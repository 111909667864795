import { ThemeSchema } from "./schema";
import logo from "../assets/images/ramsay-logo.png";

const theme: ThemeSchema = {
  name: "Default",
  logo: (
    <img
      src={logo}
      alt="Ramsay"
      height={40}
      style={{ marginLeft: 25, marginRight: 25 }}
    />
  ),
  primary: {
    main: "#B2FCE4",
    dark: "#4EF8C1",
    contrastText: "#000000",
  },
  secondary: {
    main: "#000000",
    dark: "#000000",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#CC3300",
    dark: "#CC3300",
    contrastText: "#FFFFFF",
  },
  typography: {
    fontFamily: ["Italianplate", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  button: {
    radius: {
      small: "0.25rem",
      normal: "0.5rem",
      large: "0.75rem",
    },
    padding: {
      small: "0.3rem 0.5rem",
      normal: "0.5rem 0.75rem",
      large: "1rem 1.5rem",
    },
  },
};
export default theme;
