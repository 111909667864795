import { OrganisationApi, Organisation } from "apis/merchant";
import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useAuth } from "./useAuth";

export const OrganisationsContext = createContext<{
  organisations?: Array<Organisation>;
}>({
  organisations: [],
});

export const OrganisationsProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  useEffect(() => {
    async function fetchOrganisations() {
      try {
        const res = await new OrganisationApi().getOrganisationList();
        setOrganisations(res.data);
      } catch (e) {
        setOrganisations([]);
      }
    }
    if (!isAuthenticated) {
      setOrganisations([]);
    } else {
      fetchOrganisations();
    }
  }, [isAuthenticated]);
  return (
    <OrganisationsContext.Provider value={{ organisations }}>
      {children}
    </OrganisationsContext.Provider>
  );
};

export const useOrganisations = () => {
  return useContext(OrganisationsContext);
};
