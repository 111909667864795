import { useFormik } from "formik";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import * as yup from "yup";
import {
  Button,
  Col,
  Input,
  Row,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../../components";
import { mockReports } from "./mockData";
import { PaymentReportTable } from "./PaymentReportTable";

export const SearchPayment = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik<{ searchBy: string }>({
    initialValues: {
      searchBy: "",
    },
    validationSchema: yup.object({
      searchBy: yup
        .string()
        .required("Please enter an invoice/reference number"),
    }),
    onSubmit: ({ searchBy }) => {
      setLoading(true);
      setTimeout(() => {
        setReport(
          mockReports.filter((report) => report.referenceNumber === searchBy)
        );
        setLoading(false);
      }, 500);
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <div>
      <Section>
        <SectionHeader>Search</SectionHeader>
        <SectionContent>
          <form autoComplete="false" onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Input
                  id="searchBy"
                  label="Invoice / Payment Reference Number"
                  onChange={handleChange}
                  value={values.searchBy}
                  onBlur={handleBlur}
                  error={touched.searchBy && Boolean(errors.searchBy)}
                  errorMessage={touched.searchBy && (errors.searchBy as string)}
                />
              </Col>
            </Row>

            <Button variant="primary" icon={<BsSearch />} type="submit">
              Search
            </Button>
          </form>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Report Details</SectionHeader>
        <SectionContent>
          <PaymentReportTable
            reports={report}
            loading={loading}
            paginated={false}
          />
        </SectionContent>
      </Section>
    </div>
  );
};
