export const ProviderTypeList = [
  { label: "OPTOMETRIST", value: "OPTOMETRIST" },
  { label: "GENERAL DENTIST", value: "GENERAL DENTIST" },
  { label: "PHYSIOTHERAPIST", value: "PHYSIOTHERAPIST" },
  { label: "CHIROPRACTOR", value: "CHIROPRACTOR" },
  { label: "OSTEOPATH", value: "OSTEOPATH" },
  { label: "PODIATRIST", value: "PODIATRIST" },
  { label: "PSYCHOLOGIST", value: "PSYCHOLOGIST" },
  { label: "OCCUPATIONAL THERAPIST", value: "OCCUPATIONAL THERAPIST" },
  { label: "ACUPUNCTURIST", value: "ACUPUNCTURIST" },
  { label: "NATUROPATH", value: "NATUROPATH" },
  { label: "SPEECH PATHOLOGIST", value: "SPEECH PATHOLOGIST" },
  { label: "DIETITIAN", value: "DIETITIAN" },
  { label: "MASSAGE THERAPIST", value: "MASSAGE THERAPIST" },
  { label: "EXERCISE PHYSIOLOGY", value: "EXERCISE PHYSIOLOGY" },
  { label: "OPTICAL DISPENSER  ", value: "OPTICAL DISPENSER  " },
  { label: "ENDODONTIST", value: "ENDODONTIST" },
  { label: "ORAL SURGEON", value: "ORAL SURGEON" },
  { label: "ORTHODONTIST", value: "ORTHODONTIST" },
  { label: "PAEDODONTIST (PAEDIATRIC)", value: "PAEDODONTIST (PAEDIATRIC)" },
  { label: "PERIODONTIST", value: "PERIODONTIST" },
  { label: "PROSTHETIST", value: "PROSTHETIST" },
  { label: "PROSTHODONTIST", value: "PROSTHODONTIST" },
  { label: "MYOTHERAPIST", value: "MYOTHERAPIST" },
  { label: "DENTAL TECHNICIAN", value: "DENTAL TECHNICIAN" },
  { label: "AUDIOLOGY", value: "AUDIOLOGY" },
  { label: "MEDICAL", value: "MEDICAL" },
];
