import { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  PatientClaimType,
  postEligibility,
  postPatientClaim,
} from "../../apis/medicareOnline";
import { Backdrop, SelectOptionType, Spinner } from "../../components";
import { PatientClaim, ServiceProvider } from "../../types";
import TelehealthClaim from "./Claim";
import TelehealthClaimEstimation from "./ClaimEstimation";
import { ClaimResultSuccess } from "./ClaimResult";
import {
  MedicareOnlineDisclaimer,
  MedicareOnlinePrivacyNote,
} from "./Disclaimers";

const newClaim: PatientClaim = {
  patient: {
    medicareNumber: "",
    medicareRefNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    sex: "",
  },
  serviceProvider: {
    providerNumber: "",
    serviceType: "",
  },
  medicalServices: [],
};

const serviceTypes: Array<SelectOptionType> = [
  { label: "General", value: "general" },
  { label: "Pathology", value: "pathology" },
  { label: "Specialist", value: "specialist" },
];

const providers: Array<ServiceProvider> = [
  { providerNumber: "2438221J", lastName: "Kent", firstName: "Lauretta" },
  { providerNumber: "2438231H", lastName: "Cook", firstName: "Chris" },
];

const mapServiceProviderToSelectOption = ({
  providerNumber,
  firstName,
  lastName,
}: ServiceProvider): SelectOptionType => ({
  value: providerNumber,
  label: [firstName, lastName].join(" "),
});

const MedicareOnline = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [claim, setClaim] = useState(newClaim);

  const handleClaimSubmit = async (claimCaptured: PatientClaim) => {
    try {
      setClaim(claimCaptured);
      setIsLoading(true);

      const response = await postEligibility(claimCaptured);

      setIsEligible(true);
      setClaim({
        ...claimCaptured,
        medicalServices: claimCaptured.medicalServices.map((service) => {
          const assessedService = response.medicalServices.find(
            (s) => Number(s.id) === Number(service.id)
          );

          if (assessedService) {
            return {
              ...service,
              benefitAmount: assessedService.benefitAmount,
            };
          } else {
            return service;
          }
        }),
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaimReset = () => {
    setClaim(newClaim);
  };

  const handleEstimationReject = () => {
    setIsEligible(false);
    setIsSubmitted(false);
    setIsLoading(false);
    setClaim({
      ...claim,
      medicalServices: claim.medicalServices.map((service) => ({
        ...service,
        benefitAmount: null,
        error: null,
      })),
    });
  };

  const handleEstimationAccept = async () => {
    try {
      setIsLoading(true);

      const response = await postPatientClaim(
        claim.serviceProvider.serviceType as PatientClaimType,
        claim
      );

      setIsSubmitted(true);
      setClaim({
        ...claim,
        medicalServices: claim.medicalServices.map((service) => {
          const assessedService = response.medicalServices.find(
            (s) => Number(s.id) === Number(service.id)
          );

          if (assessedService) {
            return {
              ...service,
              error: assessedService.error,
            };
          } else {
            return service;
          }
        }),
      });
      toast.success("Your claim has been submitted successfully!");
    } catch (error) {
      toast.error({ type: "error", content: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinish = () => {
    history.push("/telehealth");
  };

  return (
    <>
      <Backdrop open={isLoading} fixed>
        <Spinner />
      </Backdrop>

      {isSubmitted ? (
        <ClaimResultSuccess onFinish={handleFinish} />
      ) : isEligible ? (
        <TelehealthClaimEstimation
          onAccept={handleEstimationAccept}
          onReject={handleEstimationReject}
          isSubmitted={isSubmitted}
          medicalServices={claim.medicalServices}
          disclaimer={
            <>
              <MedicareOnlineDisclaimer />
              <MedicareOnlinePrivacyNote />
            </>
          }
        />
      ) : (
        <TelehealthClaim
          initialValue={claim}
          providers={providers.map(mapServiceProviderToSelectOption)}
          serviceTypes={serviceTypes}
          disclaimer={<MedicareOnlinePrivacyNote />}
          onSubmit={handleClaimSubmit}
          onReset={handleClaimReset}
          submitButtonText="Get Estimation"
        />
      )}
    </>
  );
};

export default MedicareOnline;
