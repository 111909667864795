import parse from "html-react-parser";
import { filter, groupBy } from "lodash";
import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import { ResponseCode, ResponseCodeApi } from "../../apis/merchant";
import { Section, SectionContent, SectionHeader } from "../../components";

interface ResponseCodesProps {
  provider: string;
}

const responseCodeTypes = [
  {
    type: "Claim Response Codes",
    header: "Claim Response Codes",
  },
  {
    type: "Item Response Codes",
    header: "Item Response Codes",
  },
  {
    type: "Cancellation Response Codes",
    header: "Cancellation Response Codes",
  },
];

const ResponseCodes: React.FC<ResponseCodesProps> = ({ provider }) => {
  const [responseCodes, setResponseCodes] = useState<Array<ResponseCode>>([]);

  useAsync(async () => {
    try {
      const response = await new ResponseCodeApi().getResponseCodeList();
      setResponseCodes(response.data);
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const groupedResponseCode = useMemo(
    () =>
      groupBy(
        filter(responseCodes, { organisation: provider, claimType: "Private" }),
        "rcCategory"
      ),
    [provider, responseCodes]
  );

  return (
    <>
      {responseCodeTypes.map(({ type, header }) => (
        <Section key={type}>
          <SectionHeader>{header}</SectionHeader>
          <SectionContent>
            {groupedResponseCode[type]
              ?.filter((code) => !!code.rcCategoryDesc)
              .map(({ id, rcCategoryDesc }) => (
                <div key={id}> {parse(rcCategoryDesc)}</div>
              ))}
            {groupedResponseCode[type]?.some((code) => !!code.responseCode) ? (
              <Table size="sm">
                <thead>
                  <tr>
                    <th style={{ width: 50 }}>Response Code</th>
                    <th style={{ width: 200 }}>Response Text</th>
                    <th>Description</th>
                  </tr>
                </thead>
                {groupedResponseCode[type]
                  ?.filter((code) => !!code.responseCode)
                  .map((code) => (
                    <tbody key={code.id}>
                      <tr>
                        <td>{parse(code.responseCode)}</td>
                        <td>{parse(code.responseText)}</td>
                        <td>{parse(code.responseDesc)}</td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
            ) : (
              "No Response Codes to display"
            )}
          </SectionContent>
        </Section>
      ))}
    </>
  );
};

export default ResponseCodes;
