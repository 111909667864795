import React from "react";
import { Input, InputProps } from "./Input";

export interface CurrencyInputProps
  extends Omit<InputProps, "format" | "type"> {}

export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  CurrencyInputProps
>((props, ref) => {
  return (
    <Input
      format={{
        thousandSeparator: true,
        isNumericString: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        allowNegative: false,
        allowLeadingZeros: false,
        placeholder: "$0.00",
        prefix: "$",
      }}
      ref={ref}
      type="text"
      {...props}
    />
  );
});
