import React from "react";
import styled from "styled-components";
import AfterpayHealth from "../../assets/images/afterpay-health.svg";
import { ReactComponent as AfterPay } from "../../assets/images/afterPay.svg";

export interface LogoProps {
  brand?: "afterpay" | "afterpay-health" | "anz";
}

const LogoWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;

  img,
  svg {
    max-height: 50px;
  }
`;

export const Logo: React.FC<LogoProps> = ({ brand }) => {
  const getImage = (brand?: string) => {
    switch (brand) {
      case "afterpay":
        return <AfterPay style={{ height: 30 }} />;
      case "afterpay-health":
        return <img src={AfterpayHealth} alt="Afterpay Health" />;
      default:
        return <img src={AfterpayHealth} alt="Afterpay Health" />;
    }
  };

  return <LogoWrapper>{getImage(brand)}</LogoWrapper>;
};
