/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FlattenedActivityReport } from '../models';
// @ts-ignore
import { ReconciliationReport } from '../models';
// @ts-ignore
import { ResponseEntityClaimReportResponse } from '../models';
// @ts-ignore
import { ResponseEntityPaymentReportResponse } from '../models';
// @ts-ignore
import { ShiftReport } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityReport: async (date: string, deviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getActivityReport', 'date', date)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getActivityReport', 'deviceId', deviceId)
            const localVarPath = `/v1/report/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportDate 
         * @param {number} terminalAssociationId 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsolidatedClaimsReport: async (reportDate: string, terminalAssociationId: number, storeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDate' is not null or undefined
            assertParamExists('getConsolidatedClaimsReport', 'reportDate', reportDate)
            // verify required parameter 'terminalAssociationId' is not null or undefined
            assertParamExists('getConsolidatedClaimsReport', 'terminalAssociationId', terminalAssociationId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getConsolidatedClaimsReport', 'storeId', storeId)
            const localVarPath = `/v1/report/consolidatedClaims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reportDate !== undefined) {
                localVarQueryParameter['reportDate'] = reportDate;
            }

            if (terminalAssociationId !== undefined) {
                localVarQueryParameter['terminalAssociationId'] = terminalAssociationId;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportDate 
         * @param {number} terminalAssociationId 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonConsolidatedClaimsReport: async (reportDate: string, terminalAssociationId: number, storeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDate' is not null or undefined
            assertParamExists('getNonConsolidatedClaimsReport', 'reportDate', reportDate)
            // verify required parameter 'terminalAssociationId' is not null or undefined
            assertParamExists('getNonConsolidatedClaimsReport', 'terminalAssociationId', terminalAssociationId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getNonConsolidatedClaimsReport', 'storeId', storeId)
            const localVarPath = `/v1/report/nonConsolidatedClaims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reportDate !== undefined) {
                localVarQueryParameter['reportDate'] = reportDate;
            }

            if (terminalAssociationId !== undefined) {
                localVarQueryParameter['terminalAssociationId'] = terminalAssociationId;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReconciliationReport: async (date: string, deviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getReconciliationReport', 'date', date)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getReconciliationReport', 'deviceId', deviceId)
            const localVarPath = `/v1/report/reconciliation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShiftReport: async (deviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getShiftReport', 'deviceId', deviceId)
            const localVarPath = `/v1/report/shift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityReport(date: string, deviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FlattenedActivityReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityReport(date, deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportDate 
         * @param {number} terminalAssociationId 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsolidatedClaimsReport(reportDate: string, terminalAssociationId: number, storeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntityPaymentReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsolidatedClaimsReport(reportDate, terminalAssociationId, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportDate 
         * @param {number} terminalAssociationId 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNonConsolidatedClaimsReport(reportDate: string, terminalAssociationId: number, storeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntityClaimReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNonConsolidatedClaimsReport(reportDate, terminalAssociationId, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReconciliationReport(date: string, deviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReconciliationReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReconciliationReport(date, deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShiftReport(deviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShiftReport(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityReport(date: string, deviceId: string, options?: any): AxiosPromise<Array<FlattenedActivityReport>> {
            return localVarFp.getActivityReport(date, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportDate 
         * @param {number} terminalAssociationId 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsolidatedClaimsReport(reportDate: string, terminalAssociationId: number, storeId: number, options?: any): AxiosPromise<ResponseEntityPaymentReportResponse> {
            return localVarFp.getConsolidatedClaimsReport(reportDate, terminalAssociationId, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportDate 
         * @param {number} terminalAssociationId 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonConsolidatedClaimsReport(reportDate: string, terminalAssociationId: number, storeId: number, options?: any): AxiosPromise<ResponseEntityClaimReportResponse> {
            return localVarFp.getNonConsolidatedClaimsReport(reportDate, terminalAssociationId, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReconciliationReport(date: string, deviceId: string, options?: any): AxiosPromise<ReconciliationReport> {
            return localVarFp.getReconciliationReport(date, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShiftReport(deviceId: string, options?: any): AxiosPromise<ShiftReport> {
            return localVarFp.getShiftReport(deviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {string} date 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getActivityReport(date: string, deviceId: string, options?: any) {
        return ReportApiFp(this.configuration).getActivityReport(date, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportDate 
     * @param {number} terminalAssociationId 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getConsolidatedClaimsReport(reportDate: string, terminalAssociationId: number, storeId: number, options?: any) {
        return ReportApiFp(this.configuration).getConsolidatedClaimsReport(reportDate, terminalAssociationId, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportDate 
     * @param {number} terminalAssociationId 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getNonConsolidatedClaimsReport(reportDate: string, terminalAssociationId: number, storeId: number, options?: any) {
        return ReportApiFp(this.configuration).getNonConsolidatedClaimsReport(reportDate, terminalAssociationId, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getReconciliationReport(date: string, deviceId: string, options?: any) {
        return ReportApiFp(this.configuration).getReconciliationReport(date, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getShiftReport(deviceId: string, options?: any) {
        return ReportApiFp(this.configuration).getShiftReport(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
}
