import React from "react";
import {
  Button as BsButton,
  ButtonProps as BsButtonProps,
} from "react-bootstrap";
import styled, { css } from "styled-components";
import { Spinner } from "../Spinner";

export interface ButtonProps extends BsButtonProps {
  variant?:
    | "primary"
    | "secondary"
    | "outline-primary"
    | "outline-secondary"
    | "danger"
    | "outline-danger"
    | "light"
    | "outline-light"
    | "dark"
    | "outline-dark";
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  loading?: boolean;
}

const buttonVariantStyle = (variant: string, key: string) => css`
  ${({ theme: { [key]: color } }) =>
    color &&
    css`
      &.btn-${variant} {
        background-color: ${color.main};
        border-color: ${color.main};
        color: ${color.contrastText};

        &:not(:disabled):not(.disabled) {
          &:active,
          &:hover,
          &:focus {
            background-color: ${color.dark};
            color: ${color.contrastText};
          }
        }
      }
      &.btn-outline-${variant} {
        border-color: ${color.main};
        color: ${color.main};

        &:not(:disabled):not(.disabled) {
          &:active,
          &:hover,
          &:focus {
            background-color: ${color.main};
            color: ${color.contrastText};
          }
        }
      }
    `}
`;

const StyledBsButton = styled(BsButton)`
  border-radius: ${(props) => props.theme.button?.radius?.normal};
  padding: ${(props) => props.theme.button?.padding?.normal};

  &.btn-sm {
    border-radius: ${(props) => props.theme.button?.radius?.small};
    padding: ${(props) => props.theme.button?.padding?.small};
  }

  &.btn-lg {
    border-radius: ${(props) => props.theme.button?.radius?.large};
    padding: ${(props) => props.theme.button?.padding?.large};
  }

  .btn-icon {
    vertical-align: text-bottom;
    &-right {
      margin-left: 0.25rem;
    }
    &-left {
      margin-right: 0.25rem;
    }
  }

  ${buttonVariantStyle("primary", "primary")}
  ${buttonVariantStyle("secondary", "secondary")}
  ${buttonVariantStyle("danger", "error")}
`;

const iconWithProps = (
  icon: React.ReactNode,
  iconPosition: "left" | "right",
  iconOnly: boolean,
  size?: string
) => {
  if (!React.isValidElement(icon)) {
    return icon;
  }
  const iconSize = size === "lg" ? 22 : size === "sm" ? 16 : 18;
  return React.cloneElement(icon, {
    size: iconSize,
    className: iconOnly ? `btn-icon` : `btn-icon btn-icon-${iconPosition}`,
  });
};

export const Button = React.forwardRef<BsButtonProps, ButtonProps>(
  (
    {
      children,
      disabled,
      icon,
      iconPosition = "left",
      loading,
      size,
      ...props
    },
    ref
  ) => {
    return (
      <StyledBsButton
        ref={ref}
        size={size}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <>
            {icon &&
              iconPosition === "left" &&
              iconWithProps(icon, iconPosition, !children, size)}
            {children}
            {icon &&
              iconPosition === "right" &&
              iconWithProps(icon, iconPosition, !children, size)}
          </>
        )}
      </StyledBsButton>
    );
  }
);
