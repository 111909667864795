import { fromPairs } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ReportSummary } from "../../../../apis/merchant";
import { Table } from "../../../../components";
import { CurrencyUtils } from "../../../../utils/currency-utils";

export interface ReportSummaryTableProps {
  reception: number;
  summaries: Array<ReportSummary>;
}

const NoSummary = styled.p`
  text-align: center;
  font-size: 0.9rem;
`;

export const ReportSummaryTable: React.FC<ReportSummaryTableProps> = ({
  reception,
  summaries,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "head",
      },
      ...summaries?.map((summary) => ({
        Header: summary.type,
        accessor: (row: any) => row[summary.type as keyof typeof row],
        align: "right",
      })),
    ],
    [summaries]
  );

  const data = useMemo(
    () => [
      {
        head: "Total Count",
        ...fromPairs(
          summaries?.map((summary) => [summary.type, summary.totalCount])
        ),
      },
      {
        head: "Total Amount",
        ...fromPairs(
          summaries?.map((summary) => [
            summary.type,
            CurrencyUtils.format(summary.totalAmount),
          ])
        ),
      },
    ],
    [summaries]
  );

  return (
    <div>
      {!!summaries.length ? (
        <>
          <p>Reception: {reception}</p>
          <Table columns={columns} data={data} />
        </>
      ) : (
        <NoSummary>No summary to display</NoSummary>
      )}
    </div>
  );
};
