import { useAuth } from "hooks";
import { Redirect, Route, Switch } from "react-router-dom";
import { Menu, MenuHeader, MenuItem, MenuList } from "../../components";
import AccountMGT from "./AccountMGT";
import { Features } from "./Features/Features";
import NotificationMGT from "./NotificationMGT/NotificationMGT";
import { MedicareDevices } from "./_MedicareDevices";

export const Administration: React.FC = () => {
  const { user } = useAuth();
  let AdminMenu = [
    {
      name: "Account",
      link: "/administration/account",
    },
    {
      name: "Notification Management",
      link: "/administration/notificationMGT",
    },
    {
      name: "Features Management",
      link: "/administration/features",
    },
    {
      name: "Medicare Devices",
      link: "/administration/moldevices",
    },
  ];

  if (user.profile.powerUser) {
    AdminMenu = [
      {
        name: "Notification Management",
        link: "/administration/notificationMGT",
      },
      {
        name: "Medicare Devices",
        link: "/administration/moldevices",
      },
    ];
  }

  return (
    <div>
      <Menu>
        <MenuHeader>Administration</MenuHeader>
        <MenuList>
          {AdminMenu.map((menu) => (
            <MenuItem to={menu.link} key={menu.link}>
              {menu.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Switch>
        {user.profile.systemAdmin ? (
          <Route exact path="/administration">
            <Redirect to="/administration/account" />
          </Route>
        ) : (
          <Route exact path="/administration">
            <Redirect to="/administration/notificationMGT" />
          </Route>
        )}
        <Route path="/administration/account">
          <AccountMGT />
        </Route>
        <Route exact path="/administration/notificationMGT">
          <NotificationMGT />
        </Route>
        {/* TODO: workaround for medicare token, to be removed */}
        <Route exact path="/administration/moldevices">
          <MedicareDevices />
        </Route>
        <Route exact path="/administration/features">
          <Features />
        </Route>
        <Route exact path="/administration/notificationMGT">
          <NotificationMGT />
        </Route>
        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
};
