import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import styles from "../Auth.module.scss";
import { AuthLayout } from "../Layout";
import { LoginForm } from "./Form";

export const Login: React.FC = () => {
  const history = useHistory();

  const { login, isLoading, isAuthenticated, error } = useAuth();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  const handleLogin = (email: string, password: string) => {
    login(email, password);
  };

  return (
    <AuthLayout>
      <div className={styles.card}>
        <div className={styles.form}>
          <h2 className={styles.header}>Log in</h2>
          <LoginForm loading={isLoading} error={error} onLogin={handleLogin} />
          <div className="text-right">
            <Link className={styles.link} to="/forgot-password">
              Forgot password?
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.help}>
        <h4>Medicare Easyclaim</h4>
        <p>
          If you have an inquiry about or wish to apply for the Medicare
          Easyclaim solution with your stand-alone EFTPOS device call:
        </p>
        <p>
          CBA Mediclear customers - 1300 883 258
          <br />
          ANZ Medicare customers - 1300 633 269
          <br />
          Suncorp Customers - 1300 621 803
        </p>
      </div>
    </AuthLayout>
  );
};
