import styled from "styled-components";
import { Container } from "../../../components";
import { CurrencyUtils } from "../../../utils/currency-utils";

const Styles = styled.div`
  margin: 1rem 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    margin: 0 1rem;
  }

  .label {
    font-size: 1.4rem;
  }
  .amount {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1rem;
  }
  .description {
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    padding: 0.15rem;
    max-width: 280px;
  }
`;

interface OverviewProps {
  showGapAmount: boolean;
  totalChargeAmount: number;
  totalBenefitAmount: number;
  totalGapAmount?: number;
}

const Overview: React.FC<OverviewProps> = ({
  showGapAmount,
  totalChargeAmount,
  totalBenefitAmount,
  totalGapAmount,
}) => {
  const AmountView: React.FC<{
    label: string;
    amount: number;
    description?: string;
  }> = ({ label, amount, description }) => {
    return (
      <div>
        <p className="label">{label}</p>
        <p className="amount">{CurrencyUtils.format(amount)}</p>
        {!!description && <p className="description">{description}</p>}
      </div>
    );
  };

  return (
    <Container>
      <Styles>
        <AmountView label="Total Charge Amount" amount={totalChargeAmount} />
        <AmountView
          label="Total Benefit Amount"
          amount={totalBenefitAmount}
          description={
            showGapAmount
              ? ""
              : "The amount will be deposited in the patient registered bank account"
          }
        />
        {showGapAmount && (
          <AmountView label="Total Gap Amount" amount={totalGapAmount} />
        )}
      </Styles>
    </Container>
  );
};

export default Overview;
