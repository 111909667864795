import React, { useEffect, useState } from "react";
import { Section, SectionContent, SectionHeader } from "../../../../components";
import { mockReports } from "./mockData";
import { PaymentReportTable } from "./PaymentReportTable";

export const OutstandingPayment = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReport(
        mockReports.filter((report) => report.status === "Outstanding")
      );
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div>
      <Section>
        <SectionHeader>Report Details</SectionHeader>
        <SectionContent>
          <PaymentReportTable
            reports={report}
            loading={loading}
            isPaid={false}
          />
        </SectionContent>
      </Section>
    </div>
  );
};
