import { useFormik } from "formik";
import { useOrganisations } from "hooks/useOrganisations";
import { useProfiles } from "hooks/useProfiles";
import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Account, AccountApi, AccountModel } from "../../../apis/merchant";
import {
  Button,
  Col,
  Input,
  Row,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectOptionType,
} from "../../../components";
import { ProviderTypeList } from "./ProviderTypeList";

export interface UserProps {
  account: Account;
  profile: string;
}

interface FormValues extends Omit<Account, "providerType" | "organisation"> {
  providerType: SelectOptionType;
  password: string;
  passwordConfirmation: string;
  organisation: SelectOptionType;
}

const User: React.FC<UserProps> = ({ profile, account }) => {
  const isProvider = profile === "Provider";
  const [showPassword, setShowPassword] = useState(false);
  const { organisations } = useOrganisations();
  const { profileList } = useProfiles();

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...account,
      password: "",
      passwordConfirmation: "",
      providerType: null,
      organisation: null,
      providerNumber: "",
    },
    validationSchema: yup.object().shape({
      username: yup
        .string()
        .email("Username should be an email address")
        .required("Username is required"),
      password: yup
        .string()
        .matches(
          /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase and one number"
        )
        .required("Please enter a password"),
      passwordConfirmation: yup
        .string()
        .required("Please retype the password")
        .oneOf([yup.ref("password")], "Passwords must match"),
      organisation: yup
        .object()
        .nullable()
        .required("Please select an organisation"),
      providerType: isProvider
        ? yup.object().nullable().required("Please select a provider type")
        : undefined,
      providerNumber: isProvider
        ? yup.string().required("Please enter a provider number")
        : undefined,
    }),
    onSubmit: (values) => {
      const request = {
        ...values,
        profile: profileList.find((p) => p.name === profile),
        providerType: values.providerType?.value,
        organisation: {
          id: values.organisation.value,
        },
      };
      addAccount(request);
      handleModelClose();
    },
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldValue,
    submitForm,
  } = formik;

  const handleModelClose = () => {
    resetForm();
  };

  const addAccount = async (account: AccountModel) => {
    try {
      await new AccountApi().createAccount(account);
      toast.info(" Account : " + account.username + " created successfully");
    } catch (error) {
      toast.error("Error creating account: " + account.username);
    }
  };

  const form = (
    <form>
      <Row>
        <Col lg={6}>
          <Input
            error={touched.username && Boolean(errors.username)}
            errorMessage={errors.username}
            id="username"
            label="Username"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email"
            value={values.username}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Input
            error={touched.firstName && Boolean(errors.firstName)}
            errorMessage={errors.firstName}
            id="firstName"
            label="First name"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="First name"
            value={values.firstName}
          />
        </Col>
        <Col lg={6}>
          <Input
            error={touched.surname && Boolean(errors.surname)}
            errorMessage={errors.surname}
            id="surname"
            label="Surname"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Surname"
            value={values.surname}
          />
        </Col>
      </Row>
      {isProvider && (
        <Row>
          <Col lg={6}>
            <Input
              error={touched.providerNumber && Boolean(errors.providerNumber)}
              errorMessage={errors.providerNumber}
              id="providerNumber"
              label="Provider number"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Provider number"
              value={values.providerNumber}
            />
          </Col>
          <Col lg={6}>
            <Select
              error={touched.providerType && Boolean(errors.providerType)}
              errorMessage={errors.providerType as string}
              id="providerType"
              label="Provider type"
              options={ProviderTypeList.map((provider) => ({
                value: provider.value,
                label: provider.label,
              }))}
              value={values.providerType}
              onBlur={handleBlur}
              placeholder="select a provider type"
              onChange={(val) => setFieldValue("providerType", val)}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={6}>
          <Input
            error={touched.password && Boolean(errors.password)}
            errorMessage={errors.password}
            id="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Password"
            value={values.password}
            icon={
              showPassword ? (
                <BsEyeSlashFill onClick={() => setShowPassword(false)} />
              ) : (
                <BsEyeFill onClick={() => setShowPassword(true)} />
              )
            }
            iconPosition="right"
          />
        </Col>
        <Col lg={6}>
          <Input
            error={
              touched.passwordConfirmation &&
              Boolean(errors.passwordConfirmation)
            }
            errorMessage={errors.passwordConfirmation}
            id="passwordConfirmation"
            type={showPassword ? "text" : "password"}
            label="Confirm password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Confirm password"
            value={values.passwordConfirmation}
            icon={
              showPassword ? (
                <BsEyeSlashFill onClick={() => setShowPassword(false)} />
              ) : (
                <BsEyeFill onClick={() => setShowPassword(true)} />
              )
            }
            iconPosition="right"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Select
            error={touched.organisation && Boolean(errors.organisation)}
            errorMessage={errors.organisation as string}
            id="organisation"
            options={organisations.map((org) => ({
              value: org.id.toString(),
              label: org.name,
            }))}
            value={values.organisation}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue("organisation", val)}
            label="Organisation"
          />
        </Col>
        <Col lg={6}>
          <Input
            readOnly
            id="profile"
            label="Profile"
            placeholder="Profile"
            value={profile}
          />
        </Col>
      </Row>
    </form>
  );
  return (
    <Section>
      <SectionHeader> New {profile} </SectionHeader>
      <SectionContent>
        {form}
        <Button type="submit" variant="primary" onClick={() => submitForm()}>
          Add
        </Button>
      </SectionContent>
    </Section>
  );
};

export default User;
