import { useMemo } from "react";
import { FlattenedActivityReport } from "../../../../apis/merchant";
import { Table, TableColumnType } from "../../../../components";
import { CurrencyUtils } from "../../../../utils/currency-utils";
import { ReportCSVExporter } from "../../ReportExporter";

interface ActivityReportTableProps {
  reports: Array<FlattenedActivityReport>;
  loading: boolean;
}

export const ActivityReportTable: React.FC<ActivityReportTableProps> = ({
  reports,
  loading,
}) => {
  const columns = useMemo<Array<TableColumnType<FlattenedActivityReport>>>(
    () => [
      {
        Header: "Reception",
        accessor: "reception",
      },
      {
        Header: "Submitted Count",
        accessor: "submittedCount",
        align: "center",
      },
      {
        Header: "Submitted Amount",
        accessor: (row) => CurrencyUtils.format(row.submittedAmount),
        align: "right",
      },
      {
        Header: "Voided Count",
        accessor: "voidedCount",
        align: "center",
      },
      {
        Header: "Voided Amount",
        accessor: (row) => CurrencyUtils.format(row.voidedAmount),
        align: "right",
      },
      {
        Header: "Cancelled Count",
        accessor: "cancelledCount",
        align: "center",
      },
      {
        Header: "Cancelled Amount",
        accessor: (row) => CurrencyUtils.format(row.cancelledAmount),
        align: "right",
      },
      {
        Header: "Report Datetime",
        accessor: "reportDateTime",
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={reports || []}
      compact
      hover
      striped
      noDataMessage="No report to display"
      loading={loading}
      paginated
    />
  );
};

interface ActivityReportTableCSVExporterProps {
  filename: string;
  reports: Array<FlattenedActivityReport>;
}

export const ActivityReportTableCSVExporter: React.FC<ActivityReportTableCSVExporterProps> = ({
  filename,
  reports,
}) => {
  const csvMetadata = [
    { header: "Reception", name: "reception" },
    { header: "Submitted Count", name: "submittedCount" },
    { header: "Submitted Amount", name: "submittedAmount" },
    { header: "Voided Count", name: "voidedCount" },
    { header: "Voided Amount", name: "voidedAmount" },
    { header: "Cancelled Count", name: "cancelledCount" },
    { header: "Cancelled Amount", name: "cancelledAmount" },
    { header: "Report Datetime", name: "reportDateTime" },
  ];

  return (
    <ReportCSVExporter
      filename={filename}
      metadata={csvMetadata}
      reports={reports}
    />
  );
};
