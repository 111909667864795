import React from "react";
import { Alert as BsAlert, AlertProps as BsAlertProps } from "react-bootstrap";
import styled, { css } from "styled-components";

export interface AlertProps extends BsAlertProps {
  variant?: "primary" | "secondary" | "danger";
}

const alertVariantStyle = (variant: string, key: string) => css`
  ${({ theme: { [key]: color } }) =>
    color &&
    css`
      &.alert-${variant} {
        background-color: ${color.main};
        border-color: ${color.main};
        color: ${color.contrastText};
      }
    `}
`;

const StyledBsAlert = styled(BsAlert)`
  ${alertVariantStyle("primary", "primary")}
  ${alertVariantStyle("secondary", "secondary")}
  ${alertVariantStyle("danger", "error")}
`;

export const Alert: React.FC<AlertProps> = ({ children, variant }) => {
  return <StyledBsAlert variant={variant}>{children}</StyledBsAlert>;
};
