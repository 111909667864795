import { sanitize } from "dompurify";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useFormik } from "formik";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GiSave } from "react-icons/gi";
import { toast } from "react-toastify";
import { NotificationApi } from "../../../apis/merchant";
import {
  Button,
  Col,
  Input,
  Row,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectOptionType,
} from "../../../components";
import { useNotifications } from "../../../hooks";
import { ModalityOptions } from "./ModalityOptions";
import "./NotificationMGT.scss";

interface FormValues {
  subject: string;
  message: EditorState;
  modality: SelectOptionType;
}

const NotificationMGT: React.FC = () => {
  const { refresh } = useNotifications();

  const formik = useFormik<FormValues>({
    initialValues: {
      subject: "",
      message: EditorState.createEmpty(),
      modality: null,
    },
    onSubmit: async ({ subject, message, modality }) => {
      try {
        // TODO: This should be a type mismatch due to code generation
        await new NotificationApi().createNotification({
          subject,
          message: safeEditorStateToHtml(message),
          modality: modality?.value,
        } as unknown);

        formik.resetForm();
        toast.success("Notification has been successfully published.");

        setTimeout(() => refresh(), 1000);
      } catch (e) {
        toast.error(e?.message);
      }
    },
  });

  const { values, handleChange, handleSubmit, setFieldValue } = formik;

  return (
    <div>
      <Section>
        <SectionHeader>Notification Management</SectionHeader>
        <SectionContent>
          <form autoComplete="false" onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Input
                  id="subject"
                  label="Subject"
                  onChange={handleChange}
                  value={values.subject}
                  maxLength={50}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Message</h6>
                <Editor
                  editorState={values.message}
                  onEditorStateChange={(messageState) =>
                    setFieldValue("message", messageState)
                  }
                  wrapperStyle={{
                    border: "1px solid #ccc",
                    borderRadius: "0.25rem",
                    marginBottom: "20px",
                  }}
                  editorStyle={{
                    height: "150px",
                    padding: "0 1rem",
                    fontSize: "1rem",
                  }}
                  toolbar={{
                    options: ["inline", "list", "history"],
                    inline: { options: ["bold", "italic"] },
                    list: { options: ["unordered", "ordered"] },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Select
                  id="modality"
                  options={ModalityOptions()}
                  label="Modality"
                  onChange={(selected?) => setFieldValue("modality", selected)}
                  value={values.modality}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Button
                  variant="primary"
                  icon={<GiSave />}
                  type="submit"
                  disabled={
                    !values.subject ||
                    !values.message.getCurrentContent().getPlainText()
                  }
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </form>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Preview Notification</SectionHeader>
        <SectionContent>
          <Row>
            <Col>
              <h5>{values.subject}</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: safeEditorStateToHtml(values.message),
                }}
              />
            </Col>
          </Row>
        </SectionContent>
      </Section>
    </div>
  );
};

function safeEditorStateToHtml(draftState: EditorState): string {
  return sanitize(draftToHtml(convertToRaw(draftState?.getCurrentContent())));
}

export default NotificationMGT;
