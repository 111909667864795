import { Redirect, Route, Switch } from "react-router-dom";
import { Account } from "../../../apis/merchant";
import { Container, Nav, NavItem } from "../../../components";
import { AccountMGT as AccountList } from "./AccountMGT";
import User from "./User";
import { ProfilesProvider, OrganisationsProvider } from "hooks";

const AccountMGT: React.FC = () => {
  const newAccount: Account = {
    username: "",
    firstName: "",
    surname: "",
    organisation: { id: null, name: "", createDate: null },
    profile: {
      id: null,
      name: "",
      createDate: "",
      systemAdmin: false,
      powerUser: false,
    },
  };

  return (
    <>
      <ProfilesProvider>
        <OrganisationsProvider>
          <Container>
            <Nav>
              <NavItem to="/administration/account/accounts">Accounts</NavItem>
              <NavItem to="/administration/account/provider">
                Add Provider
              </NavItem>
              <NavItem to="/administration/account/receptionist">
                Add Receptionist
              </NavItem>
              <NavItem to="/administration/account/practiceManager">
                Add Practice Manager
              </NavItem>
              <NavItem to="/administration/account/poweruser">
                Add Power User
              </NavItem>
            </Nav>
            <br />
          </Container>

          <Switch>
            <Route exact path="/administration/account">
              <Redirect to="/administration/account/accounts" />
            </Route>
            <Route exact path="/administration/account/accounts">
              <AccountList />
            </Route>
            <Route exact path="/administration/account/provider">
              <User profile="Provider" account={newAccount} />
            </Route>
            <Route exact path="/administration/account/receptionist">
              <User profile="Receptionist" account={newAccount} />
            </Route>
            <Route exact path="/administration/account/practiceManager">
              <User profile="Practice Manager" account={newAccount} />
            </Route>
            <Route exact path="/administration/account/poweruser">
              <User profile="Power User" account={newAccount} />
            </Route>
          </Switch>
        </OrganisationsProvider>
      </ProfilesProvider>
    </>
  );
};

export default AccountMGT;
