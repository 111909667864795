import { format } from "date-fns";
import { useState } from "react";
import {
  DeviceModel,
  ReconciliationReport as ReconciliationReportModel,
  ReportApi,
} from "../../../apis/merchant";
import { Section, SectionContent, SectionHeader } from "../../../components";
import {
  ProviderReportTable,
  ProviderReportTableCSVExporter,
} from "./Shared/ProviderReportTable";
import { ReportSummaryTable } from "./Shared/ReportSummaryTable";
import { SearchForm } from "./Shared/SearchForm";

export const ReconciliationReport = () => {
  const [report, setReport] = useState<ReconciliationReportModel>();
  const [reception, setReception] = useState<DeviceModel>();
  const [loading, setLoading] = useState(false);

  const handleSearch = async (reception: DeviceModel, date: Date) => {
    setReception(reception);
    setLoading(true);
    try {
      const res = await new ReportApi().getReconciliationReport(
        format(date, "yyyyMMdd"),
        String(reception.id)
      );
      setReport(res.data);
    } catch (e) {
      setReport(null);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setReception(null);
    setReport(null);
    setLoading(false);
  };

  return (
    <div>
      <Section>
        <SectionHeader>Search</SectionHeader>
        <SectionContent>
          <SearchForm onSearch={handleSearch} onReset={handleReset} />
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Report Details</SectionHeader>
        <SectionContent>
          <ProviderReportTable
            reports={report?.providerReports || []}
            loading={loading}
          />
          <ProviderReportTableCSVExporter
            filename="Reconciliation Report"
            reports={report?.providerReports}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Summary by Reception</SectionHeader>
        <SectionContent>
          <ReportSummaryTable
            reception={reception?.id}
            summaries={report?.reconciliationSummaries || []}
          />
        </SectionContent>
      </Section>
    </div>
  );
};
