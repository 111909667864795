import { Modal as BsModal } from "react-bootstrap";
import styled from "styled-components";

const StyledBsModalHeader = styled(BsModal.Header)`
  font-weight: bold;
  font-size: 1.2rem;
  border-bottom-width: 0;
`;

export const ModalHeader: React.FC = ({ children }) => {
  return <StyledBsModalHeader closeButton={false}>{children}</StyledBsModalHeader>;
};
