import styled from "styled-components";

const Styles = styled.div`
  font-size: 0.8rem;
  padding-top: 1rem;

  & >ol {
    padding-left: 0.8rem;
  }
`;

export const MedicareOnlineDisclaimer = () => {
  return (
    <Styles>
      <p>Disclaimer:</p>
      <ol>
        <li>
          This response is based on information supplied by you and on the
          conditions applicable to you and your membership of your health fund
          at the proposed admission date, as known at the date this OEC was
          performed.
        </li>
        <li>
          This response is not an undertaking by Services Australia or the
          health fund to pay any claim.
        </li>

        <li>
          Services Australia have the right to decline a future claim based on
          your eligibility at the time that future claim is made.
        </li>

        <li>
          The health fund may decline a future claim based on the conditions
          applicable at the time that future claim is made, including:
          pre-existing ailments; waiting periods not being served; product
          exclusions; accident or where compensation and/or damages may be
          claimed from another source; and cancelled, suspended or non-financial
          memberships.
        </li>
      </ol>
    </Styles>
  );
};

export const MedicareOnlinePrivacyNote = () => {
  return (
    <Styles>
      <p>Privacy Notice:</p>
      <ol>
        <li>
          Your personal information is protected by law, including the Privacy
          Act 1988, and is collected by the Australian Government Services
          Australia for the assessment and administration of payments and
          services. This information is required to process your application or
          claim.
        </li>
        <li>
          Your information may be used by the agency or given to other parties
          for the purposes of research, investigation or where you have agreed
          or it is required or authorised by law.
        </li>
        <li>
          You can get more information about the way in which Services Australia
          will manage your personal information, including our privacy policy at{" "}
          <a href="//servicesaustralia.gov.au/privacy" target="blank">
            servicesaustralia.gov.au/privacy
          </a>{" "}
          or by requesting a copy from the agency.
        </li>
        <li>
          The information provided or received will be used to determine your
          Medicare eligibility and may be accessed by Services Australia, your
          provider and health fund. Your information will not be provided to any
          other third parties unless required or authorised by law. This
          information may be used to facilitate patient Informed Financial
          Consent (IFC).
        </li>
      </ol>
    </Styles>
  );
};
