import styled from "styled-components";

const StyledHeaderSection = styled.div`
  background-color: #f0f0f0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const SectionHeader: React.FC = ({ children }) => {
  return <StyledHeaderSection>{children}</StyledHeaderSection>;
};
