import { isNil, startsWith } from "lodash";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.div<{ active: boolean }>`
  background-color: #fff;
  display: inline-block;
  margin-bottom: 0.2rem;

  a, span {
    color: ${(props) => (props.active ? "#000" : "#BBB")};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75rem;
    padding: 0 0.75em;
  }
  &:not(:last-child) {
    margin-right: 0.2rem;
  }
  a:hover {
    cursor: pointer;
    color: #000;
    text-decoration: underline;
  }
`;

export interface MenuItemProps {
  to?: string;
  active?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ children, to, active }) => {
  const { pathname } = useLocation();
  const isActive = isNil(active) ? startsWith(pathname, to) : active;

  return (
    <StyledLink active={isActive}>
      {to ? <Link to={to}>{children}</Link> : <span>{children}</span>}
    </StyledLink>
  );
};
