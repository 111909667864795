import React, { useCallback, useMemo, useRef, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { Account, AccountApi } from "../../../apis/merchant";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
  SectionContent,
  SectionHeader,
  Table,
  TableColumnType,
} from "../../../components";
import { DateUtils } from "../../../utils/date-utils";
import AccountModal, { updateAccount } from "./AccountModal";

export const AccountMGT: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const newAccount: Account = {
    username: "",
    firstName: "",
    surname: "",
    organisation: { name: "" },
    profile: { name: "", createDate: "" },
  };
  const [account, setAccount] = useState<Account>(newAccount);
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleRowClick = useCallback(
    (id: string) => {
      setAccount(accounts.find((account) => account.id === id));
      setModalOpen(true);
    },
    [accounts]
  );

  const handleDeleteAccount = useCallback(
    (id: string) => {
      setAccount(accounts.find((account) => account.id === id));
      setShowDeleteModal(true);
    },
    [accounts]
  );
  const columns = useMemo<Array<TableColumnType<Account>>>(
    () => [
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Surname",
        accessor: "surname",
      },
      {
        Header: "Organisation",
        accessor: (row) => row.organisation?.name,
      },
      {
        Header: "Profile",
        accessor: (row) => row.profile?.name,
        width: 150,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 50,
      },
      {
        Header: "Create Datetime",
        accessor: (row) => DateUtils.formatDateTime(row.createDate),
        align: "right",
      },
      {
        Header: "",
        id: "actions",
        accessor: (row) => (
          <Button
            variant="outline-dark"
            icon={<MdEdit />}
            size="sm"
            onClick={() => handleRowClick(row.id)}
          />
        ),
        width: 50,
      },
      {
        Header: "",
        id: "actionsDelete",
        accessor: (row) =>
          !row.profile?.systemAdmin && (
            <Button
              variant="outline-danger"
              icon={<MdDelete />}
              size="sm"
              onClick={() => handleDeleteAccount(row.id)}
            />
          ),
        width: 50,
      },
    ],
    [handleRowClick, handleDeleteAccount]
  );

  const handleUpdateAccount = async (updatedAccount: updateAccount) => {
    try {
      setLoading(true);
      const res = await new AccountApi().updateAccount(updatedAccount);
      toast.info(updatedAccount.username + " updated successfully");
      setAccounts(
        accounts.map((account) => {
          return account.id === res.data.id
            ? {
                ...account,
                ...res.data,
              }
            : account;
        })
      );
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = async () => {
    try {
      setLoading(true);
      await new AccountApi().deleteAccount(account.id);
      toast.info(account.username + " deleted successfully");
      setAccounts(accounts.filter((obj) => obj.id !== account.id));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
    setShowDeleteModal(false);
  };

  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const fetchData = useCallback(async (pageSize, pageIndex) => {
    const fetchId = ++fetchIdRef.current;

    /* istanbul ignore else */
    if (fetchId === fetchIdRef.current) {
      try {
        setLoading(true);
        const res = await new AccountApi().getAccountList(
          pageIndex + 1,
          pageSize
        );
        setAccounts(res.data.data);
        setPageCount(res.data.totalPages);
      } catch (e) {
        setAccounts([]);
        setPageCount(0);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return (
    <div>
      <AccountModal
        account={account}
        open={modalOpen}
        handleClose={closeModal}
        onUpdate={handleUpdateAccount}
      />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <ModalHeader>Confirm delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete account: {account.username} ?
        </ModalBody>
        <ModalFooter>
          <Button variant="light" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
      <Section>
        <SectionHeader>Account List</SectionHeader>
        <SectionContent>
          <Table
            columns={columns}
            data={accounts || []}
            compact
            noDataMessage="No Accounts to display"
            loading={loading}
            paginated
            lazyLoad
            fetchData={fetchData}
            pageCount={pageCount}
          />
        </SectionContent>
      </Section>
    </div>
  );
};
