import parse from "html-react-parser";
import { filter } from "lodash";
import React, { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import { Faq, FaqApi } from "../../apis/merchant";
import {
  Accordion,
  AccordionItem,
  Section,
  SectionContent,
  SectionHeader,
} from "../../components";

interface ReconciliationSettlementProps {
  provider: string;
}

const ReconciliationSettlement: React.FC<ReconciliationSettlementProps> = ({
  provider,
}) => {
  const [faqs, setFaqs] = useState<Faq[]>([]);

  useAsync(async () => {
    try {
      const response = await new FaqApi().getFaqList();
      setFaqs(response.data);
    } catch (e) {
      setFaqs([]);
      toast.error(e.message);
    }
  }, []);

  const commonFaqs = useMemo(
    () =>
      filter(faqs, {
        organisation: provider,
        menuItem: "reconciliation",
        type: "common",
      }),
    [faqs, provider]
  );

  return (
    <>
      <Section>
        <SectionHeader>
          Common Reconciliation &amp; Settlement Questions
        </SectionHeader>
        <SectionContent>
          {commonFaqs.length > 0 ? (
            <Accordion>
              {commonFaqs.map((common) => {
                return (
                  <AccordionItem key={common.id} header={common.question}>
                    {parse(common.answer)}
                  </AccordionItem>
                );
              })}
            </Accordion>
          ) : (
            "No FAQs to display"
          )}
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>
          Private Health Report Names &amp; Definitions
        </SectionHeader>
        <SectionContent>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: 200 }}>Report Name</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Reconciliation Report </td>
                <td>
                  The record of claims sent through to each Health Fund for a
                  particular day. Available Claiming Day plus 1.
                </td>
              </tr>
              <tr>
                <td> Activity Report </td>
                <td>
                  The totals of claims, voids and cancellations summarised for a
                  particular day. Available Claiming Day plus 1
                </td>
              </tr>
              <tr>
                <td> Daily Claim Report </td>
                <td>
                  The record of claims sent through to each Health Fund for a
                  particular day.
                </td>
              </tr>
              <tr>
                <td> Consolidated Claim Report </td>
                <td>
                  The record of claims paid by DXC'S Claim Settlement System
                  (CSS) for those Health Funds that participate.
                </td>
              </tr>
            </tbody>
          </Table>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Reconciliation Report Definitions</SectionHeader>
        <SectionContent>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: 200 }}>Claim Report</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Provider Name </td>
                <td>
                  This is the name of the provider whom the claim was processed
                  for
                </td>
              </tr>
              <tr>
                <td> Service Type </td>
                <td> The modality of the provider </td>
              </tr>
              <tr>
                <td> Fund Name </td>
                <td> The name of the Health Fund </td>
              </tr>
              <tr>
                <td> Receipt Number </td>
                <td>
                  This is the identifying Claim Reference number for this claim
                  - 7 digits, prefixed by your DXC ID e.g. ANI12345 0000123
                </td>
              </tr>
              <tr>
                <td> Number of Service </td>
                <td>
                  The number of claims accepted by fund, rejected by fund and
                  the total number submitted.
                </td>
              </tr>
              <tr>
                <td> Total Charge </td>
                <td> This is the total amount the claim was processed for </td>
              </tr>
              <tr>
                <td> Total Benefit </td>
                <td>
                  This is the amount the patient's private health fund committed
                  to pay to the provider for this claim
                </td>
              </tr>
              <tr>
                <td> Total Gap </td>
                <td>
                  This is the amount paid by the patient which is not covered by
                  the health fund
                </td>
              </tr>
              <tr>
                <td> Response Code </td>
                <td>
                  This is the response code received from the private health
                  fund. Each code has a definition - Please see list{" "}
                  <Link to="/help/responseCodes">here</Link>.
                </td>
              </tr>
              <tr>
                <td> Result Description </td>
                <td>
                  Whether the claim was approved, rejected, voided or cancelled.
                </td>
              </tr>
              <tr>
                <td> Reception </td>
                <td> Your HealthPoint DXC ID per device </td>
              </tr>
              <tr>
                <td> Report Datetime </td>
                <td> The date and time the report is relevant to. </td>
              </tr>
            </tbody>
          </Table>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Consolidated Claim Report Definitions</SectionHeader>
        <SectionContent>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: 200 }}>Claim Report</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Settlement Date </td>
                <td>This is the date the claim was paid</td>
              </tr>
              <tr>
                <td>Response Date </td>
                <td>
                  This is the date the claim response was received from the
                  Health Fund (should be the same as the date the claim was
                  made)
                </td>
              </tr>
              <tr>
                <td>Transaction Date </td>
                <td>This is the date the claim was made</td>
              </tr>
              <tr>
                <td>Provider ID </td>
                <td>
                  This is the provider number of the provider whom the claim was
                  processed for
                </td>
              </tr>
              <tr>
                <td>Claim Reference Number </td>
                <td>
                  This is the identifying Claim Reference number for this claim
                  - 7 digits, prefixed by your DXC ID e.g. ANI12345 0000123
                </td>
              </tr>
              <tr>
                <td>Total Claim Amount </td>
                <td>This is the total amount the claim was processed for</td>
              </tr>
              <tr>
                <td>Health Fund Approved Benefit Amount </td>
                <td>
                  This is the amount the patient's private health fund committed
                  to pay to the provider for this claim
                </td>
              </tr>
              <tr>
                <td>Health Fund Response Code </td>
                <td>
                  This is the response code received from the private health
                  fund. Each code has a definition - Please see list{" "}
                  <Link to="/help/responseCodes">here</Link>.
                  <p>
                    <b>Note:</b> If the response code is listed as 32 - then
                    this indicates the claim benefit amount payable by the fund
                    has been amended by the health fund.
                  </p>
                </td>
              </tr>
              <tr>
                <td>Payment Reference </td>
                <td>
                  This is the reference number associated with the payment of
                  this claim. This reference number and the associated
                  consolidated payment amount should be viewable via your online
                  bank account.
                </td>
              </tr>
              <tr>
                <td>Comments </td>
                <td>
                  Any text a Health Fund may have entered when amending a
                  benefit amount payable
                </td>
              </tr>
            </tbody>
          </Table>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Activity Report Definitions</SectionHeader>
        <SectionContent>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: 200 }}>Claim Report</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Reception </td>
                <td> Your HealthPoint DXC ID per device </td>
              </tr>
              <tr>
                <td> Submitted Count </td>
                <td> Total number of claims submitted </td>
              </tr>
              <tr>
                <td> Submitted Amount </td>
                <td> The total benefit amount of the claims submitted </td>
              </tr>
              <tr>
                <td> Voided Count </td>
                <td> Total number of claims voided </td>
              </tr>
              <tr>
                <td> Voided Amount </td>
                <td> The total benefit amount for the claims voided </td>
              </tr>
              <tr>
                <td> Cancelled Count </td>
                <td> Total number of claims cancelled </td>
              </tr>
              <tr>
                <td> Cancelled Amount </td>
                <td> The total benefit amount for the claims cancelled </td>
              </tr>
              <tr>
                <td> Report Datetime </td>
                <td> The date and time the report is relevant to. </td>
              </tr>
            </tbody>
          </Table>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Daily Claim Report Definitions</SectionHeader>
        <SectionContent>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: 200 }}>Claim Report</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Provider Name </td>
                <td>
                  This is the name of the provider whom the claim was processed
                  for
                </td>
              </tr>
              <tr>
                <td> Service Type </td>
                <td> The modality of the provider </td>
              </tr>
              <tr>
                <td> Fund Name </td>
                <td> The name of the Health Fund </td>
              </tr>
              <tr>
                <td> Receipt Number </td>
                <td>
                  This is the identifying Claim Reference number for this claim
                  - 7 digits, prefixed by your DXC ID e.g. ANI12345 0000123
                </td>
              </tr>
              <tr>
                <td> Number of Service </td>
                <td>
                  The number of claims accepted by fund, rejected by fund and
                  the total number submitted.
                </td>
              </tr>
              <tr>
                <td> Total Charge </td>
                <td> This is the total amount the claim was processed for </td>
              </tr>
              <tr>
                <td> Total Benefit </td>
                <td>
                  This is the amount the patient's private health fund committed
                  to pay to the provider for this claim
                </td>
              </tr>
              <tr>
                <td> Total Gap </td>
                <td>
                  This is the amount paid by the patient which is not covered by
                  the health fund
                </td>
              </tr>
              <tr>
                <td> Response Code </td>
                <td>
                  This is the response code received from the private health
                  fund. Each code has a definition - Please see list{" "}
                  <Link to="/help/responseCodes">here</Link>.
                </td>
              </tr>
              <tr>
                <td> Result Description </td>
                <td>
                  Whether the claim was approved, rejected, voided or cancelled.
                </td>
              </tr>
              <tr>
                <td> Reception </td>
                <td> Your HealthPoint DXC ID per device </td>
              </tr>
              <tr>
                <td> Report Datetime </td>
                <td> The date and time the report is relevant to. </td>
              </tr>
            </tbody>
          </Table>
        </SectionContent>
      </Section>
    </>
  );
};

export default ReconciliationSettlement;
