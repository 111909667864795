import { isNil, startsWith } from "lodash";
import { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../hooks";
import { Logo } from "../Logo";
import { NotificationIcon } from "../Notification";
import { ThemeContext } from "styled-components";

const StyledNavbar = styled(Navbar).attrs({
  expand: "lg",
})`
  .navbar-toggler {
    border-width: 0;
  }

  > .navbar-nav {
    flex-direction: column;
  }

  @media (max-width: 992px) {
    .navbar-nav {
      flex-basis: auto;
      flex-grow: 1;
    }
  }
`;

const StyledNavLink = styled(Nav.Link)<{ $active: boolean }>`
  font-weight: bold;
  font-size: 1rem;
  color: #000;
  padding-bottom: 0.1rem;
  margin: 0.5rem 0.8rem 0.4rem 0.8rem;
  line-height: 1.6rem;
  position: relative;
  border-bottom: 0.25rem solid
    ${(props) => (props.$active ? props.theme.primary.main : "transparent")};

  &:active,
  &:hover {
    color: #000;
    text-decoration: none;
    border-bottom: 0.25rem solid ${(props) => props.theme.primary.main};
  }

  @media (min-width: 992px) {
    &:not(:last-child):after {
      content: "";
      position: relative;
      left: 0.8rem;
      border-right: 1px solid #b3b3b3;
    }
  }
`;

interface NavLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  active?: boolean;
  to?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  active,
  children,
  to,
  ...props
}) => {
  const { pathname } = useLocation();
  const isPathMatched = to === "/" ? pathname === to : startsWith(pathname, to);
  const isActive = isNil(active) ? isPathMatched : active;

  return (
    <StyledNavLink $active={isActive} as={Link} to={to} {...props}>
      {children}
    </StyledNavLink>
  );
};

export const NavBar: React.FC = () => {
  const { user, logout } = useAuth();
  const themeContext = useContext(ThemeContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <StyledNavbar>
      <Navbar.Brand as={Link} to="/">
        {themeContext.logo || <Logo />}
      </Navbar.Brand>
      <Nav>
        <Nav.Item className="mr-1">{user?.firstName}</Nav.Item>
        <Nav.Item>{user?.profile.name}</Nav.Item>
      </Nav>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavLink to="/">Dashboard</NavLink>
          <NavLink to="/reports">Reports</NavLink>
          {process.env.REACT_APP_ENABLE_TELEHEALTH && (
            <NavLink to="/telehealth">Telehealth</NavLink>
          )}
          {(user?.profile.powerUser || user?.profile.systemAdmin) && (
            <NavLink to="/administration">Administration</NavLink>
          )}

          <NavLink to="/help">Help</NavLink>
          <NavLink to="/notification">
            <NotificationIcon />
          </NavLink>
          <NavLink to="#" onClick={handleLogout}>
            Logout
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </StyledNavbar>
  );
};
