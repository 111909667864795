export const mockReports = [
  {
    operator: "Grower Grown",
    refId: "1-167",
    dateTransaction: "10/06/2021 03:45pm",
    referenceNumber: "1NV-39814",
    amount: 389.0,
    status: "Paid",
    datePaid: "10/06/2021 04:00pm",
  },
  {
    operator: "Dev usr",
    refId: "1-165",
    dateTransaction: "10/06/2021 02:15pm",
    referenceNumber: "1NV-39804",
    amount: 200.0,
    status: "Paid",
    datePaid: "10/06/2021 02:30pm",
  },
  {
    operator: "Jas",
    refId: "1-166",
    dateTransaction: "10/06/2021 02:30pm",
    referenceNumber: "1NV-30865",
    amount: 329.0,
    status: "Paid",
    datePaid: "10/06/2021 03:00pm",
  },
  {
    operator: "Dev usr",
    refId: "1-158",
    dateTransaction: "10/06/2021 11:30am",
    referenceNumber: "1NV-38293",
    amount: 245.0,
    status: "Outstanding",
  },
  {
    operator: "Dev usr",
    refId: "1-259",
    dateTransaction: "10/06/2021 10:19am",
    referenceNumber: "1NV-40613",
    amount: 580.5,
    status: "Outstanding",
  },
];
