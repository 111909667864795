import { ToastContainer as ToastifyContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const StyledToastContainer = styled(ToastifyContainer).attrs({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
})`
  .Toastify__toast {
    &--default {
      background: white;
      color: black;
    }
    &--error {
      background: ${(props) => props.theme.error?.main};
      color: ${(props) => props.theme.error?.contrastText};
    }
  }

  .Toastify__progress-bar {
    &--default {
      background: ${(props) => props.theme.primary?.main};
    }
    &--dark {
      background: white;
    }
  }
`;

export const ToastContainer = StyledToastContainer;
