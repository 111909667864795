/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Faq } from '../models';
/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Faq} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq: async (body?: Faq, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq: async (faqId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('deleteFaq', 'faqId', faqId)
            const localVarPath = `/v1/faq/{faqId}`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaq: async (faqId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('getFaq', 'faqId', faqId)
            const localVarPath = `/v1/faq/{faqId}`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/faq/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Faq} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFaq(body?: Faq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Faq>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFaq(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFaq(faqId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFaq(faqId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaq(faqId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Faq>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFaq(faqId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaqList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Faq>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFaqList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqApiFp(configuration)
    return {
        /**
         * 
         * @param {Faq} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq(body?: Faq, options?: any): AxiosPromise<Faq> {
            return localVarFp.createFaq(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq(faqId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFaq(faqId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaq(faqId: number, options?: any): AxiosPromise<Faq> {
            return localVarFp.getFaq(faqId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqList(options?: any): AxiosPromise<Array<Faq>> {
            return localVarFp.getFaqList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProviders(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI {
    /**
     * 
     * @param {Faq} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public createFaq(body?: Faq, options?: any) {
        return FaqApiFp(this.configuration).createFaq(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} faqId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public deleteFaq(faqId: number, options?: any) {
        return FaqApiFp(this.configuration).deleteFaq(faqId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} faqId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public getFaq(faqId: number, options?: any) {
        return FaqApiFp(this.configuration).getFaq(faqId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public getFaqList(options?: any) {
        return FaqApiFp(this.configuration).getFaqList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public getProviders(options?: any) {
        return FaqApiFp(this.configuration).getProviders(options).then((request) => request(this.axios, this.basePath));
    }
}
