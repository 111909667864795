import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PaymentForm } from "./PaymentForm";

export const Payment: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/telehealth/payment">
        <PaymentForm />
      </Route>
      <Route>
        <Redirect to="/404" />
      </Route>
    </Switch>
  );
};

export default Payment;
