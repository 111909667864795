import styled from "styled-components";
import { Footer, NavBar } from "../../components";
import { NotificationsProvider } from "../../hooks";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const PageWrapper = styled.div`
  flex: 1;
`;

const Layout = ({ children }) => {
  return (
    <Root>
      <NotificationsProvider>
        <NavBar />
        <PageWrapper>{children}</PageWrapper>
        <Footer />
      </NotificationsProvider>
    </Root>
  );
};

export default Layout;
