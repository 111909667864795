import React, { useEffect, useState } from "react";
import { IoInformationCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import { TrainingMaterial, TrainingMaterialApi } from "../../apis/merchant";
import { Section, SectionContent, SectionHeader } from "../../components";

interface TrainingVideosProps {
  provider: string;
}

const TrainingVideos: React.FC<TrainingVideosProps> = ({ provider }) => {
  const [trainingMaterials, setTrainingMaterials] = useState<
    Array<TrainingMaterial>
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trainingVideos, setTrainingVideos] = useState<Array<TrainingMaterial>>(
    []
  );
  const [userGuides, setUserGuides] = useState<Array<TrainingMaterial>>([]);

  useAsync(async () => {
    try {
      const response = await new TrainingMaterialApi().getTrainingMaterialList();
      setTrainingMaterials(response.data);
    } catch (e) {
      setTrainingMaterials([]);
      toast.error(e.message);
    }
  }, []);

  useEffect(() => {
    trainingMaterials.length > 0 &&
      setUserGuides(
        trainingMaterials.filter(
          (trainingMaterial) =>
            trainingMaterial.organisation === provider &&
            trainingMaterial.materialType === "User Guides"
        )
      );
  }, [trainingMaterials, provider]);

  return (
    <div>
      <Section>
        <SectionHeader>User Guides &amp; Support Information</SectionHeader>
        {userGuides.length > 0 ? (
          userGuides.map((userGuide) => {
            return (
              <SectionContent key={userGuide.id}>
                <IoInformationCircleSharp />
                <a target="_blank" href={userGuide.link} rel="noreferrer">
                  <span>{userGuide.title}</span>
                </a>
                <p>{userGuide.description} </p>
              </SectionContent>
            );
          })
        ) : (
          <SectionContent>No User Guides available</SectionContent>
        )}
      </Section>

      <Section>
        <SectionHeader>Training Videos</SectionHeader>
        {
          /* istanbul ignore next */ trainingVideos.length > 0 ? (
            trainingVideos.map((trainingVideo) => {
              return (
                <SectionContent key={trainingVideo.id}>
                  <a target="_blank" href={trainingVideo.link} rel="noreferrer">
                    <span>{trainingVideo.title}</span>
                  </a>
                  <p>{trainingVideo.description} </p>
                </SectionContent>
              );
            })
          ) : (
            <SectionContent>No Training Videos available</SectionContent>
          )
        }
      </Section>
    </div>
  );
};

export default TrainingVideos;
