import { get } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useAsyncFn } from "react-use";
import {
  Notification,
  NotificationApi,
  NotificationDisplay,
} from "../apis/merchant";
import { useAuth } from "./useAuth";

export const NotificationsContext = createContext<{
  notificationList?: Array<Notification>;
  count?: number;
  refresh?: () => void;
  getDisplayList?: () => Promise<NotificationDisplay[]>;
}>({
  notificationList: [],
  count: 0,
  refresh: /* istanbul ignore next */ () => {},
  getDisplayList: /* istanbul ignore next */ () => Promise.reject(),
});

export const NotificationsProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const [notificationList, refresh] = useAsyncFn(async () => {
    if (!isAuthenticated) {
      return [];
    }

    try {
      const res = await new NotificationApi().getNotificationList(5);
      return res.data;
    } catch (err) {
      return [];
    }
  }, [isAuthenticated]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const getDisplayList = useCallback(async () => {
    try {
      const res = await new NotificationApi().getNotificationDisplayList(5);
      refresh();
      return res.data;
    } catch (e) {
      throw new Error(
        get(e, "response.data.message", "Failed to load notifications")
      );
    }
  }, [refresh]);

  return (
    <NotificationsContext.Provider
      value={{
        notificationList: notificationList.value || [],
        count: notificationList.value?.length || 0,
        refresh,
        getDisplayList,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
