import { ListGroup as BsListGroup } from "react-bootstrap"
import styled from "styled-components";

export interface ListGroupProps {

}

const StyledListGroup = styled(BsListGroup)`
  margin: 1rem auto;
  padding: 0 0.5rem;
`;

export const ListGroup: React.FC<ListGroupProps> = ({ children }) => {
  return <StyledListGroup>{children}</StyledListGroup>;
};
