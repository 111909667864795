import {
  Menu,
  MenuHeader,
  Section,
  SectionContent,
  SectionHeader,
} from "components";

const Home = () => {
  return (
    <>
      <div>
        <Menu>
          <MenuHeader>Dashboard</MenuHeader>
        </Menu>
      </div>
      <Section>
        <SectionHeader>Message </SectionHeader>
        <SectionContent>
          Welcome to Health Merchant Reconciliation and Reporting Portal
        </SectionContent>
      </Section>
    </>
  );
};

export default Home;
