import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import {
  Button,
  DatePicker,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components";
import { Input } from "../../../components/Input";
import { CurrencyInput } from "../../../components/Input/CurrencyInput";
import { MedicalService } from "../../../types";
interface ServiceItemProps {
  serviceItem: MedicalService;
  open: boolean;
  handleClose: () => void;
  onDelete: (item: MedicalService) => void;
  onAdd: (item: MedicalService) => void;
  onUpdate: (item: MedicalService) => void;
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  serviceItem,
  open,
  handleClose,
  onDelete,
  onAdd,
  onUpdate,
}) => {
  const isNewServiceItem = !serviceItem.itemNumber;

  const formik = useFormik<MedicalService>({
    enableReinitialize: true,
    initialValues: {
      ...serviceItem,
    },
    validationSchema: yup.object({
      serviceDate: yup
        .date()
        .typeError("Please enter a valid date")
        .nullable()
        .max(new Date(), "Service date must not be a date in the future")
        .required("Service date is required"),
      itemNumber: yup.string().required("Item code is required"),
      chargeAmount: yup
        .number()
        .nullable()
        .min(1, "Minimum charge amount is $1.00")
        .required("Charge amount is required"),
    }),
    onSubmit: (values) => {
      const service: MedicalService = {
        ...values,
        id: serviceItem.id,
        chargeAmount: Number(values.chargeAmount),
      };

      if (isNewServiceItem) {
        onAdd(service);
      } else {
        onUpdate(service);
      }

      handleModelClose();
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldValue,
    submitForm,
  } = formik;

  const handleModelClose = () => {
    resetForm();
    handleClose();
  };

  const handleDelete = () => {
    onDelete(serviceItem);
    handleModelClose();
  };

  const form = (
    <form>
      <Input
        error={touched.itemNumber && Boolean(errors.itemNumber)}
        errorMessage={errors.itemNumber}
        id="itemNumber"
        label="Service Item Code"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Service Item Code"
        value={values.itemNumber}
      />

      <DatePicker
        disableFuture
        error={touched.serviceDate && Boolean(errors.serviceDate)}
        errorMessage={errors.serviceDate as string}
        id="serviceDate"
        label="Date of Service"
        onBlur={handleBlur("serviceDate")}
        onChange={(val) => setFieldValue("serviceDate", val)}
        value={values.serviceDate}
      />

      <CurrencyInput
        error={touched.chargeAmount && Boolean(errors.chargeAmount)}
        errorMessage={errors.chargeAmount}
        id="chargeAmount"
        label="Charge Amount"
        onBlur={handleBlur}
        onValueChange={(value) => setFieldValue("chargeAmount", value)}
        placeholder="$0.00"
        value={values.chargeAmount}
      />
    </form>
  );

  return (
    <Modal show={open} onClose={handleModelClose}>
      <ModalHeader>Add a Service</ModalHeader>
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        {!isNewServiceItem && (
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        )}
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={handleModelClose} variant="light">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={() => submitForm()}
        >
          {isNewServiceItem ? "Add" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceItem;
