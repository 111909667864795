import { lighten, transparentize } from "polished";
import { useContext } from "react";
import { Form as BsForm } from "react-bootstrap";
import ReactSelect from "react-select";
import styled, { ThemeContext } from "styled-components";

export type SelectOptionType = {
  label: string;
  value: any;
};

export interface SelectProps {
  error?: boolean;
  errorMessage?: string;
  helperText?: string;
  id?: string;
  label?: string;
  onBlur?: React.FormEventHandler;
  onChange?: (selected: SelectOptionType | null) => void;
  options?: Array<SelectOptionType>;
  placeholder?: string;
  readOnly?: boolean;
  value?: SelectOptionType | null;
}

const SelectWrapper = styled.div`
  .invalid-feedback {
    color: ${(props) => props.theme?.error?.main};
  }
`;

export const Select: React.FC<SelectProps> = ({
  error = false,
  errorMessage,
  helperText,
  id,
  label,
  onBlur,
  onChange,
  options,
  readOnly = false,
  value,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <BsForm.Group>
      {label && <BsForm.Label htmlFor={id}>{label}</BsForm.Label>}
      <SelectWrapper>
        <ReactSelect
          className={`react-select ${error ? "has-error" : ""}`}
          classNamePrefix="react-select"
          inputId={id}
          isDisabled={readOnly}
          onBlur={onBlur}
          onChange={onChange}
          options={options}
          styles={
            themeContext
              ? {
                  control: (base: any) => ({
                    ...base,
                    borderColor: error
                      ? themeContext.error.main
                      : readOnly
                      ? "rgb(206, 212, 218)"
                      : base.borderColor,
                    "&:hover": {
                      boxShadow: `0 0 0 0.2rem ${transparentize(
                        error ? 0.75 : 0.25,
                        error
                          ? themeContext.error?.main
                          : themeContext.primary?.main
                      )}`,
                    },
                    backgroundColor: readOnly
                      ? "#e9ecef"
                      : base.backgroundColor,
                  }),
                  singleValue: (base: any) => ({
                    ...base,
                    color: "#495057",
                  }),
                  option: (base, state) => ({
                    ...base,
                    color:
                      state.isSelected || state.isFocused
                        ? themeContext.primary.contrastText
                        : base.color,
                  }),
                }
              : undefined
          }
          theme={
            themeContext
              ? (theme: any) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: themeContext.primary?.main,
                    primary75: lighten(0.025, themeContext.primary?.main),
                    primary50: lighten(0.075, themeContext.primary?.main),
                    primary25: lighten(0.1, themeContext.primary?.main),
                  },
                })
              : undefined
          }
          value={value}
        />
        {error && errorMessage && (
          <BsForm.Control.Feedback type="invalid" className="d-block">
            {errorMessage}
          </BsForm.Control.Feedback>
        )}
      </SelectWrapper>

      {helperText && (
        <BsForm.Text className="text-muted">{helperText}</BsForm.Text>
      )}
    </BsForm.Group>
  );
};
