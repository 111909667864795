import { PatientClaimType } from ".";
import { MedicalService, PatientClaim } from "../../../types";
import { post } from "../http";
import { Identity } from "../models/identity";
import { Membership } from "../models/membership";
import { PatientClaimInteractiveRequest } from "../models/patientClaimInteractiveRequest";
import { PatientClaimInteractiveResponse } from "../models/patientClaimInteractiveResponse";
import { PatientClaimInteractiveService } from "../models/patientClaimInteractiveService";
import { formatCurrencyAmountForServer, formatDateForServer } from "../utils";

export async function postPatientClaim(
  type: PatientClaimType,
  claim: PatientClaim
): Promise<PatientClaim> {
  try {
    const response = await post<PatientClaimInteractiveResponse>(
      `/claim/${type}`,
      mapPatientClaimInteractiveRequestFromPatientClaim(claim)
    );
    return mapPatientClaimInteractiveResponseToPatientClaim(response);
  } catch (error) {
    throw error;
  }
}

function mapPatientClaimInteractiveRequestFromPatientClaim(
  claim: PatientClaim
): PatientClaimInteractiveRequest {
  if (!claim) return null;

  const identity: Identity = {
    dateOfBirth: formatDateForServer(claim.patient.dateOfBirth),
    familyName: claim.patient.lastName?.trim(),
    givenName: claim.patient.firstName?.trim() || "Onlyname",
  };

  const medicare: Membership = {
    memberNumber: claim.patient.medicareNumber,
    memberRefNumber: claim.patient.medicareRefNumber,
  };

  return {
    patientClaimInteractive: {
      patient: {
        identity,
        medicare,
      },
      claimant: {
        identity,
        medicare,
      },
      serviceProvider: {
        providerNumber: claim.serviceProvider.providerNumber,
      },
      referralOverrideCode:
        claim.serviceProvider.serviceType === "general" ? undefined : "N",
      accountPaidInd: "N",
      submissionAuthorityInd: "Y",
      authorisationDate: formatDateForServer(new Date()),
      medicalEvent: [
        {
          id: "01",
          medicalEventDate: formatDateForServer(
            claim.medicalServices[0]?.serviceDate
          ),
          service: claim.medicalServices.map<PatientClaimInteractiveService>(
            (service) => {
              return {
                id: service.id.padStart(4, "0"),
                chargeAmount: formatCurrencyAmountForServer(
                  service.chargeAmount
                ),
                itemNumber: service.itemNumber,
              };
            }
          ),
        },
      ],
    },
  };
}

function mapPatientClaimInteractiveResponseToPatientClaim(
  response: PatientClaimInteractiveResponse
): PatientClaim {
  return {
    medicalServices: response.claimAssessment?.medicalEvent[0]?.service.map<
      MedicalService
    >((service) => {
      return {
        id: service.id,
        error: service.error,
      };
    }),
  };
}
