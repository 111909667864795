import { BaseAPI } from "./base";
export class AuthApi extends BaseAPI {
  constructor() {
    super();
    this.axios.defaults.baseURL = this.basePath;
  }

  async login(username: string, password: string) {
    const response: any = await this.axios.post(
      `/v1/login/process`,
      new URLSearchParams({ username, password }),
      {
        baseURL: this.basePath,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      }
    );
    return response.httpStatusCode;
  }

  async logout() {
    const response: any = await this.axios.get(`/v1/logout`);
    return response.httpStatusCode;
  }

  async resetPasswordRequest(username: string) {
    const response = await this.axios.post(`/v1/reset-password/request`, {
      username,
    });

    return response;
  }

  async resetPasswordCheck(token: string) {
    const response = await this.axios.post(`/v1/reset-password/check`, {
      token,
    });

    return response;
  }

  async resetPasswordConfirm(
    token: string,
    newPassword: string,
    retypePassword: string
  ) {
    const response = await this.axios.post(`/v1/reset-password/confirm`, {
      token,
      newPassword,
      retypePassword,
    });

    return response;
  }
}
