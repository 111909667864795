import axios, { AxiosRequestConfig } from "axios";
import { merge } from "lodash";

const {
  REACT_APP_MEDICARE_CLAIM_ENDPOINT_URL = "",
  REACT_APP_MEDICARE_CLAIM_AUDIT_ID,
  REACT_APP_MEDICARE_CLAIM_SUBJECT_ID,
  REACT_APP_MEDICARE_CLAIM_SUBJECT_ID_TYPE,
} = process.env;

function getMedicareOnlineRequestHeader() {
  return {
    "audit-Id": REACT_APP_MEDICARE_CLAIM_AUDIT_ID,
    "subject-Id": REACT_APP_MEDICARE_CLAIM_SUBJECT_ID,
    "subject-id-type": REACT_APP_MEDICARE_CLAIM_SUBJECT_ID_TYPE,
    Authorization: "Bearer " + getAccessToken(),
    "Content-Type": "application/json",
  };
}

function mergeRequestConfig(
  defaultConfig: AxiosRequestConfig,
  customConfig: AxiosRequestConfig
): AxiosRequestConfig {
  return merge({}, defaultConfig, customConfig);
}

const axiosInstance = axios.create({
  baseURL: REACT_APP_MEDICARE_CLAIM_ENDPOINT_URL,
});

axiosInstance.interceptors.response.use(null, (error) => {
  return new Promise((resolve, reject) => {
    // Retry 401 Error
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      refreshAccessToken()
        .then((token) => {
          error.config.__isRetryRequest = true;
          error.config.headers.Authorization = "Bearer " + token;
          axios(error.config).then(resolve, reject);
        })
        .catch((error) => reject(error));
    } else if (error.response?.data?.message) {
      reject(error.response.data);
    } else if (error.response?.data?.error_description) {
      reject({ message: error.response.data.error_description });
    } else {
      reject(error);
    }
  });
});

export const get = <T>(
  url: string,
  params?: object,
  config?: Omit<AxiosRequestConfig, "params">
) => {
  return new Promise<T>((resolve, reject) => {
    axiosInstance
      .get(
        url,
        mergeRequestConfig(
          { params, headers: getMedicareOnlineRequestHeader() },
          config
        )
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const post = <T>(
  url: string,
  payload?: any,
  config?: Omit<AxiosRequestConfig, "data">
) => {
  return new Promise<T>((resolve, reject) => {
    axiosInstance
      .post(
        url,
        payload,
        mergeRequestConfig(
          { headers: getMedicareOnlineRequestHeader() },
          config
        )
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function getAccessToken() {
  return sessionStorage.getItem("medicareToken");
}

function setAccessToken(token: string) {
  sessionStorage.setItem("medicareToken", token);
}

function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    post<any>("/token", {
      assertion: localStorage.getItem("medicareAssertion"),
      clientId: localStorage.getItem("medicareClientId"),
    })
      .then(({ access_token }) => {
        setAccessToken(access_token);
        resolve(access_token);
      })
      .catch((error) => reject(error));
  });
}
