import { Badge } from "react-bootstrap";
import { BsFillBellFill } from "react-icons/bs";
import styled from "styled-components";
import { useNotifications } from "../../hooks";

const BadgeStyle = styled(Badge)`
  font-size: 0.6rem;
  position: absolute;
  left: 0.4rem;
  top: -0.3rem;
  background-color: ${(props) => props.theme.primary?.main};
  color: ${(props) => props.theme.primary?.contrastText};
  z-index: 2;
`;

export const NotificationIcon = () => {
  const { count } = useNotifications();

  return (
    <>
      <BsFillBellFill />
      {!!count && <BadgeStyle pill>{count}</BadgeStyle>}
    </>
  );
};
