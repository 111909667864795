import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../components";
import { CurrencyInput } from "../../../components/Input/CurrencyInput";
import { useAuth } from "../../../hooks";
import { PatientClaim } from "../../../types";

interface FormValues extends Pick<PatientClaim, "medicalServices"> {
  amount: string;
  referenceNumber: string;
  emailOrPhone: string;
}

yup.addMethod(yup.string, "or", function (schemas, msg) {
  return this.test({
    name: "or",
    message: msg,
    test: (value) => {
      if (Array.isArray(schemas) && schemas.length > 0) {
        const resee = schemas.map((schema) => schema.isValidSync(value));
        return resee.some((res) => res);
      } else {
        throw new TypeError("Schemas is not correct array schema");
      }
    },
    exclusive: false,
  });
});

const practice = "Ramsay - Melbourne CBD";

export const PaymentForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    values,
  } = useFormik<FormValues>({
    initialValues: {
      amount: "",
      referenceNumber: "",
      emailOrPhone: "",
    },
    validationSchema: yup.object({
      amount: yup.string().required("Please enter an amount"),
      referenceNumber: yup
        .string()
        .required("Please enter the reference number"),
      emailOrPhone: (yup.string() as any)
        .required("Please enter a valid email or phone number")
        .or(
          [yup.string().email(), yup.string().matches(/^0?[1-9]\d{8}$/)],
          "Please enter a valid email or phone number"
        ),
    }),
    onSubmit: async ({ amount, emailOrPhone, referenceNumber }) => {
      const isEmail = emailOrPhone?.includes("@");

      try {
        setLoading(true);
        const payload = {
          username: "-M_cdl3PMJuOxwl7kb-N",
          passtoken: "ccda161e50064b559f2d6ebbdca0d811",
          action: "append",
          templateId: "39176693-3fa5-d2e3-e576-051026e9b184",
          autoStart: "True",
          csvData: "False",
          data: {
            mobile: !isEmail ? emailOrPhone : "",
            email: isEmail ? emailOrPhone : "",
            type: isEmail ? "email" : "sms",
            paymentTo: practice,
            amount: String(amount),
            referenceNo: referenceNumber,
            companyLogo: "Ramsay",
            turnCreditCard: "1",
            turnOnAfterpay: "1",
            turnOnAppleWallet: "0",
            estimateOption: "0",
          },
        };

        await axios.post(
          "/v1/upwire-job",
          { payload: JSON.stringify(payload) },
          { baseURL: (window as any).ENV?.MERCHANT_API_URL || "" }
        );
        toast.success(`A payment link has been sent to ${emailOrPhone}.`);
        resetForm();
      } catch (e) {
        toast.error(`Payment failed. ${e}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Section>
        <SectionHeader>Hospital Details</SectionHeader>
        <SectionContent>
          <Row>
            <Col sm={12} md={6}>
              <Input
                id="practiceName"
                label="Hospital Name"
                value={practice}
                readOnly
              />
            </Col>
            <Col sm={12} md={6}>
              <Input
                id="operator"
                label="Operator"
                value={`${user.firstName} ${user.surname}`}
                readOnly
              />
            </Col>
          </Row>
        </SectionContent>
      </Section>
      <Section>
        <SectionHeader>Payment Details</SectionHeader>
        <SectionContent>
          <Row>
            <Col sm={12} md={6}>
              <CurrencyInput
                id="amount"
                label="Amount"
                onValueChange={(value) => setFieldValue("amount", value)}
                value={values.amount}
                onBlur={handleBlur}
                error={touched.amount && Boolean(errors.amount)}
                errorMessage={touched.amount && (errors.amount as string)}
                placeholder="$0.00"
              />
            </Col>
            <Col sm={12} md={6}>
              <Input
                id="referenceNumber"
                label="Reference / Invoice Number"
                onChange={handleChange}
                value={values.referenceNumber}
                onBlur={handleBlur}
                error={
                  touched.referenceNumber && Boolean(errors.referenceNumber)
                }
                errorMessage={
                  touched.referenceNumber && (errors.referenceNumber as string)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6}>
              <Input
                id="emailOrPhone"
                label="Email / Phone Number"
                onChange={handleChange}
                value={values.emailOrPhone}
                onBlur={handleBlur}
                error={touched.emailOrPhone && Boolean(errors.emailOrPhone)}
                errorMessage={
                  touched.emailOrPhone && (errors.emailOrPhone as string)
                }
                helperText="A payment link will be sent to this email address or phone number"
              />
            </Col>
          </Row>
        </SectionContent>
      </Section>

      <Container>
        <div className="text-right">
          <Button className="mr-2" variant="light" onClick={() => resetForm()}>
            Reset
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </Container>
    </>
  );
};
