import { includes } from "lodash";
import { createElement, FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AccountStatusEnum } from "../../apis/merchant";
import { useAuth } from "../../hooks";
import { Role } from "../../types/role";
import { Spinner } from "../Spinner";

interface PrivateRouteProps extends RouteProps {
  roles?: Array<Role>;
  status?: Array<AccountStatusEnum>;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  component,
  roles,
  status,
  ...rest
}) => {
  const { isAuthenticated, isLoading, user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return (
            <div className="mt-4 text-center">
              <Spinner />
            </div>
          );
        }

        if (!isAuthenticated || !user) {
          return <Redirect to="/login" />;
        }

        /* Roles */
        if (roles && roles.indexOf(user.profile?.name as Role) === -1) {
          return <Redirect to="/" />;
        }

        /* Status */
        if (user.status && !includes(status, user.status)) {
          if (user.status === "NEW") {
            return <Redirect to="/change-password" />;
          }
        }

        return children ? children : createElement(component, props);
      }}
    />
  );
};
