import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import {
  NonConsolidatedHealthFund,
  NonConsolidatedReportingFeatureManagementApi,
} from "../../../apis/merchant";
import {
  Button,
  Container,
  Nav,
  NavItem,
  Section,
  SectionContent,
  SectionHeader,
  Switch,
  Table,
  TableColumnType,
} from "../../../components";

export const Features = () => {
  const [healthFundsList, setHealthFundsList] = useState<
    Array<NonConsolidatedHealthFund>
  >([]);
  const [defaultHealthFunds, setDefaultHealthFunds] = useState<
    Array<NonConsolidatedHealthFund>
  >([]);
  const [loading, setLoading] = useState(false);
  const skipPageResetRef = useRef<boolean>();

  useAsync(async () => {
    setLoading(true);
    try {
      const res: any = await new NonConsolidatedReportingFeatureManagementApi().getNonConsolidatedExcludedFunds();
      setHealthFundsList(res.data);
      setDefaultHealthFunds(res.data);
    } catch (e) {
      toast.error(e.message);
      setHealthFundsList([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const excludedHealthFundHandler = useCallback(
    (healthFundId: number, checked: boolean) => {
      skipPageResetRef.current = true;
      setHealthFundsList(
        healthFundsList.map((fund) => {
          return fund.id === healthFundId
            ? {
                ...fund,
                excluded: checked,
              }
            : fund;
        })
      );
    },
    [healthFundsList]
  );

  useEffect(() => {
    skipPageResetRef.current = false;
  });

  const savedExcludedHealthFundsHandler = async () => {
    setLoading(true);
    const excludedHealthFundsList = healthFundsList
      .filter((fund) => fund.excluded === true)
      .map((fund) => fund.id);

    try {
      await new NonConsolidatedReportingFeatureManagementApi().saveNonConsolidatedExcludedFunds(
        excludedHealthFundsList
      );
      toast.success("Changes were saved successfully.");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const resetExcludedHealthFundsHandler = () => {
    setHealthFundsList(defaultHealthFunds);
  };

  const columns = useMemo<Array<TableColumnType<NonConsolidatedHealthFund>>>(
    () => [
      {
        Header: "PHF ID",
        accessor: "phfId",
      },
      {
        Header: "PHF Name",
        accessor: "phfName",
      },
      {
        Header: "Excluded",
        accessor: (row) => (
          <Switch
            id={row.phfId}
            checked={row.excluded}
            onChange={(e) =>
              excludedHealthFundHandler(row.id, e.target.checked)
            }
          />
        ),
      },
    ],
    [excludedHealthFundHandler]
  );

  return (
    <>
      <Container>
        <Nav>
          <NavItem to="/administration/features">
            Non Consolidated Report
          </NavItem>
        </Nav>
        <br />
      </Container>
      <Section>
        <SectionHeader>Health Fund Exclusion</SectionHeader>
        <SectionContent>
          <Table
            columns={columns}
            data={healthFundsList}
            compact
            hover
            striped
            loading={loading}
            noDataMessage="No funds to display"
            paginated
            autoResetPage={!skipPageResetRef.current}
          />
          <Button
            className="mr-2"
            variant="primary"
            loading={loading}
            onClick={savedExcludedHealthFundsHandler}
          >
            Save
          </Button>
          <Button variant="light" onClick={resetExcludedHealthFundsHandler}>
            Reset
          </Button>
        </SectionContent>
      </Section>
    </>
  );
};
