import { format as dateFnsFormat, parse as dateFnsParse } from "date-fns";
import { transparentize } from "polished";
import React from "react";
import { Form as BsForm } from "react-bootstrap";
import { DateUtils, DayPickerInputProps, Modifier } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { BsCalendarFill } from "react-icons/bs";
import styled from "styled-components";

function parseDate(str: string, format: string, locale: any) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: Date, format: string, locale: any) {
  return dateFnsFormat(date, format, { locale });
}
const FORMAT = "dd/MM/y";

export interface DatePickerProps {
  disableFuture?: boolean;
  disablePast?: boolean;
  disableWeekends?: boolean;
  error?: boolean;
  errorMessage?: string;
  helperText?: string;
  id?: string;
  label?: string;
  onBlur?: React.FocusEventHandler;
  onChange?: (date: Date) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: Date;
}

const classNames = (DayPickerInput as any).defaultProps.classNames;
const DayPickerWrapper = React.forwardRef<
  DayPickerInput,
  DayPickerInputProps & { className?: string }
>(({ className = "", ...rest }, ref) => {
  return (
    <DayPickerInput
      classNames={{
        ...classNames,
        container: `${classNames.container} ${className}`,
      }}
      {...rest}
      ref={ref}
    />
  );
});

const InputWrapper = styled.div`
  position: relative;
  .date-picker-input-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.625rem;
  }

  .form-control:not(.is-invalid):focus {
    border-color: ${(props) => props.theme.primary?.main};
    box-shadow: 0 0 0 0.2rem
      ${(props) =>
        props.theme.primary?.main
          ? transparentize(0.25, props.theme.primary?.main)
          : null};
  }
  .form-control.is-invalid {
    background: none;
    border-color: ${(props) => props.theme.error?.main};
    &:focus {
      border-color: ${(props) => props.theme.error?.main};
      box-shadow: 0 0 0 0.2rem
        ${(props) =>
          props.theme.primary?.main
            ? transparentize(0.25, props.theme.primary?.main)
            : null};
    }
  }
  .invalid-feedback {
    color: ${(props) => props.theme.error?.main};
  }
`;

const StyledDayPickerInput = styled(DayPickerWrapper)`
  width: 100%;

  .form-control {
    padding-right: 2.5rem;
  }

  .DayPicker {
    &-wrapper {
      background-color: white;
    }

    &-Month {
    }

    &-Weekday {
    }

    &-Day {
      line-height: 1.25rem;

      &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: ${(props) => props.theme.primary?.main};
        color: ${(props) => props.theme.primary?.contrastText};
      }

      &--today {
        color: ${(props) => props.theme.secondary?.main};
      }
    }

    &-Caption > div {
      text-align: center;
    }
  }
`;

export const DatePicker = React.forwardRef<DayPickerInput, DatePickerProps>(
  (
    {
      disableFuture = false,
      disablePast = false,
      disableWeekends = false,
      error = false,
      errorMessage,
      helperText,
      id,
      label,
      onBlur,
      onChange,
      placeholder = "dd/MM/yyyy",
      readOnly = false,
      value,
    },
    ref
  ) => {
    let disableModifier: Modifier;
    if (disableFuture) {
      disableModifier = { after: new Date() };
    } else if (disablePast) {
      disableModifier = { before: new Date() };
    }

    if (disableWeekends) {
      disableModifier = { ...disableModifier, daysOfWeek: [0, 6] };
    }

    return (
      <BsForm.Group>
        {label && <BsForm.Label htmlFor={id}>{label}</BsForm.Label>}

        <InputWrapper>
          <StyledDayPickerInput
            dayPickerProps={{ disabledDays: disableModifier }}
            format={FORMAT}
            formatDate={formatDate}
            inputProps={{
              id,
              disabled: readOnly,
              className: `form-control ${error ? "is-invalid" : ""}`,
            }}
            onBlur={onBlur}
            onDayChange={onChange}
            parseDate={parseDate}
            placeholder={placeholder}
            ref={ref}
            value={value}
          />
          <BsCalendarFill
            className="date-picker-input-icon"
            size="20"
            style={{ pointerEvents: "none" }}
          />
          {errorMessage && error && (
            <BsForm.Control.Feedback type="invalid" className="d-block">
              {errorMessage}
            </BsForm.Control.Feedback>
          )}
        </InputWrapper>

        {helperText && (
          <BsForm.Text className="text-muted">{helperText}</BsForm.Text>
        )}
      </BsForm.Group>
    );
  }
);
