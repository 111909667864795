import { sanitize } from "dompurify";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import { NotificationDisplay } from "../../apis/merchant";
import { Accordion, AccordionItem, Menu, MenuHeader } from "../../components";
import { useNotifications } from "../../hooks";

export const Notification: React.FC = () => {
  const [displayList, setDisplayList] = useState<NotificationDisplay[]>([]);
  const { getDisplayList } = useNotifications();

  useAsync(async () => {
    try {
      const data = await getDisplayList();
      setDisplayList(data);
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  return (
    <>
      <Menu>
        <MenuHeader>My Notifications</MenuHeader>
      </Menu>
      <Container>
        {displayList.map(({ dateText, notificationList }) => (
          <React.Fragment key={dateText}>
            <br />
            <h4>{dateText}</h4>
            {notificationList.map(({ id, subject, message }) => (
              <Accordion key={id}>
                <AccordionItem header={subject}>
                  <div
                    dangerouslySetInnerHTML={{ __html: sanitize(message) }}
                  />
                </AccordionItem>
              </Accordion>
            ))}
          </React.Fragment>
        ))}
      </Container>
    </>
  );
};
