import { useRef } from "react";
import { Container } from "../../components";

const MEDICARE_CLIENT_ID = "medicareClientId";
const MEDICARE_ASSERTION = "medicareAssertion";

export const MedicareDevices: React.FC = () => {
  const clientId = useRef(null);
  const assertion = useRef(null);
  const updateClientId = () => {
    localStorage.setItem(MEDICARE_CLIENT_ID, clientId.current.value);
  };
  const updateAssertion = () => {
    localStorage.setItem(MEDICARE_ASSERTION, assertion.current.value);
  };

  return (
    <Container>
      <p>
        <input
          defaultValue={localStorage.getItem(MEDICARE_CLIENT_ID)}
          ref={clientId}
          onBlur={updateClientId}
          placeholder="clientId"
        />
      </p>
      <p>
        <input
          defaultValue={localStorage.getItem(MEDICARE_ASSERTION)}
          ref={assertion}
          onBlur={updateAssertion}
          placeholder="assertion"
        />
      </p>
    </Container>
  );
};
