import { ThemeSchema } from "./schema";

const theme: ThemeSchema = {
  name: "ANZ",
  primary: {
    main: "#007DBA",
    dark: "#004165",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#4CD886",
    dark: "#00C752",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#DC3545",
    dark: "#DC3545",
    contrastText: "#FFFFFF",
  },
  button: {
    radius: {
      small: "0.25 rem",
      normal: "0.25 rem",
      large: "0.3 rem",
    },
    padding: {
      small: "0.3rem 0.5rem",
      normal: "0.5rem 0.75rem",
      large: "0.75rem 1.5rem",
    },
  },
};
export default theme;
