import { BrowserRouter, Route, Switch } from "react-router-dom";
import { NotFound } from "../../components/NotFound";
import { PrivateRoute } from "../../components";
import { Administration } from "../Administration";
import { ForgotPassword, Login, ResetPassword } from "../Auth";
import Help from "../Help";
import Home from "../Home";
import Layout from "../Layout";
import Notification from "../Notification";
import Reports from "../Reports";
import Telehealth from "../Telehealth";
import { ChangePassword } from "../Auth/ChangePassword";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <PrivateRoute
          path="/change-password"
          component={ChangePassword}
          status={["NEW"]}
        />
        <PrivateRoute>
          <Layout>
            <Switch>
              <PrivateRoute path="/reports" component={Reports} />
              <PrivateRoute path="/notification" component={Notification} />
              {process.env.REACT_APP_ENABLE_TELEHEALTH && (
                <PrivateRoute path="/telehealth" component={Telehealth} />
              )}
              <PrivateRoute path="/help" component={Help} />
              <PrivateRoute
                path="/administration"
                component={Administration}
                roles={["Power User", "System Admin"]}
              />
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute component={NotFound} />
            </Switch>
          </Layout>
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
