import React, { useContext } from "react";
import {
  Accordion as BsAccordion,
  AccordionContext,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

export interface AccordionItemProps {
  header: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Styles = styled.div`
  .toggle {
    display: flex;
    flex-direction: row;
    color: black;
    padding-left: 0;
    text-align: left;

    &:hover,
    &:focus {
      cursor: pointer;
      text-decoration: none;
    }

    .toggle-button {
      background-color: ${(props) => props.theme.primary?.main};
      color: ${(props) => props.theme.primary?.contrastText};
      margin-right: 0.75rem;
      border-radius: 0.25rem;
    }
  }

  .collapse-wrapper {
    margin-left: 2.5rem;
  }
`;

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  header,
  onClick,
}) => {
  const eventKey = String(header);
  const currentEventKey = useContext(AccordionContext);
  const isExpanded = currentEventKey === eventKey;

  return (
    <Styles>
      <BsAccordion.Toggle
        as={Button}
        variant="link"
        className="toggle"
        eventKey={eventKey}
        onClick={onClick}
      >
        <div className="toggle-button">
          {isExpanded ? <MdExpandLess size={25} /> : <MdExpandMore size={25} />}
        </div>
        <div className="toggle-header">{header}</div>
      </BsAccordion.Toggle>
      <BsAccordion.Collapse eventKey={eventKey}>
        <div className="collapse-wrapper">{children}</div>
      </BsAccordion.Collapse>
    </Styles>
  );
};
