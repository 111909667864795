import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Backdrop, SelectOptionType, Spinner } from "../../components";
import { PatientClaim, ServiceProvider } from "../../types";
import TelehealthClaim from "./Claim";
import TelehealthClaimEstimation from "./ClaimEstimation";
import { ClaimResultSuccess } from "./ClaimResult";

const newClaim: PatientClaim = {
  patient: {
    medicareNumber: "",
    medicareRefNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    sex: "",
  },
  serviceProvider: {
    providerNumber: "",
    serviceType: "",
  },
  medicalServices: [],
};

const serviceTypes: Array<SelectOptionType> = [
  { label: "General", value: "general" },
  { label: "Pathology", value: "pathology" },
  { label: "Specialist", value: "specialist" },
];

const providers: Array<ServiceProvider> = [
  { providerNumber: "2438221J", lastName: "Kent", firstName: "Lauretta" },
  { providerNumber: "2438231H", lastName: "Cook", firstName: "Chris" },
];

const mapServiceProviderToSelectOption = ({
  providerNumber,
  firstName,
  lastName,
}: ServiceProvider): SelectOptionType => ({
  value: providerNumber,
  label: [firstName, lastName].join(" "),
});

const HealthPoint = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [claim, setClaim] = useState(newClaim);

  // TODO: mocked api call for demonstration only
  const handleClaimSubmit = (claimCaptured: PatientClaim) => {
    setIsLoading(true);
    setIsEligible(true);
    setClaim({
      ...claimCaptured,
      medicalServices: claimCaptured.medicalServices.map((service) => {
        return {
          ...service,
          benefitAmount: service.chargeAmount - 20,
        };
      }),
    });
    setIsLoading(false);
  };

  const handleClaimReset = () => {
    setClaim(newClaim);
  };

  const handleEstimationReject = () => {
    setIsEligible(false);
    setIsSubmitted(false);
    setClaim({
      ...claim,
      medicalServices: claim.medicalServices.map((service) => ({
        ...service,
        benefitAmount: null,
        error: null,
      })),
    });
  };

  const handleEstimationAccept = async () => {
    setIsSubmitted(true);

    toast.success("Your claim has been submitted successfully!");
  };

  const handleFinish = () => {
    history.push("/telehealth");
  };

  return (
    <>
      <Backdrop open={isLoading} fixed>
        <Spinner />
      </Backdrop>

      {isSubmitted ? (
        <ClaimResultSuccess onFinish={handleFinish} />
      ) : isEligible ? (
        <TelehealthClaimEstimation
          onAccept={handleEstimationAccept}
          onReject={handleEstimationReject}
          isSubmitted={isSubmitted}
          medicalServices={claim.medicalServices}
          showGapAmount={true}
        />
      ) : (
        <TelehealthClaim
          initialValue={newClaim}
          providers={providers.map(mapServiceProviderToSelectOption)}
          serviceTypes={serviceTypes}
          onSubmit={handleClaimSubmit}
          onReset={handleClaimReset}
        />
      )}
    </>
  );
};

export default HealthPoint;
