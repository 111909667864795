/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DxcPracticeModel } from './dxc-practice-model';

/**
 * 
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * 
     * @type {number}
     * @memberof DeviceModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    type: DeviceModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceModel
     */
    removed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceModel
     */
    rootMenuId?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    hardwareName?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DeviceModel
     */
    attributes?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof DeviceModel
     */
    secretNeverExpires?: boolean;
    /**
     * 
     * @type {DxcPracticeModel}
     * @memberof DeviceModel
     */
    dxcPractice?: DxcPracticeModel;
}

/**
    * @export
    * @enum {string}
    */
export enum DeviceModelTypeEnum {
    Pms = 'PMS',
    MobileDevice = 'MOBILE_DEVICE',
    StandaloneTerminal = 'STANDALONE_TERMINAL',
    IntegratedTerminalApt = 'INTEGRATED_TERMINAL_APT',
    IntegratedTerminal = 'INTEGRATED_TERMINAL',
    Tablet = 'TABLET'
}



