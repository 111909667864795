import React from "react";
import styled from "styled-components";

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  id?: string;
  label?: string;
  readOnly?: boolean;
}

const StyledLabel = styled.label<{ isInvalid: boolean }>`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  color: ${(props) => (props.isInvalid ? props.theme.error?.main : "black")};

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 3px;
    box-shadow: ${(props) =>
      props.isInvalid ? "0 0 3px " + props.theme.error?.main : "none"};
    background-color: #eee;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
    transition: background-color 0.15s ease-in-out;
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.primary?.main};
    transition: background-color 0.15s ease-in-out;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    visibility: hidden;
  }

  input:checked ~ .checkmark:after {
    visibility: visible;
    transition: visibility 0.15s ease-in-out;
    transition-delay: 0.15s;
  }

  .checkmark:after {
    left: 8px;
    top: 2px;
    width: 9px;
    height: 18px;
    border: solid ${(props) => props.theme.primary?.contrastText || "white"};
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
  }

  input:disabled ~ .checkmark {
    background-color: #ccc !important;
  }
`;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, error = false, id, label, readOnly, ...props }, ref) => {
    return (
      <StyledLabel htmlFor={id} isInvalid={error}>
        {label}
        <input
          id={id}
          type="checkbox"
          ref={ref}
          disabled={readOnly}
          {...props}
        />
        <span className="checkmark"></span>
      </StyledLabel>
    );
  }
);
