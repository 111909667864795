import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => (
  <div style={{textAlign : "center"}}>
    <h1>404 - Not Found!</h1>
    <Link to="/">
      Go Dashboard
    </Link>
  </div>
);
