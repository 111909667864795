import React, { useEffect } from "react";
import { Table as BsTable } from "react-bootstrap";
import { useFilters, usePagination, useTable } from "react-table";
import styled from "styled-components";
import { Spinner } from "../Spinner";
import { TablePagination } from "./TablePagination";

export interface TableProps {
  columns: any;
  compact?: boolean;
  data: any;
  fetchData?: (pageSize: number, pageIndex: number) => void;
  hover?: boolean;
  lazyLoad?: boolean;
  loading?: boolean;
  noDataMessage?: string;
  pageCount?: number;
  paginated?: boolean;
  responsive?: string;
  striped?: boolean;
  autoResetPage?: boolean;
}

const StyledTable = styled(BsTable)`
  position: relative;

  thead tr:first-child th {
    border-top: none;
  }

  thead .filter-row th {
    padding-top: 0;
    padding-bottom: 0;
  }

  tbody tr.table-row-empty {
    text-align: center;
    font-size: 0.9rem;
    td {
      padding: 1rem;
      div {
        min-height: 1rem;
      }
    }
  }

  tbody tr.table-row-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    td {
      border-top: none;
      padding: 0;
    }
  }

  &.table-striped tbody tr.table-row-empty {
    background-color: transparent;
  }
`;

const getAdditionalHeaderProps = (column: any) => {
  const { align = "left", width } = column;
  return {
    style: { width },
    className: `text-${align}`,
  };
};

const getAdditionalCellProps = (column: any) => {
  const { align = "left" } = column;
  return {
    className: `text-${align}`,
  };
};

export const Table: React.FC<TableProps> = ({
  columns,
  compact = false,
  data,
  fetchData,
  hover = false,
  lazyLoad = false,
  loading = false,
  noDataMessage,
  pageCount: controlledPageCount,
  paginated = false,
  responsive = true,
  striped = false,
  autoResetPage = true,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      autoResetPage,
      ...(lazyLoad && {
        manualPagination: true,
        manualFilters: true,
        pageCount: controlledPageCount,
      }),
    },
    useFilters,
    usePagination
  );

  const hasFilter = headerGroups.some((headerGroup) =>
    headerGroup.headers.some((column) => Boolean(column.Filter))
  );

  const rowsToDisplay = paginated ? page : rows;

  useEffect(() => {
    if (lazyLoad) {
      fetchData(pageSize, pageIndex);
    }
  }, [lazyLoad, fetchData, pageIndex, pageSize]);

  return (
    <>
      <StyledTable
        responsive={responsive}
        striped={striped}
        hover={hover}
        size={compact ? "sm" : undefined}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <React.Fragment key={i}>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(getAdditionalHeaderProps(column))}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
              {hasFilter && (
                <tr className="filter-row">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.Filter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              )}
            </React.Fragment>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading && (
            <tr className="table-row-loading">
              <td colSpan={9999}>
                <Spinner />
              </td>
            </tr>
          )}
          {rowsToDisplay.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps(
                        getAdditionalCellProps(cell.column)
                      )}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {!rowsToDisplay?.length && (noDataMessage || loading) && (
            <tr className="table-row-empty">
              <td colSpan={9999}>
                <div>{noDataMessage}</div>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>

      {paginated && !!rowsToDisplay?.length && (
        <TablePagination
          disabled={loading}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
};
