import { Form as BsForm } from "react-bootstrap";
import styled from "styled-components";
import { Radio } from "./Radio";

export interface RadioGroupProps {
  error?: boolean;
  errorMessage?: string;
  helperText?: string;
  inline?: boolean;
  label?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (selected: any) => void;
  options: Array<{ label: any; value: string; disabled?: boolean }>;
  readOnly?: boolean;
  selected?: string;
}

const Styles = styled(BsForm.Group)`
  .invalid-feedback {
    display: block;
    color: ${(props) => props.theme.error?.main};
  }
`;

export const RadioGroup: React.FC<RadioGroupProps> = ({
  error = false,
  errorMessage,
  helperText,
  label,
  inline = false,
  name,
  onBlur,
  onChange,
  options,
  readOnly = false,
  selected,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <Styles>
      {label && <BsForm.Label className="d-block">{label}</BsForm.Label>}

      {options?.map(({ label, value, disabled }) => (
        <Radio
          key={value}
          name={name}
          id={value}
          value={value}
          error={error}
          readOnly={readOnly || disabled}
          inline={inline}
          label={label}
          checked={value === selected}
          onBlur={onBlur}
          onChange={handleChange}
        />
      ))}
      {errorMessage && (
        <BsForm.Control.Feedback type="invalid" className="d-block">
          {errorMessage}
        </BsForm.Control.Feedback>
      )}

      {helperText && (
        <BsForm.Text className="text-muted">{helperText}</BsForm.Text>
      )}
    </Styles>
  );
};
