import React from "react";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";
import ImgDVA from "../../assets/images/DVA.png";
import ImgHealthPoint from "../../assets/images/HealthPoint.jpg";
import ImgMOL from "../../assets/images/MOL.png";
import ImgNDIS from "../../assets/images/NDIS.png";
import ImgTAC from "../../assets/images/TAC.png";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Menu,
  MenuHeader,
  MenuItem,
  MenuList,
  Row,
} from "../../components";
import HealthPoint from "./HealthPoint";
import MedicareOnline from "./MedicareOnline";
import Payment from "./Payment";

interface TelehealthItemProps {
  title: string;
  subtitle?: string;
  logo?: string | React.ReactNode;
  link?: string;
  component?: React.ComponentType;
}

const telehealthItems: TelehealthItemProps[] = [
  {
    title: "Create an Invoice",
    logo: <FaFileInvoiceDollar size={40} />,
    link: "/telehealth/payment",
    component: Payment,
  },
  {
    title: "Medicare Online",
    subtitle: "Check Medicare Online Eligibility and make a claim",
    logo: ImgMOL,
    link: "/telehealth/mol",
    component: MedicareOnline,
  },
  {
    title: "Allied Private Health Insurance",
    logo: ImgHealthPoint,
    link: "/telehealth/healthpoint",
    component: HealthPoint,
  },
  {
    title: "Overseas Health Cover",
    logo: ImgHealthPoint,
    link: "#",
  },
  {
    title: "Department of Veterans' Affairs",
    logo: ImgDVA,
  },
  {
    title: "National Disability Scheme",
    logo: ImgNDIS,
  },
  {
    title: "Transport Accident Commission",
    subtitle: "Perform transport claim",
    logo: ImgTAC,
  },
  {
    title: "Workers Compensation",
  },
];

const Telehealth: React.FC = () => {
  const location = useLocation();

  return (
    <div>
      <Menu>
        <MenuHeader>Telehealth</MenuHeader>
        {location?.pathname !== "/telehealth" && (
          <MenuList>
            {telehealthItems.map(({ title, link }) => (
              <MenuItem key={title} to={link}>
                {title}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
      <Switch>
        <Route path="/telehealth" exact component={TelehealthList} />
        {telehealthItems
          .filter((item) => Boolean(item.component))
          .map(({ title, link, component }) => (
            <Route key={title} path={link} component={component} />
          ))}
      </Switch>
    </div>
  );
};

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 136px;
    min-height: 50px;
    margin-right: 1rem;
    img {
      width: 120px;
    }
  }

  .title-group {
    .title {
      font-size: 1.25rem;
    }

    .subtitle {
      margin-bottom: 0;
      color: #888;
    }
  }
`;

const TelehealthList = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <ListGroup>
          {telehealthItems.map(({ title, subtitle, logo, link }) => (
            <ListGroupItem key={title} to={link}>
              <StyledItem>
                <div className="logo-wrapper">
                  {typeof logo === "string" ? (
                    <img src={logo} alt={title} />
                  ) : !!logo ? (
                    logo
                  ) : (
                    <span>{title.charAt(0)}</span>
                  )}
                </div>
                <div className="title-group">
                  <h4 className="title">{title}</h4>
                  {subtitle && <p className="subtitle">{subtitle}</p>}
                </div>
              </StyledItem>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Row>
    </Container>
  );
};

export default Telehealth;
