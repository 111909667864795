import { useState } from "react";
import {
  Button,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../components";

const SendEmail = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="primary" onClick={handleOpen}>
        Email/SMS Claim Receipt
      </Button>
      <Modal show={open} onClose={handleClose}>
        <ModalHeader>Email Claim Receipt</ModalHeader>
        <ModalBody>
          <Input
            id="email"
            label="Enter Patient's Email Address"
            type="email"
          />
          An email with claim receipt will be sent to the above email address.
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClose} variant="light">
            Cancel
          </Button>
          <Button onClick={handleClose} variant="primary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

interface ClaimResultSuccessProps {
  onFinish: () => any;
}

export const ClaimResultSuccess: React.FC<ClaimResultSuccessProps> = ({
  onFinish,
}) => {
  return (
    <div>
      <Section>
        <SectionHeader>Claim Result</SectionHeader>
        <SectionContent>
          <div className="text-center">
            <h1>Claim Successful!</h1>
            <p>
              Money will be deposited in patient registered bank account with
              Medicare.
            </p>
            <Button
              className="mr-2"
              variant="primary"
              onClick={() => {
                window.print();
              }}
            >
              Print Claim Receipt
            </Button>

            <SendEmail />
          </div>
        </SectionContent>
      </Section>
      <Container className="text-right">
        <Button variant="primary" onClick={onFinish}>
          Finish
        </Button>
      </Container>
    </div>
  );
};
