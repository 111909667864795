import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button, Input, PasswordInput } from "../../components";
import { useAuth } from "../../hooks";
import styles from "./Auth.module.scss";
import { AuthLayout } from "./Layout";

interface FormValue {
  password: string;
  newPassword: string;
  retypePassword: string;
}

export const ChangePassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { user, changePassword } = useAuth();

  const formik = useFormik<FormValue>({
    initialValues: {
      password: "",
      newPassword: "",
      retypePassword: "",
    },
    validationSchema: yup.object({
      password: yup.string().required("Password can not be empty."),
      newPassword: yup
        .string()
        .required("New password can not be empty.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "New password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character and at least 8 characters."
        )
        .notOneOf(
          [yup.ref("password")],
          "New password can not be same as the old password."
        ),
      retypePassword: yup
        .string()
        .required("Retype password can not be empty.")
        .equals(
          [yup.ref("newPassword")],
          "New password and retype password must be the same."
        ),
    }),
    onSubmit: async ({ password, newPassword, retypePassword }) => {
      setLoading(true);
      try {
        await changePassword(password, newPassword);
        toast.success(`Your password has been updated successfully.`);
        history.push("/");
      } catch (e) {
        toast.error(e.message);
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <AuthLayout>
      <div className={styles.card}>
        <div className={styles.form}>
          <h2 className={styles.header}>Change password</h2>

          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Input
              id="username"
              label="Username"
              value={user.username}
              readOnly
            />

            <PasswordInput
              error={touched.password && Boolean(errors.password)}
              errorMessage={errors.password}
              id="password"
              label="Password"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Password"
              value={values.password}
            />

            <PasswordInput
              error={touched.newPassword && Boolean(errors.newPassword)}
              errorMessage={errors.newPassword}
              id="newPassword"
              label="New password"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="New password"
              value={values.newPassword}
            />

            <PasswordInput
              error={touched.retypePassword && Boolean(errors.retypePassword)}
              errorMessage={errors.retypePassword}
              id="retypePassword"
              label="Retype password"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Retype password"
              value={values.retypePassword}
            />

            <Button type="submit" variant="primary" block loading={loading}>
              Change
            </Button>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};
