import { transparentize } from "polished";
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

export interface SwitchProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  error?: boolean;
  id?: string;
  label?: string;
  readOnly?: boolean;
}

const StyledSwitch = styled(Form.Check)`
  padding-left: 3rem;

  .custom-control-label::before {
    height: 1.25rem;
    width: 2.5rem;
    border-radius: 0.625rem;
    left: -2.75rem;
    top: 2px;
  }

  .custom-control-label::after {
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    left: calc(-2.75rem + 2px);
    top: 4px;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: ${(props) => props.theme.primary?.main};
      border-color: ${(props) => props.theme.primary?.main};
    }
    &::after {
      background-color: ${(props) => props.theme.primary?.contrastText};
      transform: translateX(1.25rem);
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: ${(props) =>
      props.theme.primary
        ? transparentize(0.5, props.theme.primary?.main)
        : null};
  }

  .custom-control-input.is-invalid ~ .custom-control-label {
    color: ${(props) => props.theme.error?.main};
  }

  .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: ${(props) => props.theme.error?.main};
    border-color: ${(props) => props.theme.error?.main};
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem
      ${(props) =>
        props.theme.primary
          ? transparentize(0.5, props.theme.primary?.main)
          : null};
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: ${(props) => props.theme.primary?.main};
  }
`;

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ error = false, id, label, readOnly, ...props }, ref) => {
    return (
      <StyledSwitch
        ref={ref}
        type="switch"
        label={label}
        disabled={readOnly}
        id={id}
        isInvalid={error}
        {...props}
      />
    );
  }
);
