/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * NonConsolidatedReportingFeatureManagementApi - axios parameter creator
 * @export
 */
export const NonConsolidatedReportingFeatureManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonConsolidatedExcludedFunds: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/features/nonconsolidated/excluded/funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNonConsolidatedExcludedFunds: async (body?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/features/nonconsolidated/excluded/funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NonConsolidatedReportingFeatureManagementApi - functional programming interface
 * @export
 */
export const NonConsolidatedReportingFeatureManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NonConsolidatedReportingFeatureManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNonConsolidatedExcludedFunds(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNonConsolidatedExcludedFunds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveNonConsolidatedExcludedFunds(body?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveNonConsolidatedExcludedFunds(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NonConsolidatedReportingFeatureManagementApi - factory interface
 * @export
 */
export const NonConsolidatedReportingFeatureManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NonConsolidatedReportingFeatureManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonConsolidatedExcludedFunds(options?: any): AxiosPromise<void> {
            return localVarFp.getNonConsolidatedExcludedFunds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNonConsolidatedExcludedFunds(body?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.saveNonConsolidatedExcludedFunds(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NonConsolidatedReportingFeatureManagementApi - object-oriented interface
 * @export
 * @class NonConsolidatedReportingFeatureManagementApi
 * @extends {BaseAPI}
 */
export class NonConsolidatedReportingFeatureManagementApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonConsolidatedReportingFeatureManagementApi
     */
    public getNonConsolidatedExcludedFunds(options?: any) {
        return NonConsolidatedReportingFeatureManagementApiFp(this.configuration).getNonConsolidatedExcludedFunds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonConsolidatedReportingFeatureManagementApi
     */
    public saveNonConsolidatedExcludedFunds(body?: Array<number>, options?: any) {
        return NonConsolidatedReportingFeatureManagementApiFp(this.configuration).saveNonConsolidatedExcludedFunds(body, options).then((request) => request(this.axios, this.basePath));
    }
}
