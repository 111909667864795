import parse from "html-react-parser";
import { filter } from "lodash";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import { SupportDetail, SupportDetailApi } from "../../apis/merchant";
import { Section, SectionContent, SectionHeader } from "../../components";

interface SupportEnquiryProps {
  provider: string;
}

const SupportEnquiry: React.FC<SupportEnquiryProps> = ({ provider }) => {
  const [supportEnquiryList, setSupportEnquiryList] = useState<
    Array<SupportDetail>
  >([]);

  useAsync(async () => {
    try {
      const response = await new SupportDetailApi().getSupportDetailList();
      setSupportEnquiryList(response.data);
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const healthPayServiceDesk = useMemo(
    () =>
      supportEnquiryList.filter(
        (supportEnquiry) =>
          supportEnquiry.organisation === provider &&
          supportEnquiry.supportType === "HealthPay Service Desk" &&
          (supportEnquiry.category === "Technical Helpdesk" ||
            supportEnquiry.category === "Merchant Enquiries" ||
            supportEnquiry.category === "Manual Authorisation")
      ),
    [provider, supportEnquiryList]
  );

  const healthPointServiceDesk = useMemo(
    () =>
      filter(supportEnquiryList, {
        organisation: provider,
        supportType: "HealthPoint Service Desk",
      }),
    [provider, supportEnquiryList]
  );

  const fundContacts = useMemo(
    () =>
      filter(supportEnquiryList, {
        organisation: provider,
        supportType: "Health Fund Contacts",
      }),
    [provider, supportEnquiryList]
  );

  return (
    <div>
      <Section>
        <SectionHeader>{provider} HealthPay Service Desk</SectionHeader>
        {healthPayServiceDesk.length > 0 ? (
          healthPayServiceDesk.map((serviceDesk) => (
            <SectionContent key={serviceDesk.id}>
              <h4>
                <span>{serviceDesk.category} </span>
              </h4>
              <span>{parse(serviceDesk.description)}</span>
            </SectionContent>
          ))
        ) : (
          <SectionContent>No support details to display</SectionContent>
        )}
      </Section>
      <Section>
        <SectionHeader>HealthPoint Service Desk</SectionHeader>
        {healthPointServiceDesk.length > 0 ? (
          healthPointServiceDesk.map((serviceDesk) => (
            <SectionContent key={serviceDesk.id}>
              <h4>
                <span>{serviceDesk.category} </span>
              </h4>
              <span>{parse(serviceDesk.description)}</span>
            </SectionContent>
          ))
        ) : (
          <SectionContent>No support details to display</SectionContent>
        )}
      </Section>

      <Section>
        <SectionHeader>Health Fund Contacts</SectionHeader>
        {fundContacts.length > 0 ? (
          fundContacts.map((fundContact) => (
            <SectionContent key={fundContact.id}>
              <h4>
                <span>{fundContact.category} </span>
              </h4>
              <span>{parse(fundContact.description)}</span>
            </SectionContent>
          ))
        ) : (
          <SectionContent>No support details to display</SectionContent>
        )}
      </Section>
    </div>
  );
};

export default SupportEnquiry;
