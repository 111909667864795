import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAsync } from "react-use";
import { AccountApi, FaqApi } from "../../apis/merchant";
import {
  Col,
  Container,
  Menu,
  MenuHeader,
  MenuItem,
  MenuList,
  Row,
  Select,
} from "../../components";
import { useAuth } from "../../hooks/useAuth";
import ClaimingPayments from "./ClaimingPayments";
import ReconciliationSettlement from "./ReconciliationSettlement";
import ResponseCodes from "./ResponseCodes";
import SupportEnquiry from "./SupportEnquiry";
import TrainingVideos from "./TrainingVideos";

const helpMenuItems = [
  {
    name: "Claiming & Payments",
    link: "/help/claimingPayments",
  },
  {
    name: "Response Codes",
    link: "/help/responseCodes",
  },
  {
    name: "Reconciliation Settlement",
    link: "/help/reconciliationSettlement",
  },
  {
    name: "General Support Enquiry",
    link: "/help/supportEnquiry",
  },
  {
    name: "Training Videos & User Guides",
    link: "/help/trainingVideos",
  },
];

const Help: React.FC = () => {
  const { storeId, isSuperUser } = useAuth();

  const [providerOptions, setProviderOptions] = useState([]);
  const [provider, setProvider] = useState("");

  useAsync(async () => {
    try {
      /* istanbul ignore else */
      if (isSuperUser) {
        const response = await new FaqApi().getProviders();
        setProviderOptions(
          response.data.map((provider) => ({
            value: provider,
            label: provider,
          }))
        );
      } else if (storeId) {
        const res = await new AccountApi().getAccountProvider(storeId);
        setProvider(res.data.provider);
      }
    } catch (e) {}
  }, [isSuperUser, storeId]);

  return (
    <div>
      <Menu>
        <MenuHeader>Help</MenuHeader>
        <MenuList>
          {helpMenuItems.map((menu) => (
            <MenuItem to={menu.link} key={menu.link}>
              {menu.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {isSuperUser && (
        <Container className="pt-4">
          <Row>
            <Col sm={12} md={6}>
              <Select
                id="provider"
                label="Please select provider"
                options={providerOptions}
                onChange={(provider) => setProvider(provider?.value)}
              />
            </Col>
          </Row>
        </Container>
      )}

      <Switch>
        <Route exact path="/help">
          <Redirect to="/help/claimingPayments" />
        </Route>
        <Route exact path="/help/claimingPayments">
          <ClaimingPayments provider={provider} />
        </Route>
        <Route exact path="/help/supportEnquiry">
          <SupportEnquiry provider={provider} />
        </Route>
        <Route exact path="/help/trainingVideos">
          <TrainingVideos provider={provider} />
        </Route>
        <Route exact path="/help/reconciliationSettlement">
          <ReconciliationSettlement provider={provider} />
        </Route>
        <Route exact path="/help/responseCodes">
          <ResponseCodes provider={provider} />
        </Route>
        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
};

export default Help;
