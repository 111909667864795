import { Account, Profile } from "apis/merchant";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  SelectOptionType,
} from "components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useOrganisations } from "hooks/useOrganisations";
import { useProfiles } from "hooks/useProfiles";

interface AccountModalProps {
  account: Account;
  open: boolean;
  handleClose: () => void;
  onUpdate: (item: updateAccount) => void;
}

export interface updateAccount
  extends Omit<Account, "profile" | "organisation"> {
  password: string;
  profile: {
    id: any;
  };
  organisation: {
    id: any;
  };
}

interface FormValues extends Omit<Account, "profile" | "organisation"> {
  profile: SelectOptionType;
  password: string;
  organisation: SelectOptionType;
}

const AccountModal: React.FC<AccountModalProps> = ({
  account,
  open,
  handleClose,
  onUpdate,
}) => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const { profile: accountProfile } = account;
  const { profileList } = useProfiles();
  const { organisations } = useOrganisations();

  useEffect(() => {
    let data = profileList;
    if (!accountProfile?.systemAdmin) {
      data = data.filter((pro) => pro.name !== "System Admin");
    }
    setProfiles(data);
  }, [accountProfile, profileList]);
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...account,
      profile: account.profile
        ? {
            label: account.profile.name,
            value: account.profile.id?.toString(),
          }
        : null,
      password: "",
      organisation: account.organisation
        ? {
            label: account.organisation.name,
            value: account.organisation.id?.toString(),
          }
        : null,
    },
    validationSchema: yup.object().shape({
      organisation: yup
        .object()
        .nullable()
        .required("Please select organisation"),
      password: yup
        .string()
        .matches(
          /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase and one number"
        )
        .nullable(),
      profile: yup.object().nullable().required("Please select profile"),
    }),
    onSubmit: (values) => {
      const request = {
        ...values,
        password: values.password === "" ? undefined : values.password,
        organisation: values.organisation
          ? {
              id: values.organisation.value,
            }
          : null,
        profile: values.profile
          ? {
              id: values.profile.value,
            }
          : null,
      };
      onUpdate(request);
      handleModelClose();
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldValue,
    submitForm,
  } = formik;

  const handleModelClose = () => {
    resetForm();
    handleClose();
  };

  const form = (
    <form>
      <Input
        readOnly={true}
        error={touched.username && Boolean(errors.username)}
        errorMessage={errors.username}
        id="username"
        label="Username"
        placeholder="Username"
        value={values.username}
      />
      <Input
        error={touched.firstName && Boolean(errors.firstName)}
        errorMessage={errors.firstName}
        id="firstName"
        label="FirstName"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="FirstName"
        value={values.firstName}
      />
      <Input
        error={touched.surname && Boolean(errors.surname)}
        errorMessage={errors.surname}
        id="urname"
        label="Surname"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Surname"
        value={values.surname}
      />
      <Input
        error={touched.password && Boolean(errors.password)}
        errorMessage={errors.password}
        id="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Password"
        value={values.password}
        icon={
          showPassword ? (
            <BsEyeSlashFill onClick={() => setShowPassword(false)} />
          ) : (
            <BsEyeFill onClick={() => setShowPassword(true)} />
          )
        }
        iconPosition="right"
      />
      <Select
        error={touched.organisation && Boolean(errors.organisation)}
        errorMessage={errors.organisation as string}
        id="organisation"
        options={organisations.map((org) => ({
          value: org.id.toString(),
          label: org.name,
        }))}
        value={values.organisation}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue("organisation", val)}
        label="Organisation"
      />
      <Select
        error={touched.profile && Boolean(errors.profile)}
        errorMessage={errors.profile as string}
        id="profile"
        options={profiles.map((pro) => ({
          value: pro.id.toString(),
          label: pro.name,
        }))}
        value={values.profile}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue("profile", val)}
        label="Profile"
      />
    </form>
  );

  return (
    <Modal show={open} onClose={handleModelClose}>
      <ModalHeader>Edit Account</ModalHeader>
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={handleModelClose} variant="light">
          Cancel
        </Button>
        <Button type="submit" variant="primary" onClick={() => submitForm()}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AccountModal;
