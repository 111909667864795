import format from "date-fns/format";

export function formatDateForServer(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

// Convert currency amount from number (in dollars) to string (in cents)
export function formatCurrencyAmountForServer(amount: number): string {
  if (!amount) return "";
  return Math.round(Number(amount) * 100).toString();
}

export function parseCurrencyAmountFromServer(amount: string): number {
  return Number(amount) / 100;
}
