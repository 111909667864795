import { createGlobalStyle, ThemeProvider } from "styled-components";
import { ToastContainer } from "../../components";
import { AuthProvider, useTheme } from "../../hooks";
import Routes from "./Routes";

const GlobalFonts = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.typography?.fontFamily};
  }
`;

const App = () => {
  return (
    <AuthProvider>
      <ProvideTheme>
        <GlobalFonts />
        <ToastContainer />
        <Routes />
      </ProvideTheme>
    </AuthProvider>
  );
};

const ProvideTheme = ({ children }) => {
  const theme = useTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default App;
