import { isNil } from "lodash";

export class CurrencyUtils {
  private static readonly DEFAULT_LOCALE = "en-AU";
  private static readonly DEFAULT_CURRENCY = "AUD";

  public static format(
    value: number,
    locale = CurrencyUtils.DEFAULT_LOCALE,
    currency = CurrencyUtils.DEFAULT_CURRENCY
  ): string {
    if (isNil(value)) {
      return value;
    }
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(value);
  }
}
