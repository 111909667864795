import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAsync } from "react-use";
import styled from "styled-components";
import { DeviceModel, TerminalAssociationApi } from "../../../../apis/merchant";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  Row,
} from "../../../../components";
import { useAuth } from "../../../../hooks";
import { DevicesContext } from "../../../../context/DevicesContext";
import { ReconciliationReport } from "./ReconciliationReport";
import { SearchPayment } from "./SearchPayment";
import { OutstandingPayment } from "./OutstandingPayment";

interface PrivateHealthItemProps {
  title: string;
  navTitle?: string;
  link?: string;
  component?: React.ComponentType;
}

const privateHealthItems: Array<PrivateHealthItemProps> = [
  {
    title: "Reconciliation Report",
    link: "/reports/payment/reconciliation",
    component: ReconciliationReport,
  },
  {
    title: "Search Payments by Reference / Invoice Number",
    navTitle: "Report Search by",
    link: "/reports/payment/search",
    component: SearchPayment,
  },
  {
    title: "Outstanding Payments / Invoices",
    link: "/reports/payment/outstanding",
    component: OutstandingPayment,
  },
];

export const PaymentReport: React.FC = () => {
  const [devices, setDevices] = useState<Array<DeviceModel>>([]);
  const { storeId } = useAuth();

  useAsync(async () => {
    try {
      const res = await new TerminalAssociationApi().getTerminalAssociationListByStoreId(
        storeId
      );

      setDevices(res.data || []);
    } catch (e) {
      setDevices([]);
    }
  }, [storeId]);

  return (
    <div>
      <Route path="/reports/payment/*">
        <Container>
          <Nav>
            {privateHealthItems.map(({ link, title, navTitle }) => (
              <NavItem key={title} to={link}>
                {navTitle || title}
              </NavItem>
            ))}
          </Nav>
          <br />
        </Container>
      </Route>

      <DevicesContext.Provider value={devices}>
        <Switch>
          <Route exact path="/reports/payment">
            <PrivateHealthReportList />
          </Route>
          {privateHealthItems.map(({ link, component }) => (
            <Route key={link} exact path={link} component={component} />
          ))}
          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>
      </DevicesContext.Provider>
    </div>
  );
};

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;

  h4 {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
  }
`;

const PrivateHealthReportList = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <ListGroup>
          {privateHealthItems.map(({ title, link, component }) => (
            <ListGroupItem key={title} to={link}>
              <StyledItem>
                <h4>{title}</h4>
              </StyledItem>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Row>
    </Container>
  );
};
