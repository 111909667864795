import { isNil, startsWith } from "lodash";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledNavItem = styled.div<{ active: boolean }>`
  display: inline-block;
  font-weight: bold;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-right: 1px solid #b3b3b3;
  &:last-child {
    border-right: none;
  }
  color: ${(props) => (props.active ? "#000000" : "#B3B3B3")};
  a {
    color: ${(props) => (props.active ? "#000000" : "#B3B3B3")};
  }
`;

export interface NavItemProps {
  active?: boolean;
  to?: string;
}

export const NavItem: React.FC<NavItemProps> = ({ active, children, to }) => {
  const { pathname } = useLocation();
  const isActive = isNil(active) ? startsWith(pathname, to) : active;

  return (
    <StyledNavItem active={isActive}>
      {to ? <Link to={to}>{children}</Link> : children}
    </StyledNavItem>
  );
};
