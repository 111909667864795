import styled from "styled-components";

const StyledMenuList = styled.div`
  padding: 0;
  margin: 0.2rem 0 0;
`;

export const MenuList: React.FC = ({ children }) => {
  return (
    <StyledMenuList>{children}</StyledMenuList>
  )
}
