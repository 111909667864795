import React from "react";
import { Spinner as BsSpinner } from "react-bootstrap";
import styled from "styled-components";

export interface SpinnerProps {
  size?: "sm";
  variant?: "primary" | "secondary";
}

const StyledSpinner = styled(BsSpinner)`
  border-color: ${(props) =>
    props.variant === "primary"
      ? props.theme?.primary?.main
      : props.variant === "secondary"
      ? props.theme?.secondary?.main
      : "#212529"};
  border-right-color: transparent;
`;

export const Spinner: React.FC<SpinnerProps> = ({ size,  variant }) => {
  return <StyledSpinner animation="border" variant={variant} size={size}/>;
};
