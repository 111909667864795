import { useFormik } from "formik";
import { get } from "lodash";
import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAsync, useSearchParam } from "react-use";
import * as yup from "yup";
import { AuthApi } from "../../apis/merchant";
import { Button, Input, Spinner } from "../../components";
import styles from "./Auth.module.scss";
import { AuthLayout } from "./Layout";

interface FormValue {
  password: string;
  passwordConfirmation: string;
}

export const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const token = useSearchParam("t");
  const [hasValidToken, setHasValidToken] = useState(false);

  useAsync(async () => {
    let isTokenValid = false;

    if (token) {
      try {
        await new AuthApi().resetPasswordCheck(token);
        isTokenValid = true;
      } catch (e) {}
    }

    if (token && isTokenValid) {
      setHasValidToken(true);
    } else {
      history.push("/forgot-password", {
        message:
          "Unfortunately the link provided to you has expired, please re-enter your email address to receive a new link to update your password",
      });
    }
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required("New password can not be empty.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "New password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character and at least 8 characters."
        ),
      passwordConfirmation: yup
        .string()
        .required("Retype password can not be empty.")
        .equals(
          [yup.ref("password")],
          "New password and retype password must be the same."
        ),
    }),
    onSubmit: async ({ password, passwordConfirmation }) => {
      setLoading(true);
      try {
        await new AuthApi().resetPasswordConfirm(
          token,
          password,
          passwordConfirmation
        );
        toast.success(`Your password has been updated successfully.`);
        history.push("/login");
      } catch (e) {
        toast.error(get(e, "response.data.message", "Server error"));
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <AuthLayout>
      <div className={styles.card}>
        {hasValidToken ? (
          <div className={styles.form}>
            <h2 className={styles.header}>Change password</h2>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Input
                error={touched.password && Boolean(errors.password)}
                errorMessage={errors.password}
                id="password"
                type={showPassword ? "text" : "password"}
                label="New password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="New password"
                value={values.password}
                icon={
                  showPassword ? (
                    <BsEyeSlashFill onClick={() => setShowPassword(false)} />
                  ) : (
                    <BsEyeFill onClick={() => setShowPassword(true)} />
                  )
                }
                iconPosition="right"
              />

              <Input
                error={
                  touched.passwordConfirmation &&
                  Boolean(errors.passwordConfirmation)
                }
                errorMessage={errors.passwordConfirmation}
                id="passwordConfirmation"
                type={showPassword ? "text" : "password"}
                label="Retype password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Retype password"
                value={values.passwordConfirmation}
                icon={
                  showPassword ? (
                    <BsEyeSlashFill onClick={() => setShowPassword(false)} />
                  ) : (
                    <BsEyeFill onClick={() => setShowPassword(true)} />
                  )
                }
                iconPosition="right"
              />

              <Button type="submit" variant="primary" block loading={loading}>
                Change
              </Button>
            </form>
          </div>
        ) : (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </div>
    </AuthLayout>
  );
};
