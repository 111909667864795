import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Input, InputProps } from "./Input";

export interface PasswordInputProps
  extends Omit<InputProps, "type" | "icon" | "iconPosition"> {}

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      ref={ref}
      type={showPassword ? "text" : "password"}
      icon={
        showPassword ? (
          <BsEyeSlashFill onClick={() => setShowPassword(false)} />
        ) : (
          <BsEyeFill onClick={() => setShowPassword(true)} />
        )
      }
      iconPosition="right"
      {...props}
    />
  );
});
