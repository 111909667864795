/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Account } from '../models';
// @ts-ignore
import { DeviceModel } from '../models';
// @ts-ignore
import { TerminalAssociation } from '../models';
/**
 * TerminalAssociationApi - axios parameter creator
 * @export
 */
export const TerminalAssociationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TerminalAssociation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTerminalAssociation: async (body?: TerminalAssociation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/terminal-association`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTerminalAssociation: async (terminalAssociationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'terminalAssociationId' is not null or undefined
            assertParamExists('deleteTerminalAssociation', 'terminalAssociationId', terminalAssociationId)
            const localVarPath = `/v1/terminal-association/{terminalAssociationId}`
                .replace(`{${"terminalAssociationId"}}`, encodeURIComponent(String(terminalAssociationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAssociation: async (terminalAssociationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'terminalAssociationId' is not null or undefined
            assertParamExists('getTerminalAssociation', 'terminalAssociationId', terminalAssociationId)
            const localVarPath = `/v1/terminal-association/{terminalAssociationId}`
                .replace(`{${"terminalAssociationId"}}`, encodeURIComponent(String(terminalAssociationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Account} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAssociationList: async (body?: Account, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/terminal-association`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAssociationListByStoreId: async (storeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getTerminalAssociationListByStoreId', 'storeId', storeId)
            const localVarPath = `/v1/terminal-association/storeId/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {TerminalAssociation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTerminalAssociation: async (terminalAssociationId: number, body?: TerminalAssociation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'terminalAssociationId' is not null or undefined
            assertParamExists('updateTerminalAssociation', 'terminalAssociationId', terminalAssociationId)
            const localVarPath = `/v1/terminal-association/{terminalAssociationId}`
                .replace(`{${"terminalAssociationId"}}`, encodeURIComponent(String(terminalAssociationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalAssociationApi - functional programming interface
 * @export
 */
export const TerminalAssociationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalAssociationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TerminalAssociation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTerminalAssociation(body?: TerminalAssociation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalAssociation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTerminalAssociation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTerminalAssociation(terminalAssociationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTerminalAssociation(terminalAssociationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalAssociation(terminalAssociationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalAssociation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalAssociation(terminalAssociationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Account} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalAssociationList(body?: Account, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalAssociation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalAssociationList(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalAssociationListByStoreId(storeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalAssociationListByStoreId(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {TerminalAssociation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTerminalAssociation(terminalAssociationId: number, body?: TerminalAssociation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalAssociation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTerminalAssociation(terminalAssociationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TerminalAssociationApi - factory interface
 * @export
 */
export const TerminalAssociationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalAssociationApiFp(configuration)
    return {
        /**
         * 
         * @param {TerminalAssociation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTerminalAssociation(body?: TerminalAssociation, options?: any): AxiosPromise<TerminalAssociation> {
            return localVarFp.createTerminalAssociation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTerminalAssociation(terminalAssociationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTerminalAssociation(terminalAssociationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAssociation(terminalAssociationId: number, options?: any): AxiosPromise<TerminalAssociation> {
            return localVarFp.getTerminalAssociation(terminalAssociationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Account} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAssociationList(body?: Account, options?: any): AxiosPromise<Array<TerminalAssociation>> {
            return localVarFp.getTerminalAssociationList(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAssociationListByStoreId(storeId: number, options?: any): AxiosPromise<Array<DeviceModel>> {
            return localVarFp.getTerminalAssociationListByStoreId(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} terminalAssociationId 
         * @param {TerminalAssociation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTerminalAssociation(terminalAssociationId: number, body?: TerminalAssociation, options?: any): AxiosPromise<TerminalAssociation> {
            return localVarFp.updateTerminalAssociation(terminalAssociationId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalAssociationApi - object-oriented interface
 * @export
 * @class TerminalAssociationApi
 * @extends {BaseAPI}
 */
export class TerminalAssociationApi extends BaseAPI {
    /**
     * 
     * @param {TerminalAssociation} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalAssociationApi
     */
    public createTerminalAssociation(body?: TerminalAssociation, options?: any) {
        return TerminalAssociationApiFp(this.configuration).createTerminalAssociation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} terminalAssociationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalAssociationApi
     */
    public deleteTerminalAssociation(terminalAssociationId: number, options?: any) {
        return TerminalAssociationApiFp(this.configuration).deleteTerminalAssociation(terminalAssociationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} terminalAssociationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalAssociationApi
     */
    public getTerminalAssociation(terminalAssociationId: number, options?: any) {
        return TerminalAssociationApiFp(this.configuration).getTerminalAssociation(terminalAssociationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Account} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalAssociationApi
     */
    public getTerminalAssociationList(body?: Account, options?: any) {
        return TerminalAssociationApiFp(this.configuration).getTerminalAssociationList(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalAssociationApi
     */
    public getTerminalAssociationListByStoreId(storeId: number, options?: any) {
        return TerminalAssociationApiFp(this.configuration).getTerminalAssociationListByStoreId(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} terminalAssociationId 
     * @param {TerminalAssociation} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalAssociationApi
     */
    public updateTerminalAssociation(terminalAssociationId: number, body?: TerminalAssociation, options?: any) {
        return TerminalAssociationApiFp(this.configuration).updateTerminalAssociation(terminalAssociationId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
