import React, { useMemo } from "react";
import { Table, TableColumnType } from "../../../components";
import { MedicalService } from "../../../types";
import { CurrencyUtils } from "../../../utils/currency-utils";
import { DateUtils } from "../../../utils/date-utils";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
//   table: {
//     minWidth: 650,
//   },
//   progress: {
//     minHeight: 60,
//     paddingTop: 15,
//     textAlign: "center",
//   },
// }));

interface DetailsTableProps {
  services: Array<MedicalService>;
  isSubmitted: boolean;
  showGapAmount: boolean;
}

const DetailsTable: React.FC<DetailsTableProps> = ({
  services,
  isSubmitted,
  showGapAmount,
}) => {
  const columns = useMemo<Array<TableColumnType<MedicalService>>>(
    () => [
      {
        Header: "Date of Service",
        accessor: (row) => DateUtils.formatDate(row.serviceDate),
        align: "center",
      },
      {
        Header: "Item Number",
        accessor: "itemNumber",
      },
      {
        Header: "Charge Amount",
        accessor: (row) => CurrencyUtils.format(row.chargeAmount),
        align: "right",
      },
      {
        Header: "Benefit Amount",
        accessor: (row) => CurrencyUtils.format(row.benefitAmount),
        align: "right",
      },
      {
        Header: "Gap Amount",
        accessor: (row) =>
          CurrencyUtils.format(row.chargeAmount - row.benefitAmount),
        align: "right",
      },
    ],
    []
  );

  return (
    <div>
      <Table columns={columns} data={services} hover />
    </div>
  );
};

export default DetailsTable;
