import { Profile, ProfileApi } from "apis/merchant";
import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useAuth } from "./useAuth";

export const ProfilesContext = createContext<{ profileList?: Array<Profile> }>({
  profileList: [],
});

export const ProfilesProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [profileList, setProfileList] = useState<Profile[]>([]);
  useEffect(() => {
    async function fetchProfiles() {
      try {
        const res = await new ProfileApi().getProfileList();
        setProfileList(res.data);
      } catch (e) {
        setProfileList([]);
      }
    }
    if (!isAuthenticated) {
      setProfileList([]);
    } else {
      fetchProfiles();
    }
  }, [isAuthenticated]);
  return (
    <ProfilesContext.Provider value={{ profileList }}>
      {children}
    </ProfilesContext.Provider>
  );
};

export const useProfiles = () => {
  return useContext(ProfilesContext);
};
