import { Modal as BsModal, ModalProps as BsModalProps } from "react-bootstrap";

export interface ModalProps extends BsModalProps {}

export const Modal: React.FC<ModalProps> = ({
  centered = true,
  children,
  ...props
}) => {
  return (
    <BsModal centered={centered} {...props}>
      {children}
    </BsModal>
  );
};
