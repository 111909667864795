import { groupBy, sumBy } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import { PaymentReportResponse } from "../../../../apis/merchant";
import { Table, TableColumnType } from "../../../../components";
import { CurrencyUtils } from "../../../../utils/currency-utils";
import { DateUtils } from "../../../../utils/date-utils";

export interface PaymentSummaryTableProps {
  date: Date;
  report: PaymentReportResponse;
}

const NoSummary = styled.p`
  text-align: center;
  font-size: 0.9rem;
`;

interface PaymentSummary {
  fundId: string;
  fundName: string;
  reportDate: Date;
  terminalId: string;
  totalTransactions: number;
  totalClaimAmount: number;
  totalPaymentAmount: number;
}

export const PaymentSummaryTable: React.FC<PaymentSummaryTableProps> = ({
  date,
  report,
}) => {
  const summaries = useMemo(() => {
    const groupedTransactions = groupBy(report?.paymentTransactions, "phfId");

    const summaries: Array<PaymentSummary> = [];

    for (const phfId in groupedTransactions) {
      summaries.push({
        fundId: phfId,
        fundName: groupedTransactions[phfId][0].phfName,
        reportDate: date,
        terminalId: report.reportHeader?.terminalId,
        totalTransactions: groupedTransactions[phfId].length,
        totalClaimAmount: sumBy(groupedTransactions[phfId], "totalClaimAmount"),
        totalPaymentAmount: sumBy(groupedTransactions[phfId], "paidAmount"),
      });
    }

    return summaries;
  }, [date, report]);

  const columns = useMemo<Array<TableColumnType<PaymentSummary>>>(
    () => [
      {
        Header: "Fund Details",
        accessor: (row) => `${row.fundId} ${row.fundName}`,
      },
      {
        Header: "Report Date",
        accessor: (row) => DateUtils.formatDate(row.reportDate),
      },
      {
        Header: "DXC ID",
        accessor: "terminalId",
      },
      {
        Header: "Total Transactions",
        accessor: "totalTransactions",
        align: "center",
      },
      {
        Header: "Total Claim Amount",
        accessor: (row) => CurrencyUtils.format(row.totalClaimAmount),
        align: "right",
      },
      {
        Header: "Total Payment Amount",
        accessor: (row) => CurrencyUtils.format(row.totalPaymentAmount),
        align: "right",
      },
    ],
    []
  );

  return (
    <div>
      {!!summaries.length ? (
        <Table columns={columns} data={summaries} />
      ) : (
        <NoSummary>No summary to display</NoSummary>
      )}
    </div>
  );
};
