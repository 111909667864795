export function ModalityOptions(){
  return [
    { label: "Medical Clinic", value: "Medical Clinic"},
    { label: "Pharmacy", value: "Pharmacy"},
    { label: "Chemist", value: "Chemist"},
    { label: "Optometrist", value: "Optometrist"},
    { label: "Dental", value: "Dental"},
    { label: "Chiropractic", value: "Chiropractic"},
    { label: "Massage Therapist", value: "Massage Therapist"},
    { label: "Hospitals", value: "Hospitals"},
    { label: "Residential Care Services", value: "Residential Care Services"},
    { label: "Social Assistance Services", value: "Social Assistance Services"},
    { label: "Miscellaneous - PCEFTPOS", value: "Miscellaneous - PCEFTPOS"},
    { label: "Medical and Other Health Care Services", value: "Medical and Other Health Care Services"},
  ]
}
