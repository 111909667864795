import React from "react";
import styled from "styled-components";

const BackdropContainer = styled.div<BackdropProps>`
  position: ${(props) => (props.fixed ? "fixed" : "absolute")};
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.transparent ? "transparent" : "rgba(0, 0, 0, 0.5)"};
  z-index: 101;
`;

export interface BackdropProps {
  open: boolean;
  fixed?: boolean;
  transparent?: boolean;
}

export const Backdrop: React.FC<BackdropProps> = ({
  children,
  open,
  fixed = false,
  transparent = false,
}) => {
  return (
    <BackdropContainer open={open} fixed={fixed} transparent={transparent}>
      {children}
    </BackdropContainer>
  );
};
