import styled from "styled-components";

const StyledMenuHeader = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-decoration: underline;
`;

export const MenuHeader: React.FC = ({ children }) => {
  return (
    <StyledMenuHeader>{children}</StyledMenuHeader>
  )
}
