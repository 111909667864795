import { useFormik } from "formik";
import { find } from "lodash";
import React from "react";
import * as yup from "yup";
import {
  Button,
  Container,
  Col,
  DatePicker,
  Input,
  Row,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectOptionType,
} from "../../../components";
import { Patient, PatientClaim } from "../../../types";
import ServiceDetails from "./ServiceDetails";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
//   section: {
//     marginBottom: theme.spacing(4),
//     "& > h3": {
//       marginBottom: theme.spacing(1),
//     },
//   },
//   paper: {
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(1),
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2),
//   },
//   footerButtons: {
//     display: "flex",
//     justifyContent: "flex-end",
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
// }));

const sexOptions = [
  { label: "Male", value: "1" },
  { label: "Female", value: "2" },
  { label: "Other", value: "3" },
];

export const medicareNumberSchema = yup
  .string()
  .matches(/^\d{10}$/, "Please enter a valid Medicare member number");
export const medicareRefNumberSchema = yup
  .string()
  .nullable()
  .matches(/^[1-9]$/, "Please enter a valid Medicare Ref Number");

interface TelehealthClaimProps {
  initialValue: PatientClaim;
  providers?: Array<SelectOptionType>;
  serviceTypes?: Array<SelectOptionType>;
  disclaimer?: React.ReactNode;
  onSubmit: (claim: PatientClaim) => any;
  onReset?: () => any;
  submitButtonText?: string;
}

interface FormValues
  extends Omit<Patient, "sex">,
    Pick<PatientClaim, "medicalServices"> {
  sex: SelectOptionType;
  providerNumber: SelectOptionType;
  serviceType: SelectOptionType;
}

const TelehealthClaim: React.FC<TelehealthClaimProps> = ({
  initialValue,
  providers,
  serviceTypes,
  disclaimer,
  onSubmit,
  onReset,
  submitButtonText,
}) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      medicareNumber: initialValue.patient?.medicareNumber,
      medicareRefNumber: initialValue.patient?.medicareRefNumber,
      firstName: initialValue.patient?.firstName,
      lastName: initialValue.patient?.lastName,
      dateOfBirth: initialValue.patient?.dateOfBirth,
      sex: find(sexOptions, ["value", initialValue.patient?.sex]),
      providerNumber: find(providers, [
        "value",
        initialValue.serviceProvider?.providerNumber,
      ]),
      serviceType: find(serviceTypes, [
        "value",
        initialValue.serviceProvider?.serviceType,
      ]),
      medicalServices: initialValue.medicalServices,
    },
    validationSchema: yup.object({
      medicareNumber: medicareNumberSchema.required(
        "Medicare card number is required"
      ),
      MedicareRefNumber: medicareRefNumberSchema,
      firstName: yup
        .string()
        .trim()
        .nullable()
        .max(40, "First name must not be more than 40 characters"),
      lastName: yup
        .string()
        .trim()
        .min(1, "Last name must not be less than character")
        .max(40, "Last name must not be more than 40 characters")
        .required("Last name is required"),
      dateOfBirth: yup
        .date()
        .typeError("Please enter a valid date")
        .nullable()
        .max(new Date(), "Date of birth must not be a date in the future")
        .required("Date of birth is required"),
      providerNumber: yup
        .object()
        .nullable()
        .required("Please select a provider"),
      serviceType: yup
        .object()
        .nullable()
        .required("Please select a service type"),
      medicalServices: yup.array().min(1),
    }),
    onSubmit: (values) => {
      onSubmit({
        ...values,
        patient: {
          medicareNumber: values.medicareNumber,
          medicareRefNumber: values.medicareRefNumber,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: values.dateOfBirth,
          sex: values.sex?.value,
        },
        serviceProvider: {
          providerNumber: values.providerNumber.value,
          serviceType: values.serviceType.value,
        },
        medicalServices: values.medicalServices,
      });
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldValue,
    submitForm,
  } = formik;

  const handleReset = () => {
    resetForm({
      values: {
        medicareNumber: "",
        medicareRefNumber: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        sex: null,
        providerNumber: null,
        serviceType: null,
        medicalServices: [],
      },
    });
    onReset?.();
  };

  return (
    <div>
      <Section>
        <SectionHeader>Patient Details</SectionHeader>
        <SectionContent>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Input
                id="medicareNumber"
                format="#### ##### #"
                label="Patient Card Number"
                onBlur={handleBlur}
                onValueChange={(value) =>
                  setFieldValue("medicareNumber", value)
                }
                placeholder="Card number"
                value={values.medicareNumber}
                error={touched.medicareNumber && Boolean(errors.medicareNumber)}
                errorMessage={errors.medicareNumber}
              />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                id="medicareRefNumber"
                format="#"
                label="Patient Reference Number"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Reference number"
                value={values.medicareRefNumber}
                error={
                  touched.medicareRefNumber && Boolean(errors.medicareRefNumber)
                }
                errorMessage={errors.medicareRefNumber}
              />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <DatePicker
                disableFuture
                error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                errorMessage={errors.dateOfBirth as string}
                id="dateOfBirth"
                label="Date of Birth"
                onBlur={handleBlur("dateOfBirth")}
                onChange={(val) => setFieldValue("dateOfBirth", val)}
                value={values.dateOfBirth}
              />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                error={touched.firstName && Boolean(errors.firstName)}
                errorMessage={errors.firstName}
                id="firstName"
                label="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="First Name"
                value={values.firstName}
              />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                error={touched.lastName && Boolean(errors.lastName)}
                errorMessage={errors.lastName}
                id="lastName"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Last Name"
                value={values.lastName}
              />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Select
                error={touched.sex && Boolean(errors.sex)}
                errorMessage={errors.sex as string}
                id="sex"
                label="Gender"
                onChange={(selected) => setFieldValue("sex", selected)}
                options={sexOptions}
                value={values.sex}
              />
            </Col>
          </Row>
        </SectionContent>
      </Section>
      <Section>
        <SectionHeader>Provider Details</SectionHeader>
        <SectionContent>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Select
                error={touched.providerNumber && Boolean(errors.providerNumber)}
                errorMessage={errors.providerNumber as string}
                id="providerNumber"
                label="Select Provider"
                onChange={(selected) =>
                  setFieldValue("providerNumber", selected)
                }
                options={providers}
                value={values.providerNumber}
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Select
                error={touched.serviceType && Boolean(errors.serviceType)}
                errorMessage={errors.serviceType as string}
                id="service-type"
                label="Select Service Type"
                onChange={(selected) => setFieldValue("serviceType", selected)}
                options={serviceTypes}
                value={values.serviceType}
              />
            </Col>
          </Row>
        </SectionContent>
      </Section>
      <Section>
        <SectionHeader>Service Details</SectionHeader>
        <SectionContent>
          <ServiceDetails
            services={values.medicalServices}
            setServices={(val) => setFieldValue("medicalServices", val)}
            isSubmitted={!!touched.medicalServices}
          />
        </SectionContent>
      </Section>
      <Container className="text-right">
        <Button className="mr-2" variant="light" onClick={() => handleReset()}>
          Reset
        </Button>

        <Button variant="primary" onClick={() => submitForm()}>
          {submitButtonText || "Submit"}
        </Button>
      </Container>
      <Container>{disclaimer}</Container>
    </div>
  );
};

export default TelehealthClaim;
