import { useMemo } from "react";
import { PaymentTransaction } from "../../../../apis/merchant";
import {
  SelectColumnFilter,
  Table,
  TableColumnType,
} from "../../../../components";
import { CurrencyUtils } from "../../../../utils/currency-utils";
import { DateUtils } from "../../../../utils/date-utils";
import { ReportCSVExporter } from "../../ReportExporter";

export interface PaymentTransactionTableProps {
  reports: Array<PaymentTransaction>;
  loading: boolean;
}

export const PaymentTransactionTable: React.FC<PaymentTransactionTableProps> = ({
  reports,
  loading,
}) => {
  const columns = useMemo<Array<TableColumnType<PaymentTransaction>>>(
    () => [
      {
        Header: "Fund Detail",
        accessor: (row) => `${row.phfId} ${row.phfName}`,
        Filter: SelectColumnFilter,
        width: 200,
      },
      {
        Header: "Provider Id",
        accessor: "providerId",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Transaction Date",
        accessor: (row) => DateUtils.formatDateTime(row.transactionDate),
        align: "right",
      },
      {
        Header: "Claim Reference Number",
        accessor: "refTag",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Payment Reference",
        accessor: "paymentReference",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Health Fund Response Code",
        accessor: "phfResponseCode",
      },
      {
        Header: "Total Claim Amount",
        accessor: (row) => CurrencyUtils.format(row.totalClaimAmount),
        align: "right",
      },
      {
        Header: "Health Fund Approved Benefit Amount",
        accessor: (row) => CurrencyUtils.format(row.phfTotalBenefitAmount),
        align: "right",
      },
      {
        Header: "Paid Amount",
        accessor: (row) => CurrencyUtils.format(row.paidAmount),
        align: "right",
      },
      {
        Header: "Response Date",
        accessor: (row) => DateUtils.formatDateTime(row.responseDate),
        align: "right",
      },
      {
        Header: "Settlement Date",
        accessor: (row) => DateUtils.formatDateTime(row.settlementDate),
        align: "right",
      },
      {
        Header: "Comments",
        accessor: "comment",
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={reports || []}
      compact
      hover
      striped
      noDataMessage="No report to display"
      loading={loading}
      paginated
    />
  );
};

interface PaymentTransactionTableCSVExporterProps {
  filename: string;
  reports: Array<PaymentTransaction>;
  receptionName: string;
}

export const PaymentTransactionTableCSVExporter: React.FC<PaymentTransactionTableCSVExporterProps> = ({
  filename,
  reports,
  receptionName,
}) => {
  const csvMetadata = [
    { header: "Reception", name: "reception" },
    { header: "Transaction Date", name: "transactionDate" },
    { header: "PHF ID", name: "phfId" },
    { header: "PHF Name", name: "phfName" },
    { header: "PHF Response Code", name: "phfResponseCode" },
    { header: "Provider ID", name: "providerId" },
    { header: "Reference TAG", name: "refTag" },
    { header: "Payment Reference", name: "paymentReference" },
    { header: "PHF Total Benefit Amount", name: "phfTotalBenefitAmount" },
    { header: "Total Claim Amount", name: "totalClaimAmount" },
    { header: "Paid Amount", name: "paidAmount" },
    { header: "Response Date", name: "responseDate" },
    { header: "Settlement Date", name: "settlementDate" },
    { header: "Terminal Date", name: "terminalDate" },
    { header: "Comment", name: "comment" },
  ];

  const reportsWithReception = reports?.map((item) => ({
    ...item,
    reception: receptionName,
  }));

  return (
    <ReportCSVExporter
      filename={filename}
      metadata={csvMetadata}
      reports={reportsWithReception}
    />
  );
};
