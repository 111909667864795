import React, { useMemo } from "react";
import { Button, Table, TableColumnType } from "../../../../components";
import { CurrencyUtils } from "../../../../utils/currency-utils";
import { ReportCSVExporter } from "../../../Reports/ReportExporter";

interface PaymentReport {
  operator?: string;
  refId?: string;
  dateTransaction?: string;
  referenceNumber?: string;
  amount?: number;
  status?: string;
  datePaid?: string;
}

interface PaymentReportTableProps {
  reports: Array<PaymentReport>;
  loading: boolean;
  isPaid?: boolean;
  paginated?: boolean;
}

export const PaymentReportTable: React.FC<PaymentReportTableProps> = ({
  reports,
  loading,
  isPaid = true,
  paginated = true,
}) => {
  const columns = useMemo<Array<TableColumnType<PaymentReport>>>(
    () => [
      {
        Header: "Operator",
        accessor: "operator",
        width: 120,
      },
      {
        Header: "Ref ID",
        accessor: "refId",
        width: 80,
      },
      {
        Header: "Date of Transaction",
        accessor: "dateTransaction",
        width: 200,
        align: "center",
      },
      {
        Header: "Reference / Invoice Number",
        accessor: "referenceNumber",
        width: 150,
      },
      {
        Header: "Amount",
        accessor: (row) => CurrencyUtils.format(row.amount),
        align: "right",
      },
      {
        Header: "Status",
        accessor: (row) => (
          <span style={{ color: row.status === "Paid" ? "green" : "#FF5733" }}>
            {row.status}
          </span>
        ),
        align: "center",
      },
      isPaid
        ? {
            Header: "Date Paid",
            accessor: "datePaid",
            width: 200,
            align: "center",
          }
        : {
            Header: "",
            id: "action",
            accessor: (row) => (
              <Button
                variant="primary"
                size="sm"
                style={{ marginTop: "-5px", marginBottom: "-5px" }}
              >
                Send a reminder
              </Button>
            ),
          },
    ],
    [isPaid]
  );

  return (
    <Table
      columns={columns}
      data={reports || []}
      hover
      noDataMessage="No report to display"
      loading={loading}
      paginated={paginated}
    />
  );
};

interface PaymentReportTableCSVExporterProps {
  filename: string;
  reports: Array<PaymentReport>;
}

export const PaymentReportTableCSVExporter: React.FC<PaymentReportTableCSVExporterProps> = ({
  filename,
  reports,
}) => {
  const csvMetadata = [
    { header: "Operator", name: "operator" },
    { header: "Ref ID", name: "refId" },
    { header: "Date of Transaction", name: "dateTransaction" },
    { header: "Reference / Invoice Number", name: "referenceNumber" },
    { header: "Amount", name: "amount" },
    { header: "Status", name: "status" },
    { header: "Date Paid", name: "datePaid" },
  ];

  return (
    <ReportCSVExporter
      filename={filename}
      metadata={csvMetadata}
      reports={reports}
    />
  );
};
