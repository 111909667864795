import { includes, inRange, max, min, range, toNumber } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import styled from "styled-components";
import { Input } from "../Input";
import { Select } from "../Select";

const Styles = styled.div`
  .form-group {
    margin-right: 1rem;
    input {
      width: 80px;
    }

    .react-select {
      width: 150px;
    }
  }

  .pagination {
    margin-right: 1rem;

    .page-item:not(.disabled):not(.active) .page-link {
      color: #404040;
    }

    .page-item.active .page-link {
      color: ${(props) => props.theme?.primary?.contrastText};
      background-color: ${(props) => props.theme?.primary?.main};
      border-color: ${(props) => props.theme?.primary?.main};
    }

    .page-item.disabled .page-link {
      opacity: 0.65;
    }
  }
`;

interface TablePaginationProps {
  disabled?: boolean;
  gotoPage: any;
  previousPage: any;
  nextPage: any;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  setPageSize: any;
}

export const TablePagination: React.FC<TablePaginationProps> = ({
  disabled = false,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
}) => {
  const pageSizeOptions = useMemo(
    () =>
      range(10, 51, 10).map((pageSize) => ({
        label: `Show ${pageSize}`,
        value: pageSize,
      })),
    []
  );

  const [pageInputNumber, setPageInputNumber] = useState<number>(pageIndex);

  useEffect(() => {
    setPageInputNumber(pageIndex + 1);
  }, [pageIndex]);

  const gotoPageIfValid = useCallback(
    (page: number) => {
      if (inRange(page, 1, pageCount + 1)) {
        gotoPage(page - 1);
      }
    },
    [gotoPage, pageCount]
  );

  return (
    <Styles className="d-flex flex-sm-row flex-column">
      <Pagination>
        <Pagination.First
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage || disabled}
        />
        <Pagination.Prev
          onClick={() => previousPage()}
          disabled={!canPreviousPage || disabled}
        />

        <Pagination.Item
          onClick={() => gotoPage(0)}
          active={pageIndex === 0}
          disabled={disabled}
        >
          {1}
        </Pagination.Item>

        {pageIndex > 3 && <Pagination.Ellipsis disabled={disabled} />}

        {range(
          max([pageIndex - 2, 1]),
          min([pageIndex + 3, pageCount - 1]),
          1
        ).map((index) => (
          <Pagination.Item
            key={index}
            onClick={() => gotoPage(index)}
            active={pageIndex === index}
            disabled={disabled}
          >
            {index + 1}
          </Pagination.Item>
        ))}

        {pageCount - pageIndex > 4 && (
          <Pagination.Ellipsis disabled={disabled} />
        )}

        {pageCount > 1 && (
          <Pagination.Item
            onClick={() => gotoPage(pageCount - 1)}
            active={pageIndex === pageCount - 1}
            disabled={disabled}
          >
            {pageCount}
          </Pagination.Item>
        )}

        <Pagination.Next
          onClick={() => nextPage()}
          disabled={!canNextPage || disabled}
        />
        <Pagination.Last
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage || disabled}
        />
      </Pagination>

      <Input
        type="number"
        value={pageInputNumber || ""}
        onChange={(e) => {
          const page = toNumber(e.target.value);
          setPageInputNumber(page);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            gotoPageIfValid(pageInputNumber);
          }
        }}
        onBlur={() => {
          gotoPageIfValid(pageInputNumber);
        }}
        onKeyDown={(e) => {
          if (includes(["-", "+", ".", "E", "e"], e.key)) {
            e.preventDefault();
            return false;
          }
        }}
        readOnly={disabled}
        min={1}
      />

      <Select
        value={pageSizeOptions.find((opt) => opt.value === pageSize)}
        onChange={(selected) => {
          setPageSize(selected.value);
        }}
        options={pageSizeOptions}
        readOnly={disabled}
      />
    </Styles>
  );
};
