import { BsDownload } from "react-icons/bs";
import { Button } from "../../components";

type CSVMetadata = Array<{ header: string; name: string }>;

interface ReportCSVExporterProps {
  filename: string;
  metadata: CSVMetadata;
  reports: Array<any>;
}

function downloadCSV(filename: string, csvContent: string) {
  const blob = new Blob([csvContent], { type: "text/csv" });

  const a = document.createElement("a");
  a.download = `${filename}.csv`;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
  a.dispatchEvent(
    new MouseEvent("click", { bubbles: false, cancelable: true })
  );
}

function reportToCSV(metadata: CSVMetadata, reports: Array<any>) {
  let csv = "";

  csv += metadata.map((meta) => meta.header).join(",");
  csv += "\r\n";

  for (const record of reports) {
    csv += metadata
      .map((meta) => record[meta.name as keyof typeof record])
      .join(",");
    csv += "\r\n";
  }

  return csv;
}

export const ReportCSVExporter: React.FC<ReportCSVExporterProps> = ({
  filename,
  metadata,
  reports,
}) => {
  const handleClick = () => {
    downloadCSV(filename, reportToCSV(metadata, reports));
  };

  if (!reports?.length) {
    return null;
  }

  return (
    <Button icon={<BsDownload />} onClick={handleClick}>
      Download CSV
    </Button>
  );
};
