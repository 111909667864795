import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../components";
import { MedicalService } from "../../../types";
import DetailsTable from "./DetailsTable";
import Overview from "./Overview";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
//   section: {
//     marginBottom: theme.spacing(4),
//     "& > h3": {
//       marginBottom: theme.spacing(1),
//     },
//   },
//   addButton: {
//     marginBottom: theme.spacing(2),
//   },
//   footerButtons: {
//     marginTop: theme.spacing(1),
//     display: "flex",
//     justifyContent: "flex-end",
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
//   checkboxes: {
//     marginTop: theme.spacing(4),
//     marginLeft: theme.spacing(1),
//   },
// }));

interface TelehealthClaimEstimationProps {
  onReject: () => any;
  onAccept: () => any;
  isSubmitted: boolean;
  medicalServices: MedicalService[];
  showGapAmount?: boolean;
  disclaimer?: React.ReactNode;
}

const TelehealthClaimEstimation: React.FC<TelehealthClaimEstimationProps> = ({
  onReject,
  onAccept,
  isSubmitted,
  medicalServices,
  showGapAmount = false,
  disclaimer,
}) => {
  const totalChargeAmount = medicalServices
    .map((s) => s.chargeAmount)
    .reduce((a, b) => a + b, 0);
  const totalBenefitAmount = medicalServices
    .map((s) => s.benefitAmount)
    .reduce((a, b) => a + b, 0);
  const totalGapAmount = totalChargeAmount - totalBenefitAmount;

  const [checked1, setChecked1] = useState(false);

  const [checked2, setChecked2] = useState(false);

  return (
    <div>
      <Section>
        <SectionHeader>Claim Estimation</SectionHeader>
        <SectionContent>
          <Overview
            showGapAmount={showGapAmount}
            totalChargeAmount={totalChargeAmount}
            totalBenefitAmount={totalBenefitAmount}
            totalGapAmount={totalGapAmount}
          />

          <DetailsTable
            services={medicalServices}
            isSubmitted={isSubmitted}
            showGapAmount={showGapAmount}
          />
        </SectionContent>
      </Section>
      <Container>
        <div>
          <Checkbox
            id="agree1"
            label="I have read the disclaimer and policy notice."
            checked={checked1}
            onChange={(e) => {
              setChecked1(e.target.checked);
            }}
          />

          <Checkbox
            id="agree2"
            label="I agree to deposit the total benefit amount into the patient registered bank account."
            checked={checked2}
            onChange={(e) => {
              setChecked2(e.target.checked);
            }}
          />
        </div>

        {!isSubmitted && (
          <div className="text-right mt-2 mb-2">
            <Button className="mr-2" variant="light" onClick={onReject}>
              Reject
            </Button>

            <Button
              variant="primary"
              onClick={onAccept}
              disabled={!checked1 || !checked2}
            >
              Accept
            </Button>
          </div>
        )}
      </Container>
      <Container>{disclaimer}</Container>
    </div>
  );
};

export default TelehealthClaimEstimation;
