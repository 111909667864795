import { useFormik } from "formik";
import { isNil } from "lodash";
import { useContext, useMemo } from "react";
import { BsSearch } from "react-icons/bs";
import styled from "styled-components";
import * as yup from "yup";
import { DeviceModel } from "../../../../apis/merchant";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  SelectOptionType,
} from "../../../../components";
import { DevicesContext } from "../../../../context/DevicesContext";

const ButtonGroup = styled.div`
  > * {
    margin-right: 0.5rem;
  }
`;

export interface SearchFormProps {
  withDate?: boolean;
  onReset: () => void;
  onSearch: (reception: DeviceModel, date?: Date) => void;
}

function mapDeviceModelToSelectOptionType(
  device: DeviceModel
): SelectOptionType {
  return {
    value: device.id,
    label: device.name!,
  };
}

interface FormValues {
  reportDate?: Date;
  reception: SelectOptionType;
}

export const SearchForm: React.FC<SearchFormProps> = ({
  withDate = true,
  onReset,
  onSearch,
}) => {
  const devices = useContext(DevicesContext);
  const options = useMemo(() => devices.map(mapDeviceModelToSelectOptionType), [
    devices,
  ]);

  const formik = useFormik<FormValues>({
    initialValues: {
      reportDate: new Date(),
      reception: null,
    },
    validationSchema: yup.object({
      reportDate: withDate
        ? yup
            .date()
            .required("Please enter a valid date")
            .min(new Date(2000, 0, 1), "Please enter a valid date")
        : undefined,
      reception: yup.object().nullable().required("Please select a reception"),
    }),
    onSubmit: ({ reportDate, reception }) => {
      if (isNil(reception) || (isNil(reportDate) && withDate)) {
        // Do nothing if reception is not selected,
        // or report date is not selected when it is required.
        return;
      }

      const device = devices.find((device) => device.id === reception.value);

      if (withDate) {
        onSearch(device, reportDate);
      } else {
        onSearch(device);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleReset = (e: any) => {
    formik.handleReset(e);
    onReset();
  };

  return (
    <form autoComplete="false" onSubmit={handleSubmit}>
      <Row>
        {withDate && (
          <Col sm={12} md={6} lg={4}>
            <DatePicker
              id="reportDate"
              label="Report Date"
              onChange={(date) => setFieldValue("reportDate", date)}
              disableFuture
              value={values.reportDate}
              onBlur={handleBlur("reportDate")}
              error={touched.reportDate && Boolean(errors.reportDate)}
              errorMessage={touched.reportDate && (errors.reportDate as string)}
            />
          </Col>
        )}
        <Col sm={12} md={6} lg={4}>
          <Select
            id="reception"
            options={options}
            label="Reception"
            onChange={(selected) => setFieldValue("reception", selected)}
            value={values.reception}
            onBlur={handleBlur}
            error={touched.reception && Boolean(errors.reception)}
            errorMessage={touched.reception && (errors.reception as string)}
          />
        </Col>
      </Row>

      <ButtonGroup>
        <Button variant="primary" icon={<BsSearch />} type="submit">
          Search
        </Button>
        <Button variant="light" onClick={handleReset}>
          Reset
        </Button>
      </ButtonGroup>
    </form>
  );
};
