import {
  format as dateFnsFormat,
  isValid as dateFnsIsValid,
  parseISO as dateFnsParseISO,
} from "date-fns";
import { isNil } from "lodash";

export class DateUtils {
  private static readonly DEFAULT_DATE_FORMAT = "dd/MM/yyyy";
  private static readonly DEFAULT_DATETIME_FORMAT = "dd/MM/yyyy HH:mm:ss";

  private static format(date: Date | string, format: string): string {
    if (isNil(date)) return date;

    if (typeof date === "string") {
      date = dateFnsParseISO(date);
      if (!dateFnsIsValid(date)) {
        return "";
      }
    }
    return dateFnsFormat(date, format);
  }

  public static formatDate(date: Date | string): string {
    return DateUtils.format(date, DateUtils.DEFAULT_DATE_FORMAT);
  }

  public static formatDateTime(date: Date | string): string {
    return DateUtils.format(date, DateUtils.DEFAULT_DATETIME_FORMAT);
  }
}
