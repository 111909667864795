import { useFormik } from "formik";
import { get } from "lodash";
import React, { useState } from "react";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { AuthApi } from "../../apis/merchant";
import { Alert, Button, Input } from "../../components";
import styles from "./Auth.module.scss";
import { AuthLayout } from "./Layout";

export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const locationState: any = useLocation().state;

  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter your email"),
    }),
    onSubmit: async ({ email }) => {
      setLoading(true);
      try {
        await new AuthApi().resetPasswordRequest(email);
        toast.success(
          <p>
            If an account with your email address exists, further instruction
            will be emailed to <strong>{email}</strong>.
          </p>
        );
        history.push("/");
      } catch (e) {
        toast.error(get(e, "response.data.message", "Server error"));
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <AuthLayout>
      <div className={styles.card}>
        <div className={styles.form}>
          <h2 className={styles.header}>Forgot password?</h2>
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {locationState?.message && (
              <Alert variant="danger">{locationState.message}</Alert>
            )}

            <Input
              id="email"
              placeholder="Email address"
              type="email"
              label="Enter your email and we will help you to reset password"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              errorMessage={touched.email && errors.email}
              icon={<BsFillEnvelopeFill />}
              iconPosition="right"
            />

            <Button type="submit" variant="primary" block loading={loading}>
              Send
            </Button>
          </form>
          <div className="text-right">
            <Link className={styles.link} to="/login">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
