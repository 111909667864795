/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ResponseCode } from '../models';
/**
 * ResponseCodeApi - axios parameter creator
 * @export
 */
export const ResponseCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResponseCode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResponseCode: async (body?: ResponseCode, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/responseCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} responseCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResponseCode: async (responseCodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseCodeId' is not null or undefined
            assertParamExists('deleteResponseCode', 'responseCodeId', responseCodeId)
            const localVarPath = `/v1/responseCode/{responseCodeId}`
                .replace(`{${"responseCodeId"}}`, encodeURIComponent(String(responseCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} responseCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseCode: async (responseCodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseCodeId' is not null or undefined
            assertParamExists('getResponseCode', 'responseCodeId', responseCodeId)
            const localVarPath = `/v1/responseCode/{responseCodeId}`
                .replace(`{${"responseCodeId"}}`, encodeURIComponent(String(responseCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseCodeList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/responseCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResponseCodeApi - functional programming interface
 * @export
 */
export const ResponseCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResponseCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ResponseCode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResponseCode(body?: ResponseCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResponseCode(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} responseCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResponseCode(responseCodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResponseCode(responseCodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} responseCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResponseCode(responseCodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResponseCode(responseCodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResponseCodeList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResponseCodeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResponseCodeApi - factory interface
 * @export
 */
export const ResponseCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResponseCodeApiFp(configuration)
    return {
        /**
         * 
         * @param {ResponseCode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResponseCode(body?: ResponseCode, options?: any): AxiosPromise<ResponseCode> {
            return localVarFp.createResponseCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} responseCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResponseCode(responseCodeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteResponseCode(responseCodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} responseCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseCode(responseCodeId: number, options?: any): AxiosPromise<ResponseCode> {
            return localVarFp.getResponseCode(responseCodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseCodeList(options?: any): AxiosPromise<Array<ResponseCode>> {
            return localVarFp.getResponseCodeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResponseCodeApi - object-oriented interface
 * @export
 * @class ResponseCodeApi
 * @extends {BaseAPI}
 */
export class ResponseCodeApi extends BaseAPI {
    /**
     * 
     * @param {ResponseCode} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseCodeApi
     */
    public createResponseCode(body?: ResponseCode, options?: any) {
        return ResponseCodeApiFp(this.configuration).createResponseCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} responseCodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseCodeApi
     */
    public deleteResponseCode(responseCodeId: number, options?: any) {
        return ResponseCodeApiFp(this.configuration).deleteResponseCode(responseCodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} responseCodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseCodeApi
     */
    public getResponseCode(responseCodeId: number, options?: any) {
        return ResponseCodeApiFp(this.configuration).getResponseCode(responseCodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseCodeApi
     */
    public getResponseCodeList(options?: any) {
        return ResponseCodeApiFp(this.configuration).getResponseCodeList(options).then((request) => request(this.axios, this.basePath));
    }
}
