import { format } from "date-fns";
import { useState } from "react";
import {
  DeviceModel,
  FlattenedActivityReport,
  ReportApi,
} from "../../../apis/merchant";
import { Section, SectionContent, SectionHeader } from "../../../components";
import {
  ActivityReportTable,
  ActivityReportTableCSVExporter,
} from "./Shared/ActivityReportTable";
import { SearchForm } from "./Shared/SearchForm";

export const ActivityReport = () => {
  const [reports, setReports] = useState<FlattenedActivityReport[]>();
  const [loading, setLoading] = useState(false);

  const handleSearch = async (reception: DeviceModel, date: Date) => {
    setLoading(true);
    try {
      const res = await new ReportApi().getActivityReport(
        format(date, "yyyyMMdd"),
        String(reception.id)
      );
      setReports(res.data);
    } catch (e) {
      setReports(null);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setReports(null);
    setLoading(false);
  };

  return (
    <div>
      <Section>
        <SectionHeader>Search</SectionHeader>
        <SectionContent>
          <SearchForm onSearch={handleSearch} onReset={handleReset} />
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Report Details</SectionHeader>
        <SectionContent>
          <ActivityReportTable reports={reports} loading={loading} />
          <ActivityReportTableCSVExporter
            filename="Activity Report"
            reports={reports}
          />
        </SectionContent>
      </Section>
    </div>
  );
};
