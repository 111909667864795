/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckResetPasswordRequest } from '../models';
// @ts-ignore
import { ConfirmResetPasswordRequest } from '../models';
// @ts-ignore
import { ResetPasswordRequest } from '../models';
/**
 * ResetPasswordApi - axios parameter creator
 * @export
 */
export const ResetPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkResetPassword: async (body?: CheckResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reset-password/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmResetPassword: async (body?: ConfirmResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reset-password/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword: async (body?: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reset-password/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResetPasswordApi - functional programming interface
 * @export
 */
export const ResetPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResetPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkResetPassword(body?: CheckResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkResetPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmResetPassword(body?: ConfirmResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmResetPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResetPassword(body?: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResetPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResetPasswordApi - factory interface
 * @export
 */
export const ResetPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResetPasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkResetPassword(body?: CheckResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.checkResetPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmResetPassword(body?: ConfirmResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.confirmResetPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(body?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.requestResetPassword(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResetPasswordApi - object-oriented interface
 * @export
 * @class ResetPasswordApi
 * @extends {BaseAPI}
 */
export class ResetPasswordApi extends BaseAPI {
    /**
     * 
     * @param {CheckResetPasswordRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordApi
     */
    public checkResetPassword(body?: CheckResetPasswordRequest, options?: any) {
        return ResetPasswordApiFp(this.configuration).checkResetPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfirmResetPasswordRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordApi
     */
    public confirmResetPassword(body?: ConfirmResetPasswordRequest, options?: any) {
        return ResetPasswordApiFp(this.configuration).confirmResetPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordApi
     */
    public requestResetPassword(body?: ResetPasswordRequest, options?: any) {
        return ResetPasswordApiFp(this.configuration).requestResetPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
}
