import { format } from "date-fns";
import { useState } from "react";
import styled from "styled-components";
import {
  ClaimReportResponse,
  DeviceModel,
  ReportApi,
} from "../../../apis/merchant";
import {
  Container,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../components";
import { useAuth } from "../../../hooks/useAuth";
import {
  ClaimTransactionTable,
  ClaimTransactionTableCSVExporter,
} from "./Shared/ClaimTransactionTable";
import { SearchForm } from "./Shared/SearchForm";

const Disclaimer = styled.div`
  color: #666666;
  p {
    font-size: 1rem;
    margin: 2rem 0 0.5rem;
  }

  small {
    display: block;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

export const NonConsolidatedClaimReport = () => {
  const [reception, setReception] = useState<DeviceModel>();
  const [report, setReport] = useState<ClaimReportResponse>();
  const [loading, setLoading] = useState(false);
  const { storeId } = useAuth();

  const handleSearch = async (reception: DeviceModel, date: Date) => {
    setReception(reception);
    setLoading(true);
    try {
      const res = await new ReportApi().getNonConsolidatedClaimsReport(
        format(date, "yyyyMMdd"),
        reception.id,
        storeId
      );
      setReport(res.data?.response);
    } catch (e) {
      setReport(null);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setReception(null);
    setReport(null);
    setLoading(false);
  };

  return (
    <div>
      <Section>
        <SectionHeader>Search</SectionHeader>
        <SectionContent>
          <SearchForm onSearch={handleSearch} onReset={handleReset} />
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Non Consolidated Claim Details</SectionHeader>
        <SectionContent>
          <ClaimTransactionTable
            reports={report?.claimTransactions}
            loading={loading}
          />
          <ClaimTransactionTableCSVExporter
            filename="Non Consolidated Claim Report"
            reports={report?.claimTransactions}
            receptionName={reception?.name}
          />
        </SectionContent>
      </Section>

      <Container>
        <Disclaimer>
          <p>Disclaimer:</p>

          <small>
            Please be aware that as the Health Funds in the Non Consolidated
            Fund Report will pay rebates for eligible claims directly to your
            nominated bank account, the amount listed in the Total Benefit
            Amount Due column represents the amount each fund has committed to
            pay per eligible claim. This is not necessarily the amount that will
            be paid to your nominated bank account by the health fund. If you
            have any concerns regarding these payments for these funds please
            contact the individual Health Fund directly.
          </small>
        </Disclaimer>
      </Container>
    </div>
  );
};
