import { Footer, Logo } from "../../components";
import styles from "./Auth.module.scss";

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.logo}>
          <Logo brand="afterpay-health" />
          <h1>Health Merchant Portal</h1>
        </div>
        {children}
      </div>
      <Footer />
    </div>
  );
};
