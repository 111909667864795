import React from "react";
import styled from "styled-components";

export interface RadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  id?: string;
  inline?: boolean;
  label?: any;
  name?: string;
  readOnly?: boolean;
}

const StyledLabel = styled.label<{ $inline: boolean; $invalid: boolean }>`
  display: ${(props) => (props.$inline ? "inline-block" : "block")};
  position: relative;
  padding-left: 35px;
  margin-bottom: 0.75rem;
  margin-right: 1.25rem;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    box-shadow: ${(props) =>
      props.$invalid ? "0 0 5px " + props.theme.error?.main : "none"};
    background-color: #eee;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
    transition: background-color 0.15s ease-in-out;
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.primary?.main};
    transition: background-color 0.15s ease-in-out;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    visibility: hidden;
  }

  input:checked ~ .checkmark:after {
    visibility: visible;
    transition: visibility 0.15s ease-in-out;
    transition-delay: 0.15s;
  }

  .checkmark:after {
    left: 8px;
    top: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.theme.primary?.contrastText || "white"};
  }

  input:disabled ~ .checkmark {
    background-color: #ccc !important;
  }
`;

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      error = false,
      id,
      inline = false,
      label,
      name,
      readOnly,
      ...props
    },
    ref
  ) => {
    return (
      <StyledLabel htmlFor={id} $invalid={error} $inline={inline}>
        {label}
        <input
          id={id}
          type="radio"
          name={name}
          ref={ref}
          disabled={readOnly}
          {...props}
        />
        <span className="checkmark"></span>
      </StyledLabel>
    );
  }
);
