import { transparentize } from "polished";
import React from "react";
import { Form as BsForm } from "react-bootstrap";
import styled, { css } from "styled-components";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { isNil } from "lodash";

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  error?: boolean;
  errorMessage?: string;
  helperText?: string;
  format?: string | NumberFormatProps;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  id?: string;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValueChange?: (values: any) => void;
  placeholder?: string;
  readOnly?: boolean;
  size?: "lg" | "sm";
  type?: string;
  value?: string | string[] | number;
}

const FormControlStyles = css`
  ${({ theme: { primary } }) =>
    primary &&
    css`
      &:focus {
        border-color: ${primary.main};
        box-shadow: 0 0 0 0.2rem ${transparentize(0.25, primary.main)};
      }
    `}

  ${({ theme: { error } }) =>
    error &&
    css`
      &.is-invalid {
        border-color: ${error.main};
        background-image: none;
        &:focus {
          border-color: ${error.main};
          box-shadow: 0 0 0 0.2rem ${transparentize(0.75, error.main)};
        }
      }
    `}
`;

const InputStyles = styled.div<{ size?: "lg" | "sm" }>`
  position: relative;

  .form-control {
    ${FormControlStyles}
  }

  &.input-wrapper-icon {
    &-left > .form-control {
      padding-left: 2.5rem;
    }
    &-right > .form-control {
      padding-right: 2.5rem;
    }
  }
  .form-control-icon {
    position: absolute;
    top: ${({ size }) =>
      size === "lg" ? "0.7rem" : size === "sm" ? "0.4rem" : "0.5rem"};
    &-left {
      left: ${({ size }) =>
        size === "lg" ? "0.55rem" : size === "sm" ? "0.8rem" : "0.725rem"};
    }
    &-right {
      right: ${({ size }) =>
        size === "lg" ? "0.55rem" : size === "sm" ? "0.8rem" : "0.725rem"};
    }
  }
  .invalid-feedback {
    color: ${(props) => props.theme.error?.main};
  }
`;

const iconWithProps = (
  icon: React.ReactNode,
  iconPosition: "left" | "right",
  size?: "lg" | "sm"
) => {
  if (!React.isValidElement(icon)) {
    return icon;
  }
  return React.cloneElement(icon, {
    size: size === "lg" ? 26 : size === "sm" ? 18 : 20,
    className: `form-control-icon form-control-icon-${iconPosition}`,
  });
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error = false,
      errorMessage,
      format,
      helperText,
      icon,
      iconPosition = "left",
      id,
      label,
      onBlur,
      onChange = () => {},
      onValueChange,
      placeholder,
      readOnly = false,
      size,
      type,
      value,
      ...props
    },
    ref
  ) => {
    const useFormat: boolean = !isNil(format);
    const formatProps = typeof format === "string" ? { format } : format;
    const commonProps = {
      isInvalid: error,
      id,
      onBlur,
      onChange,
      placeholder,
      ref,
      readOnly,
      disabled: readOnly,
      size,
      type,
      value,
      ...props,
    };

    return (
      <BsForm.Group>
        {label && <BsForm.Label htmlFor={id}>{label}</BsForm.Label>}

        <InputStyles
          className={icon ? `input-wrapper-icon-${iconPosition}` : ""}
          size={size}
        >
          {icon &&
            iconPosition === "left" &&
            iconWithProps(icon, iconPosition, size)}

          {useFormat ? (
            <BsForm.Control
              as={NumberFormat}
              onValueChange={(values: any) => onValueChange?.(values?.value)}
              {...formatProps}
              {...commonProps}
            />
          ) : (
            <BsForm.Control {...commonProps} />
          )}
          {icon &&
            iconPosition === "right" &&
            iconWithProps(icon, iconPosition, size)}
          {errorMessage && (
            <BsForm.Control.Feedback type="invalid">
              {errorMessage}
            </BsForm.Control.Feedback>
          )}
        </InputStyles>

        {helperText && (
          <BsForm.Text className="text-muted">{helperText}</BsForm.Text>
        )}
      </BsForm.Group>
    );
  }
);
