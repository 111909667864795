import styled from "styled-components";
import { Container } from "../Grid";

const StyledSection = styled.div`
  margin: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export interface SectionProps {
  fluid?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  children,
  fluid = false,
}) => {
  return (
    <Container fluid={fluid}>
      <StyledSection>{children}</StyledSection>
    </Container>
  );
};
