import { isNil } from "lodash";
import React from "react";
import { ListGroup as BsListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface ListGroupItemProps {
  to?: string;
}

export const ListGroupItem: React.FC<ListGroupItemProps> = ({
  children,
  to,
}) => {
  const hasLink = !isNil(to);

  return (
    <BsListGroup.Item action={hasLink} as={hasLink ? Link : undefined} to={to}>
      {children}
    </BsListGroup.Item>
  );
};
