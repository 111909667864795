import { useMemo } from "react";
import { FilterProps } from "react-table";
import styled from "styled-components";

const StyledSelect = styled.select`
  border: none;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  margin: 1px -0.25rem;
`;

export const SelectColumnFilter = <D extends object>({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<D>) => {
  const options: any[] = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <StyledSelect
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
};
