import { Modal as BsModal } from "react-bootstrap";
import styled from "styled-components";


const StyledBsModalFooter = styled(BsModal.Footer)`
  border-top-width: 0;
`;


export const ModalFooter: React.FC = ({ children }) => {
  return <StyledBsModalFooter>{children}</StyledBsModalFooter>;
};
