import { Container } from "react-bootstrap";
import styled from "styled-components";

const MenuContainer = styled.div`
  background-color: #f0f0f0;
  padding: 0.5rem 0;
`;

export const Menu: React.FC = ({ children }) => {
  return (
    <MenuContainer>
      <Container>{children}</Container>
    </MenuContainer>
  );
};
