import { useFormik } from "formik";
import { useState } from "react";
import { BsEyeFill, BsEyeSlashFill, BsFillEnvelopeFill } from "react-icons/bs";
import * as yup from "yup";
import { Alert, Button, Input } from "../../../components";

interface FormValues {
  email: string;
  password: string;
}

export interface LoginFormProps {
  error?: string;
  initialEmail?: string;
  initialPassword?: string;
  loading?: boolean;
  onLogin: (email: string, password: string) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  error,
  initialEmail = "",
  initialPassword = "",
  loading = false,
  onLogin,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      email: initialEmail,
      password: initialPassword,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Email is invalid")
        .required("Please enter your email"),
      password: yup
        .string()
        .min(8, "Password should be of minimum 8 characters length")
        .required("Please enter your password"),
    }),
    onSubmit: ({ email, password }) => {
      onLogin(email, password);
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      {error && (
        <Alert variant="danger">
          <div style={{ whiteSpace: "pre-wrap" }}>{error}</div>{" "}
        </Alert>
      )}

      <Input
        id="email"
        placeholder="Email address"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && Boolean(errors.email)}
        errorMessage={touched.email && errors.email}
        icon={<BsFillEnvelopeFill />}
        iconPosition="right"
      />

      <Input
        id="password"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.password && Boolean(errors.password)}
        errorMessage={touched.password && errors.password}
        icon={
          showPassword ? (
            <BsEyeSlashFill onClick={() => setShowPassword(false)} />
          ) : (
            <BsEyeFill onClick={() => setShowPassword(true)} />
          )
        }
        iconPosition="right"
      />

      <Button type="submit" variant="primary" block loading={loading}>
        Login
      </Button>
    </form>
  );
};
