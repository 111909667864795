import { format } from "date-fns";
import { useFormik } from "formik";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import * as yup from "yup";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../../components";
import { mockReports } from "./mockData";
import {
  PaymentReportTable,
  PaymentReportTableCSVExporter,
} from "./PaymentReportTable";

export const ReconciliationReport = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik<{ reportDate: Date }>({
    initialValues: {
      reportDate: new Date(),
    },
    validationSchema: yup.object({
      reportDate: yup
        .date()
        .required("Please enter a valid date")
        .min(new Date(2000, 0, 1), "Please enter a valid date"),
    }),
    onSubmit: ({ reportDate }) => {
      setLoading(true);
      setTimeout(() => {
        setReport(
          mockReports.filter((report) =>
            report.dateTransaction?.includes(format(reportDate, "dd/MM/yyyy"))
          )
        );
        setLoading(false);
      }, 500);
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <div>
      <Section>
        <SectionHeader>Search</SectionHeader>
        <SectionContent>
          <form autoComplete="false" onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <DatePicker
                  id="reportDate"
                  label="Report Date"
                  onChange={(date) => setFieldValue("reportDate", date)}
                  disableFuture
                  value={values.reportDate}
                  onBlur={handleBlur("reportDate")}
                  error={touched.reportDate && Boolean(errors.reportDate)}
                  errorMessage={
                    touched.reportDate && (errors.reportDate as string)
                  }
                />
              </Col>
            </Row>

            <Button variant="primary" icon={<BsSearch />} type="submit">
              Search
            </Button>
          </form>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Report Details</SectionHeader>
        <SectionContent>
          <PaymentReportTable reports={report} loading={loading} />
          <PaymentReportTableCSVExporter
            reports={report}
            filename="PaymentReport"
          />
        </SectionContent>
      </Section>
    </div>
  );
};
