import { useCallback, useMemo, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Button, Table, TableColumnType } from "../../../components";
import { MedicalService } from "../../../types";
import { CurrencyUtils } from "../../../utils/currency-utils";
import { DateUtils } from "../../../utils/date-utils";
import ServiceModal from "./ServiceModal";

interface ServiceDetailsProps {
  services: MedicalService[];
  setServices: (services: MedicalService[]) => void;
  isSubmitted: boolean;
}

const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  services,
  setServices,
  isSubmitted,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (serviceItem = newServiceItem) => {
    if (!serviceItem.id) {
      serviceItem.id = nextId.toString();
      setNextId(nextId + 1);
    }
    setServiceItem(serviceItem);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const newServiceItem: MedicalService = {
    id: null,
    serviceDate: new Date(),
    itemNumber: "",
    chargeAmount: null,
  };

  const [serviceItem, setServiceItem] = useState(newServiceItem);

  const handleRowClick = useCallback(
    (id: string) => {
      setServiceItem(services.find((service) => service.id === id));
      setModalOpen(true);
    },
    [services]
  );

  const lastId = services?.length
    ? Number(services[services.length - 1].id)
    : 0;
  const [nextId, setNextId] = useState(lastId + 1);

  const handleAddService = (service: MedicalService) => {
    setServices([...services, service]);
  };

  const handleUpdateService = (updatedService: MedicalService) => {
    setServices(
      services.map((service) => {
        return service.id === updatedService.id
          ? {
              ...service,
              ...updatedService,
            }
          : service;
      })
    );
  };

  const hanldeDeleteService = (deletedService: MedicalService) => {
    setServices(services.filter((service) => service.id !== deletedService.id));
  };

  const columns = useMemo<Array<TableColumnType<MedicalService>>>(
    () => [
      {
        Header: "",
        id: "actions",
        accessor: (row) => (
          <FaEdit
            style={{ cursor: "pointer" }}
            onClick={() => handleRowClick(row.id)}
          />
        ),
        width: 50,
      },
      {
        Header: "Item Number",
        accessor: "itemNumber",
      },
      {
        Header: "Date of Service",
        accessor: (row) => DateUtils.formatDate(row.serviceDate),
        align: "center",
      },
      {
        Header: "Charge Amount",
        accessor: (row) => CurrencyUtils.format(row.chargeAmount),
        align: "right",
      },
    ],
    [handleRowClick]
  );

  return (
    <div>
      <Button className="mb-2" variant="primary" onClick={() => openModal()}>
        Add a Service
      </Button>

      <ServiceModal
        serviceItem={serviceItem}
        open={modalOpen}
        handleClose={closeModal}
        onAdd={handleAddService}
        onUpdate={handleUpdateService}
        onDelete={hanldeDeleteService}
      />

      <Table
        columns={columns}
        data={services || []}
        noDataMessage={isSubmitted ? "Please add a service" : "No Service"}
      />
    </div>
  );
};

export default ServiceDetails;
