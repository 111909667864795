import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>
        <span>&copy; {new Date().getFullYear()} Afterpay</span>|
        <a
          href="https://www.afterpay.com/en-AU/terms-of-service"
          target="blank"
        >
          Terms
        </a>
        |
        <a href="https://www.afterpaytouch.com/privacy-policy" target="blank">
          Privacy
        </a>
      </p>
    </footer>
  );
};
