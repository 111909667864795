import { PatientClaimType } from ".";
import { MedicalService, PatientClaim } from "../../../types";
import { post } from "../http";
import { OnlineEligibilityCheckRequest } from "../models/onlineEligibilityCheckRequest";
import { OnlineEligibilityCheckResponse } from "../models/onlineEligibilityCheckResponse";
import { OnlineEligibilityCheckService } from "../models/onlineEligibilityCheckService";
import {
  formatCurrencyAmountForServer,
  formatDateForServer,
  parseCurrencyAmountFromServer,
} from "../utils";

export async function postEligibility(
  claim: PatientClaim
): Promise<PatientClaim> {
  try {
    const response = await post<OnlineEligibilityCheckResponse>(
      "/eligibility",
      mapOnlineEligibilityCheckRequestFromPatientClaim(claim)
    );

    if (response.status !== "COMPLETE") {
      const { code, text } = response.medicareStatus.status;
      throw new Error(`Error ${code}: ${text}`);
    }

    return mapOnlineEligibilityCheckResponseToPatientClaim(response);
  } catch (error) {
    let errorMsg = error.message;
    if (Array.isArray(error.response?.data?.serviceMessage)) {
      const { code, reason, severity } = error.response.data.serviceMessage[0];
      errorMsg = `${severity} ${code}: ${reason}`;
    }

    throw new Error(errorMsg);
  }
}

function mapOnlineEligibilityCheckRequestFromPatientClaim(
  claim: PatientClaim
): OnlineEligibilityCheckRequest {
  if (!claim) return null;

  const serviceType = claim.serviceProvider.serviceType as PatientClaimType;
  const serviceTypeCode =
    serviceType === "pathology"
      ? "P"
      : serviceType === "specialist"
      ? "S"
      : "O";
  const providerNumber = claim.serviceProvider.providerNumber;

  return {
    claim: {
      patient: {
        identity: {
          dateOfBirth: formatDateForServer(claim.patient.dateOfBirth),
          familyName: claim.patient.lastName?.trim(),
          givenName: claim.patient.firstName?.trim() || "Onlyname",
          sex: claim.patient.sex?.trim() || "9",
        },
        medicare: {
          memberNumber: claim.patient.medicareNumber,
          memberRefNumber: claim.patient.medicareRefNumber,
        },
      },
      principalProvider: {
        providerNumber,
      },
      typeCode: "ECM",
      accountReferenceId: "123456789", // TODO
      serviceTypeCode,
      submissionAuthorityInd: "Y",
      medicalEvent: [
        {
          id: "01",
          serviceProvider: {
            providerNumber,
          },
          service: claim.medicalServices.map<OnlineEligibilityCheckService>(
            (service) => {
              return {
                id: service.id.padStart(4, "0"),
                chargeAmount: formatCurrencyAmountForServer(
                  service.chargeAmount
                ),
                itemNumber: service.itemNumber,
                serviceCodeTypeCode: "C",
                timeDependantOverrideInd: "N",
                dateOfService: formatDateForServer(service.serviceDate),
              };
            }
          ),
        },
      ],
    },
  };
}

function mapOnlineEligibilityCheckResponseToPatientClaim(
  response: OnlineEligibilityCheckResponse
): PatientClaim {
  return {
    medicalServices: response.medicareClaimEstimation?.medicalEvent[0]?.service.map<
      MedicalService
    >((service) => {
      return {
        id: service.id,
        benefitAmount: parseCurrencyAmountFromServer(service.benefit),
      };
    }),
  };
}
