import parse from "html-react-parser";
import { filter, groupBy } from "lodash";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAsync } from "react-use";
import { Faq, FaqApi } from "../../apis/merchant";
import {
  Accordion,
  AccordionItem,
  Section,
  SectionContent,
  SectionHeader,
} from "../../components";

const claimingFaqTypes = [
  { type: "common", header: "Common HealthPoint Questions" },
  { type: "integrated", header: "For Integrated Users" },
  { type: "standalone", header: "For Standalone Users" },
];

interface ClaimingPaymentsProps {
  provider: string;
}

const ClaimingPayments: React.FC<ClaimingPaymentsProps> = ({ provider }) => {
  const [faqs, setFaqs] = useState<Faq[]>([]);

  useAsync(async () => {
    try {
      const response = await new FaqApi().getFaqList();
      setFaqs(response.data);
    } catch (e) {
      setFaqs([]);

      toast.error(e.message);
    }
  }, []);

  const groupedFaqs = useMemo(
    () =>
      groupBy(
        filter(faqs, { organisation: provider, menuItem: "claiming" }),
        "type"
      ),
    [faqs, provider]
  );

  return (
    <>
      {claimingFaqTypes.map(({ type, header }) => (
        <Section key={type}>
          <SectionHeader>{header}</SectionHeader>
          <SectionContent>
            {groupedFaqs[type]?.length > 0 ? (
              <Accordion>
                {groupedFaqs[type].map(({ id, question, answer }) => {
                  return (
                    <AccordionItem key={id} header={question}>
                      {parse(answer)}
                    </AccordionItem>
                  );
                })}
              </Accordion>
            ) : (
              "No FAQs to display"
            )}
          </SectionContent>
        </Section>
      ))}
    </>
  );
};

export default ClaimingPayments;
