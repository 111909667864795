import { useState } from "react";
import { useAsync } from "react-use";
import theme, { anz } from "../themes";
import { useAuth } from "./useAuth";

export const useTheme = () => {
  const themes: Record<string, any> = {
    ANZ: anz,
    default: theme,
  };

  const { provider } = useAuth();
  const [themeSchema, setThemeSchema] = useState<any>(themes.default);

  useAsync(async () => {
    if (provider) {
      if (provider in themes) {
        setThemeSchema(themes[provider]);
      }
    } else {
      setThemeSchema(themes.default);
    }
  }, [provider]);

  return themeSchema;
};
