/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Organisation } from './organisation';
import { Profile } from './profile';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    providerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    providerType?: string;
    /**
     * 
     * @type {Organisation}
     * @memberof Account
     */
    organisation?: Organisation;
    /**
     * 
     * @type {Profile}
     * @memberof Account
     */
    profile?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    status?: AccountStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    locked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    createDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    storeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountStatusEnum {
    New = 'NEW',
    Suspended = 'SUSPENDED'
}



