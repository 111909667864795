import { get } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useAsync, useLocalStorage } from "react-use";
import { Account, AccountApi, AuthApi } from "../apis/merchant";

const IS_AUTHENTICATED = "isAuthenticated";

export const AuthContext = createContext<{
  user?: Account;
  isAuthenticated: boolean;
  isSuperUser?: boolean;
  isAnonymous?: boolean;
  storeId?: number;
  provider?: string;
  isLoading?: boolean;
  error?: any;
  login?: (username: string, password: string) => void;
  logout?: () => void;
  changePassword?: (password: string, newPassword: string) => Promise<void>;
}>({
  user: null,
  isAuthenticated: false,
  isSuperUser: false,
  isAnonymous: true,
  storeId: null,
  provider: null,
  isLoading: false,
  error: null,
  login: /* istanbul ignore next */ () => {},
  logout: /* istanbul ignore next */ () => {},
  changePassword: /* istanbul ignore next */ () => Promise.reject(),
});

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage<boolean>(
    IS_AUTHENTICATED
  );
  const [user, setUser] = useState<Account>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState<string>();

  /**
   * Login workflow:
   *                              Start loading
   *                                    |
   *                     Login with username and password
   *                      |                            |
   *                   Success                      Failure
   *                      |                            |
   *              Fetch account data              Stop loading
   *              |                |
   *          Success            Failure
   *            |                  |
   *       Fetch provider     Stop loading
   *       |           |
   *     Success    Failure
   *       |           |
   *        Stop loading
   */
  const login = useCallback(async (username: string, password: string) => {
    setIsLoading(true);
    try {
      await new AuthApi().login(username, password);

      setIsAuthenticated(true);
      setError(null);
    } catch (err) {
      let errorMessage = err.message;
      /* istanbul ignore else */
      if (err.response?.data) {
        const { attemptRemaining, httpStatusCode, locked } = err.response.data;
        if (httpStatusCode === 403) {
          errorMessage = "Login is blocked, please contact help desk.";
        } else if (locked) {
          errorMessage = "The account is locked, please contact help desk.";
        } else if (attemptRemaining <= 5) {
          errorMessage = `Login was unsuccessful.\nAccount will get locked after ${attemptRemaining} unsuccessful attempts.`;
        } else {
          errorMessage = `Login was unsuccessful.`;
        }
      }

      setError(errorMessage);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => {
    try {
      await new AuthApi().logout();
    } finally {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePassword = useCallback(
    async (password: string, newPassword: string) => {
      try {
        const res = await new AccountApi().changePassword({
          username: user.username,
          password,
          newPassword,
          retypePassword: newPassword,
        });
        setUser(res.data);
      } catch (e) {
        throw new Error(get(e, "response.data.message", "Server error"));
      }
    },
    [user]
  );

  useAsync(async () => {
    if (isAuthenticated) {
      setIsLoading(true);
      try {
        const res = await new AccountApi().getAuthenticatedUserAccount();
        setUser(res.data);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to load user account");
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setUser(null);
    }
  }, [isAuthenticated]);

  useAsync(async () => {
    if (user) {
      if (user.storeId) {
        setIsLoading(true);
        try {
          const res = await new AccountApi().getAccountProvider(user.storeId);
          const provider = res.data?.provider;
          setProvider(provider);
        } catch (err) {
          /* istanbul ignore next */
          toast.error(err.response?.data?.message || "Failed to load provider");
        }
      } else {
        // Some user profiles may not have a store id
        setProvider(null);
      }
      setIsLoading(false);
    } else {
      setProvider(null);
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isSuperUser: user?.profile?.systemAdmin || user?.profile?.powerUser,
        isAnonymous: isAuthenticated === false,
        storeId: user?.storeId,
        provider,
        isLoading,
        error,
        login,
        logout,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
