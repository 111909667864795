import { format } from "date-fns";
import { useState } from "react";
import {
  DeviceModel,
  PaymentReportResponse,
  ReportApi,
} from "../../../apis/merchant";
import { Section, SectionContent, SectionHeader } from "../../../components";
import { useAuth } from "../../../hooks/useAuth";
import { PaymentSummaryTable } from "./Shared/PaymentSummaryTable";
import {
  PaymentTransactionTable,
  PaymentTransactionTableCSVExporter,
} from "./Shared/PaymentTransactionTable";
import { SearchForm } from "./Shared/SearchForm";

export const ConsolidatedClaimReport = () => {
  const [reception, setReception] = useState<DeviceModel>();
  const [date, setDate] = useState<Date>();
  const [report, setReport] = useState<PaymentReportResponse>();
  const [loading, setLoading] = useState(false);
  const { storeId } = useAuth();

  const handleSearch = async (reception: DeviceModel, date: Date) => {
    setReception(reception);
    setDate(date);
    setLoading(true);
    try {
      const res = await new ReportApi().getConsolidatedClaimsReport(
        format(date, "yyyyMMdd"),
        reception.id,
        storeId
      );
      setReport(res.data?.response);
    } catch (e) {
      setReport(null);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setReport(null);
    setLoading(false);
  };

  return (
    <div>
      <Section>
        <SectionHeader>Search</SectionHeader>
        <SectionContent>
          <SearchForm onSearch={handleSearch} onReset={handleReset} />
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Consolidated Claim Details</SectionHeader>
        <SectionContent>
          <PaymentTransactionTable
            reports={report?.paymentTransactions}
            loading={loading}
          />
          <PaymentTransactionTableCSVExporter
            filename="Consolidated Claim Report"
            reports={report?.paymentTransactions}
            receptionName={reception?.name}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Consolidated Claim Summary</SectionHeader>
        <SectionContent>
          <PaymentSummaryTable date={date} report={report} />
        </SectionContent>
      </Section>
    </div>
  );
};
