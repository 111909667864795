import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAsync } from "react-use";
import styled from "styled-components";
import { DeviceModel, TerminalAssociationApi } from "../../../apis/merchant";
import ActivityReportImage from "../../../assets/images/activity-report.png";
import ClaimReportImage from "../../../assets/images/claim-report.png";
import ConsolidatedClaimReportImage from "../../../assets/images/consolidated-claim-report.png";
import NonConsolidatedClaimReportImage from "../../../assets/images/non-consolidated-claim-report.png";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  Row,
} from "../../../components";
import { DevicesContext } from "../../../context/DevicesContext";
import { useAuth } from "../../../hooks/useAuth";
import { ActivityReport } from "./ActivityReport";
import { ConsolidatedClaimReport } from "./ConsolidatedClaimReport";
import { DailyClaimReport } from "./DailyClaimReport";
import { NonConsolidatedClaimReport } from "./NonConsolidatedClaimReport";
import { ReconciliationReport } from "./ReconciliationReport";

interface PrivateHealthItemProps {
  title: string;
  logo?: string;
  link?: string;
  component?: React.ComponentType;
}

const privateHealthItems: Array<PrivateHealthItemProps> = [
  {
    title: "Reconciliation Report",
    link: "/reports/private/reconciliation",
    logo: ClaimReportImage,
    component: ReconciliationReport,
  },
  {
    title: "Activity Report",
    link: "/reports/private/activity",
    logo: ActivityReportImage,
    component: ActivityReport,
  },
  {
    title: "Daily Claim Report",
    link: "/reports/private/daily",
    logo: ActivityReportImage,
    component: DailyClaimReport,
  },
  {
    title: "Consolidated Claim Report",
    link: "/reports/private/consolidated",
    logo: ConsolidatedClaimReportImage,
    component: ConsolidatedClaimReport,
  },
  {
    title: "Non-Consolidated Claim Report",
    link: "/reports/private/non-consolidated",
    logo: NonConsolidatedClaimReportImage,
    component: NonConsolidatedClaimReport,
  },
];

export const PrivateHealthReports: React.FC = () => {
  const [devices, setDevices] = useState<Array<DeviceModel>>([]);
  const { storeId } = useAuth();

  useAsync(async () => {
    try {
      const res = await new TerminalAssociationApi().getTerminalAssociationListByStoreId(
        storeId
      );

      setDevices(res.data || []);
    } catch (e) {
      setDevices([]);
    }
  }, [storeId]);

  return (
    <div>
      <Route path="/reports/private/*">
        <Container>
          <Nav>
            {privateHealthItems.map(({ link, title }) => (
              <NavItem key={title} to={link}>
                {title}
              </NavItem>
            ))}
          </Nav>
          <br />
        </Container>
      </Route>

      <DevicesContext.Provider value={devices}>
        <Switch>
          <Route exact path="/reports/private">
            <PrivateHealthReportList />
          </Route>
          {privateHealthItems.map(({ link, component }) => (
            <Route key={link} exact path={link} component={component} />
          ))}
          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>
      </DevicesContext.Provider>
    </div>
  );
};

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 50px;
    margin-right: 1rem;
    img {
      height: 60px;
      filter: grayscale(100%);
    }
  }

  h4 {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
  }
`;

const PrivateHealthReportList = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <ListGroup>
          {privateHealthItems.map(({ title, link, logo, component }) => (
            <ListGroupItem key={title} to={link}>
              <StyledItem>
                <div className="logo-wrapper">
                  <img src={logo} alt={title} />
                </div>

                <h4>{title}</h4>
              </StyledItem>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Row>
    </Container>
  );
};
