/* tslint:disable */
/* eslint-disable */
/**
 * Health Platform Merchant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Organisation } from '../models';
/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Organisation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisation: async (body?: Organisation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganisation: async (organisationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('deleteOrganisation', 'organisationId', organisationId)
            const localVarPath = `/v1/organisations/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation: async (organisationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('getOrganisation', 'organisationId', organisationId)
            const localVarPath = `/v1/organisations/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Organisation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganisation(body?: Organisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganisation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganisation(organisationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisation(organisationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisation(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organisation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * 
         * @param {Organisation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisation(body?: Organisation, options?: any): AxiosPromise<Organisation> {
            return localVarFp.createOrganisation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganisation(organisationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation(organisationId: number, options?: any): AxiosPromise<Organisation> {
            return localVarFp.getOrganisation(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationList(options?: any): AxiosPromise<Array<Organisation>> {
            return localVarFp.getOrganisationList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @param {Organisation} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public createOrganisation(body?: Organisation, options?: any) {
        return OrganisationApiFp(this.configuration).createOrganisation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public deleteOrganisation(organisationId: number, options?: any) {
        return OrganisationApiFp(this.configuration).deleteOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisation(organisationId: number, options?: any) {
        return OrganisationApiFp(this.configuration).getOrganisation(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisationList(options?: any) {
        return OrganisationApiFp(this.configuration).getOrganisationList(options).then((request) => request(this.axios, this.basePath));
    }
}
