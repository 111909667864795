import { useMemo } from "react";
import { FlattenedProviderReport } from "../../../../apis/merchant";
import { Table, TableColumnType } from "../../../../components";
import { CurrencyUtils } from "../../../../utils/currency-utils";
import { ReportCSVExporter } from "../../ReportExporter";

interface ProviderReportTableProps {
  reports: Array<FlattenedProviderReport>;
  loading: boolean;
}

export const ProviderReportTable: React.FC<ProviderReportTableProps> = ({
  reports,
  loading,
}) => {
  const columns = useMemo<Array<TableColumnType<FlattenedProviderReport>>>(
    () => [
      {
        Header: "Provider Name",
        accessor: "providerName",
      },
      {
        Header: "Service Type",
        accessor: "serviceType",
      },
      {
        Header: "Fund Name",
        accessor: "fundName",
      },
      {
        Header: "Receipt Number",
        accessor: "receiptNumber",
      },
      {
        Header: "Number of Service",
        accessor: "numberOfService",
      },
      {
        Header: "Total Charge",
        accessor: (row) => CurrencyUtils.format(row.totalCharge),
        align: "right",
      },
      {
        Header: "Total Benefit",
        accessor: (row) => CurrencyUtils.format(row.totalBenefit),
        align: "right",
      },
      {
        Header: "Total Gap",
        accessor: (row) => CurrencyUtils.format(row.totalGap),
        align: "right",
      },
      {
        Header: "Response Code",
        accessor: "responseCode",
        align: "center",
      },
      {
        Header: "Result Description",
        accessor: "resultDescription",
      },
      {
        Header: "Reception",
        accessor: "reception",
      },
      {
        Header: "Report Datetime",
        accessor: "reportDateTime",
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={reports || []}
      compact
      hover
      striped
      noDataMessage="No report to display"
      loading={loading}
      paginated
    />
  );
};

interface ProviderReportTableCSVExporterProps {
  filename: string;
  reports: Array<FlattenedProviderReport>;
}

export const ProviderReportTableCSVExporter: React.FC<ProviderReportTableCSVExporterProps> = ({
  filename,
  reports,
}) => {
  const csvMetadata = [
    { header: "Provider Name", name: "providerName" },
    { header: "Service Type", name: "serviceType" },
    { header: "Fund Name", name: "fundName" },
    { header: "Receipt Number", name: "receiptNumber" },
    { header: "Number Of Service", name: "numberOfService" },
    { header: "Total Charge", name: "totalCharge" },
    { header: "Total Benefit", name: "totalBenefit" },
    { header: "Total Gap", name: "totalGap" },
    { header: "Response Code", name: "responseCode" },
    { header: "Result Description", name: "resultDescription" },
  ];

  return (
    <ReportCSVExporter
      filename={filename}
      metadata={csvMetadata}
      reports={reports}
    />
  );
};
