import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateHealthReports } from "./PrivateHealth";

import { Menu, MenuHeader, MenuItem, MenuList } from "../../components";
import { PaymentReport } from "../Telehealth/Payment/Report";

const Reports: React.FC = () => {
  return (
    <div>
      <Menu>
        <MenuHeader>Reports</MenuHeader>
        <MenuList>
          <MenuItem to="/reports/private">Private Health</MenuItem>
          <MenuItem>Medicare EasyClaim</MenuItem>
          <MenuItem to="/reports/payment">Payment</MenuItem>
        </MenuList>
      </Menu>
      <Switch>
        <Route exact path="/reports">
          <Redirect to="/reports/private" />
        </Route>
        <Route path="/reports/private">
          <PrivateHealthReports />
        </Route>
        <Route path="/reports/payment">
          <PaymentReport />
        </Route>
        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
};

export default Reports;
